import AccountingContent from "@components/page/influencer/accounting/AccountingContent";
import { Title } from "@components/styles";
import { useMenu } from "@features/useMenu";
import { useEffect } from "react";
import styled from "styled-components";

export default function Accounting() {
  const { setCurrentMenu, setCurrentSubMenu } = useMenu();

  useEffect(() => {
    setCurrentSubMenu("accounting");
    setCurrentMenu("my_contents");

    return () => {
      setCurrentSubMenu("");
    };
  }, []);

  return (
    <Container>
      <Title>정산내역</Title>
      <AccountingContent />
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
`;
