import styled from "styled-components";
import UserInput from "./UserInput";
import { usePostInfluencer } from "@hooks/admin";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function AddUserContainer() {
  const navigate = useNavigate();
  const { mutate } = usePostInfluencer();
  const [userInfo, setUserInfo] = useState({
    nickname: "",
    phone_number: "",
    name: "",
    email: "",
    auth: "",
    memo: "",
  });

  const onSubmit = () => {
    mutate(userInfo, {
      onSuccess: () => {
        navigate("/admin/config/users");
      },
      onError: (result) => {
        const error =
          result.response.data.message ||
          "일시적인 오류가 발생했어요. 잠시후 다시 시도해주세요.";
        console.log(error);
      },
    });
  };

  return (
    <Container>
      <UserInput
        userInfo={userInfo}
        setUserInfo={setUserInfo}
        onSubmit={onSubmit}
      />
    </Container>
  );
}

const Container = styled.div``;
