/* eslint-disable no-restricted-globals */
import B1TypeInputBoxTyping from "@components/core/BTypeInput/B1TypeInputBoxTyping";
import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import { usePostContent } from "@hooks/influencer";
import { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function InputContainer() {
  const navigate = useNavigate();
  const inputRef_1 = useRef<HTMLInputElement>(null);
  const inputRef_2 = useRef<HTMLInputElement>(null);

  const { mutate } = usePostContent();
  const [contentInfo, setContentInfo] = useState({ title: "", page_url: "" });
  const isDone = useMemo(() => {
    return !!(contentInfo.title && contentInfo.page_url);
  }, [contentInfo]);

  const checkUrl = (url) => {
    let regex =
      /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

    if (regex.test(url)) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = () => {
    mutate(contentInfo, {
      onSuccess: () => {
        if (
          confirm(
            "정상적으로 제출을 완료했어요! 상세 내역으로 이동하시겠습니까?"
          )
        ) {
          navigate("/influencer/my_contents/accounting");
        } else {
          setContentInfo({ title: "", page_url: "" });
        }
      },
    });
  };

  const onClick = () => {
    if (checkUrl(contentInfo.page_url)) {
      onSubmit();
    } else {
      alert("유효하지 않은 링크입니다.");
    }
  };

  return (
    <Container>
      <B1TypeInputBoxTyping
        inputRef={inputRef_1}
        inputType={InputTypeEnum.OnlyText}
        label={"컨텐츠 제목"}
        placeholder={"컨텐츠 제목을 입력하세요."}
        value={contentInfo.title}
        onChangeValue={(v: InputTypeEnum.OnlyText) =>
          setContentInfo((prev) => ({ ...prev, title: v }))
        }
        style={{ width: "40vw", minWidth: 570 }}
      />
      <B1TypeInputBoxTyping
        inputRef={inputRef_2}
        inputType={InputTypeEnum.OnlyText}
        placeholder={"작업하신 노션 링크를 입력하세요."}
        label={"컨텐츠 링크"}
        value={contentInfo.page_url}
        onChangeValue={(v: InputTypeEnum.OnlyText) =>
          setContentInfo((prev) => ({ ...prev, page_url: String(v) }))
        }
        style={{ width: "40vw", minWidth: 570 }}
      />
      <Button $isDone={isDone} onClick={onClick} disabled={!isDone}>
        제출하기
      </Button>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 4vw;
`;

const Button = styled.button<{ $isDone: boolean }>`
  margin: 16px auto 0;
  padding: 14px 55px;
  width: fit-content;
  border-radius: 8px;
  border: none;
  background: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.primaryBlue100 : theme.colors.blueGray100};
  cursor: pointer;

  color: ${({ theme, $isDone }) =>
    $isDone ? "white" : theme.colors.blueGray500};
  font-size: 20px;
  font-weight: 500;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;

  &:disabled {
    cursor: not-allowed;
  }
`;
