import { Instance } from "@api/index";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { tradeType } from "./Imjang.interfaces";

// 임장 신청 목록
export const useGetImjangApplicantsList = () =>
  useQuery({
    queryKey: ["imjangApplicantList"],
    queryFn: async () => {
      const response = await Instance({
        url: `/brokerage/apply`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 0,
  });

// 임장 신청 목록
export const useGetScaleCodeList = ({ danjiCode }) =>
  useQuery({
    queryKey: ["scaleCodeList"],
    queryFn: async () => {
      const response = await Instance({
        url: `/danjis/info/scale_code_list?danjiCode=${danjiCode}`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 0,
  });

// 임장 신청 상세 조회
// 어드민 임장 단지 추가가 생기면서 api 분리
export const useGetImjangApplicantDetail = ({ id }) =>
  useQuery({
    queryKey: ["imjangApplicant", id],
    queryFn: async () => {
      const response = await Instance({
        url: `/brokerage/apply/admin/${id}`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 0,
  });

// 임장 상태 변경
export const usePatchImjangStatus = () => {
  const queryClient = useQueryClient();
  return useMutation<{}, AxiosError, { id: string; state: string }>({
    mutationFn: async ({ id, state }) => {
      const response = await Instance({
        url: `/brokerage/apply/${id}`,
        method: "PATCH",
        data: { state },
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["imjangApplicantList"],
      });
    },
  });
};

// 임장 단지 추가 (어드민)
export const usePostImjangDanji = () => {
  const queryClient = useQueryClient();
  return useMutation<
    {},
    AxiosError,
    {
      applyId: string;
      danjiCode: number;
      scaleCodeList: number[];
      tradeType: tradeType;
    }
  >({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/brokerage/apply/admin/danji`,
        method: "POST",
        data,
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["imjangApplicant"],
      });
    },
  });
};

// 임장 단지 추가 (어드민)
export const useDeleteImjangDanji = () => {
  const queryClient = useQueryClient();
  return useMutation<
    {},
    AxiosError,
    {
      applyId: string;
      danjiIdList: string[];
    }
  >({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/brokerage/apply/admin/danji`,
        method: "DELETE",
        data,
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["imjangApplicant"],
      });
    },
  });
};

// 중개소 목록 조회
export const useGetImjangAgentList = () =>
  useQuery({
    queryKey: ["imjangAgentList"],
    queryFn: async () => {
      const response = await Instance({
        url: `/brokerage/agent`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 0,
  });

// 중개소 삭제
export const useDeleteImjangAgents = () => {
  const queryClient = useQueryClient();
  return useMutation<{}, AxiosError, { agentIds: string[] }>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/brokerage/agent/deleteAgents`,
        method: "POST",
        data,
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["imjangAgentList"],
      });
    },
  });
};

//

export const useGetRegionList = () =>
  useQuery({
    queryKey: ["region"],
    queryFn: async () => {
      const response = await Instance({
        url: `/recommendation/setting/regions_pnu`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 0,
  });

export const useGetImjangAgentDetail = ({ id }: { id: string }) =>
  useQuery({
    queryKey: ["imjangAgent", id],
    queryFn: async () => {
      const response = await Instance({
        url: `/brokerage/agent/${id}`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 0,
  });

export const useEditImjangAgentDetail = () => {
  // const queryClient = useQueryClient();
  return useMutation<{}, AxiosError, { id: string; data: object }>({
    mutationFn: async ({ id, data }) => {
      const response = await Instance({
        url: `/brokerage/agent/${id}`,
        method: "PUT",
        data,
      });
      return response.data;
    },
    retry: false,
    // onSuccess: () => {
    //   queryClient.invalidateQueries({
    //     queryKey: ["imjangList"],
    //   });
    // },
  });
};

// 중개사 등록
export const usePostAgentInfo = () => {
  // const queryClient = useQueryClient();
  return useMutation<{}, AxiosError, {}>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/brokerage/agent`,
        method: "POST",
        data,
      });
      return response.data;
    },
    retry: false,
    // onSuccess: () => {
    //   queryClient.invalidateQueries({
    //     queryKey: ["imjangList"],
    //   });
    // },
  });
};

// /brokerage/agent
