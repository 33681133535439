import styled from "styled-components";

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.blueGray900};
  font-size: 32px;
  font-weight: 600;
  line-height: 42px; /* 131.25% */
  letter-spacing: -0.64px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 8px 0 24px;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1.5px solid ${({ theme }) => theme.colors.blueGray200};
  background: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.blueGrayA100};
  }

  img {
    flex: 1;
    margin-right: 12px;
    width: 32px;
    height: 32px;
  }

  p {
    margin-left: 12px;
    color: ${({ theme }) => theme.colors.blueGray900};
    font-size: 18px;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
    letter-spacing: -0.36px;
  }
`;
