export default function UsersIcon({ active }: { active: boolean | undefined }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_85_10731)">
        <path
          d="M11.3332 6.00001C11.3332 5.26363 10.7362 4.66667 9.99984 4.66667C9.26346 4.66667 8.6665 5.26363 8.6665 6.00001V10C8.6665 10.7364 9.26346 11.3333 9.99984 11.3333C10.7362 11.3333 11.3332 10.7364 11.3332 10V6.00001Z"
          fill={active ? "#4D5664" : "#B2BEC9"}
        />
        <path
          d="M24.6667 6.00008C24.6667 5.2637 24.0697 4.66675 23.3333 4.66675C22.597 4.66675 22 5.2637 22 6.00008V10.0001C22 10.7365 22.597 11.3334 23.3333 11.3334C24.0697 11.3334 24.6667 10.7365 24.6667 10.0001V6.00008Z"
          fill={active ? "#4D5664" : "#B2BEC9"}
        />
        <path
          d="M24.6666 7.3335H8.66659C7.93021 7.3335 7.33325 7.93045 7.33325 8.66683V26.0002C7.33325 26.7365 7.93021 27.3335 8.66659 27.3335H24.6666C25.403 27.3335 25.9999 26.7365 25.9999 26.0002V8.66683C25.9999 7.93045 25.403 7.3335 24.6666 7.3335Z"
          fill={active ? "#4D9EFF" : "#B2BEC9"}
        />
        <path
          d="M20 11.3335H10.6667C10.2985 11.3335 10 11.632 10 12.0002C10 12.3684 10.2985 12.6668 10.6667 12.6668H20C20.3682 12.6668 20.6667 12.3684 20.6667 12.0002C20.6667 11.632 20.3682 11.3335 20 11.3335Z"
          fill="white"
        />
        <path
          d="M16 16.6667H10.6667C10.2985 16.6667 10 16.9652 10 17.3334C10 17.7016 10.2985 18.0001 10.6667 18.0001H16C16.3682 18.0001 16.6667 17.7016 16.6667 17.3334C16.6667 16.9652 16.3682 16.6667 16 16.6667Z"
          fill="white"
        />
        <path
          d="M22.6667 14H10.6667C10.2985 14 10 14.2985 10 14.6667C10 15.0349 10.2985 15.3333 10.6667 15.3333H22.6667C23.0349 15.3333 23.3333 15.0349 23.3333 14.6667C23.3333 14.2985 23.0349 14 22.6667 14Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_85_10731">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
