import axios, { AxiosInstance, InternalAxiosRequestConfig } from "axios";
import { Cookies } from "react-cookie";

// 기본 URL
export const communityBaseURL = process.env.REACT_APP_BACKEND_COMMUNITY_HOST;
export const baseUrl = process.env.REACT_APP_BACKEND_HOST;

const cookies = new Cookies();

// axios 인스턴스 생성 함수
function CreateAxiosInstance(baseURL: string): AxiosInstance {
  const instance = axios.create({ baseURL });
  instance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      const token = cookies.get("access_token");
      if (token && token !== "undefined") {
        config.headers["Authorization"] = `Bearer ${token}`;
        // AuthInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
      } else {
        console.log("토큰없음");
      }
      return config;
    },
    (error) => {
      console.error(error);
      return Promise.reject(error);
    }
  );

  return instance;
}

export const PublicInstance = CreateAxiosInstance(communityBaseURL);
export const AuthInstance = CreateAxiosInstance(communityBaseURL);
export const Instance = CreateAxiosInstance(baseUrl);
