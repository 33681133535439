export default function ConsultationReportIcon({
  active,
}: {
  active: boolean | undefined;
}) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <g clipPath="url(#clip0_11319_161228)">
        <path
          opacity="0.2"
          d="M16 22C19.3137 22 22 19.3137 22 16C22 12.6863 19.3137 10 16 10C12.6863 10 10 12.6863 10 16C10 19.3137 12.6863 22 16 22Z"
          fill="white"
        />
        <path
          d="M23.7143 7H8.28571C7.57563 7 7 7.59695 7 8.33333V25.6667C7 26.403 7.57563 27 8.28571 27H23.7143C24.4244 27 25 26.403 25 25.6667V8.33333C25 7.59695 24.4244 7 23.7143 7Z"
          fill={active ? "#4D9EFF" : "#B2BEC9"}
        />
        <path
          d="M16 23C19.3137 23 22 20.3137 22 17C22 13.6863 19.3137 11 16 11C12.6863 11 10 13.6863 10 17C10 20.3137 12.6863 23 16 23Z"
          stroke="white"
          strokeMiterlimit="10"
        />
        <path
          d="M13.668 17L15.668 19L19.001 15"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="9"
          y="5"
          width="2"
          height="5"
          rx="1"
          fill={active ? "#4D5664" : "#B2BEC9"}
        />
        <rect
          x="21"
          y="5"
          width="2"
          height="5"
          rx="1"
          fill={active ? "#4D5664" : "#B2BEC9"}
        />
      </g>
      <defs>
        <clipPath id="clip0_11319_161228">
          <rect width="32" height="32" fill="#4D9EFF" />
        </clipPath>
      </defs>
    </svg>
  );
}
