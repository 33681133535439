import {
  createColumnHelper,
  flexRender,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo } from "react";
import styled from "styled-components";
import { getCoreRowModel } from "@tanstack/react-table";
import "../../../../page/table.css";
import Pagination from "@components/core/paging/Pagination";
import { FormatOptions, numToKorean } from "num-to-korean";

export default function MaemulImjangTable({ data }) {
  const maemulInfoArray = useMemo(() => {
    if (data)
      return data.flatMap((item) =>
        item.maemulInfo.map((maemul) => ({
          ...maemul,
          danjiName: item.danjiInfo.대표단지명,
        }))
      );
    else return [];
  }, [data]);

  const columnHelper = createColumnHelper();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("businessDiv", {
        cell: ({ row }) => {
          return row.original["businessDiv"] || "-";
        },
        header: "거래유형",
        maxSize: 10,
      }),
      columnHelper.accessor("danjiName", {
        cell: ({ row }) => {
          return row.original["danjiName"] || "-";
        },
        header: "이름",
        maxSize: 20,
      }),
      columnHelper.accessor("dongFloorName", {
        cell: ({ row }) => {
          return row.original["dongFloorName"] || "-";
        },
        header: "동/층",
        maxSize: 10,
      }),
      columnHelper.accessor("roadAddress", {
        cell: ({ row }) => {
          return `${row.original["pyeong"]}평 / ${row.original["pyeongArea"]}`;
        },
        header: "평형",
        maxSize: 10,
      }),
      columnHelper.accessor("sellPrice", {
        cell: ({ row }) => {
          const price =
            row.original["businessDiv"] === "매매"
              ? row.original["sellPrice"]
              : row.original["deposit"];
          return numToKorean(price, FormatOptions.MIXED) + "원" || "-";
        },
        header: "금액",
        maxSize: 20,
      }),
      columnHelper.accessor("direction", {
        cell: ({ row }) => {
          return row.original["direction"] || "-";
        },
        header: "방향",
        maxSize: 10,
      }),
      columnHelper.accessor("maemulTag", {
        cell: ({ row }) => {
          const tags = {
            gm: "급매",
            sag: "세안고",
            hj: "확장",
            jm: "전망",
            nh: "남향",
            sl: "수리",
            ryd: "로얄동",
          };

          const result = Object.entries(row.original["maemulTag"])
            .filter(([key, value]) => value) // true인 항목만 필터링
            .map(([key]) => tags[key]) // 해당하는 문자열로 변환
            .join(", ");
          return result || "-";
        },
        header: "매물 상세",
        maxSize: 20,
      }),
    ];
  }, []);

  const table = useReactTable({
    data: maemulInfoArray,
    columns,
    enableRowSelection: true,
    enableMultiRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  useEffect(() => {
    table.setPageSize(5);
  }, []);

  useEffect(() => {
    table.reset();
  }, [data]);

  return (
    <Container>
      <SubTitle>매물 상세정보</SubTitle>
      <table className="contentsTable">
        <thead className="contentsThead">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="contentsHeader"
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="contentsData">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination table={table} />
    </Container>
  );
}

const Container = styled.div`
  /* padding-top: 80px; */
`;

const SubTitle = styled.h2`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 18px;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;
`;
