import styled from "styled-components";
import NoticeInput from "./NoticeInput";
import { usePostNotice } from "@hooks/admin";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function AddNoticeContainer() {
  const navigate = useNavigate();
  const { mutate } = usePostNotice();
  const [noticeInfo, setNoticeInfo] = useState({
    title: "",
    content: "",
  });

  const onSubmit = () => {
    mutate(noticeInfo, {
      onSuccess: () => navigate("/admin/config/notice/list"),
    });
  };

  return (
    <Container>
      <NoticeInput
        noticeInfo={noticeInfo}
        setNoticeInfo={setNoticeInfo}
        onSubmit={onSubmit}
      />
    </Container>
  );
}

const Container = styled.div``;
