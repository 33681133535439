export default function ContentsIcon({
  active,
}: {
  active: boolean | undefined;
}) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_72_9205)">
        <path
          d="M25.8928 4H9.40728C8.79578 4 8.30005 4.4885 8.30005 5.0911V18.1843C8.30005 18.7869 8.79578 19.2754 9.40728 19.2754H25.8928C26.5043 19.2754 27 18.7869 27 18.1843V5.0911C27 4.4885 26.5043 4 25.8928 4Z"
          fill={active ? "#DCE4EB" : "#DCE4EB"}
        />
        <path
          d="M25.9001 7.27319H7.2001C6.59258 7.27319 6.1001 7.7617 6.1001 8.3643V21.4575C6.1001 22.0601 6.59258 22.5486 7.2001 22.5486H25.9001C26.5076 22.5486 27.0001 22.0601 27.0001 21.4575V8.3643C27.0001 7.7617 26.5076 7.27319 25.9001 7.27319Z"
          fill={active ? "#B2D6FF" : "#C3D0DB"}
        />
        <path
          d="M13.4777 12.4082C13.6845 12.6132 13.965 12.7284 14.2575 12.7284H25.9C26.5075 12.7284 27 13.2169 27 13.8195V26.9089C27 27.5115 26.5075 28 25.9 28H6.1C5.49247 28 5 27.5115 5 26.9089V11.6377C5 11.0351 5.49247 10.5466 6.1 10.5466H11.1445C11.4361 10.5466 11.7158 10.6615 11.9221 10.866L13.4777 12.4082Z"
          fill={active ? "#4D9EFF" : "#B2BEC9"}
        />
        <rect x="12" y="25" width="8" height="1" rx="0.5" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_72_9205">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
