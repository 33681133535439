export default function AccountingIcon({
  active,
}: {
  active: boolean | undefined;
}) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" />
      <g clipPath="url(#clip0_124_21801)">
        <path
          d="M25 9H7C5.89543 9 5 9.89543 5 11V21C5 22.1046 5.89543 23 7 23H25C26.1046 23 27 22.1046 27 21V11C27 9.89543 26.1046 9 25 9Z"
          fill={active ? "#1C84FF" : "#B2BEC9"}
        />
        <path
          d="M24.9999 19.3333C24.9999 20.2538 24.1452 21 23.0908 21"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M23.0908 11C24.1452 11 24.9999 11.7462 24.9999 12.6667"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M7 12.6667C7 11.7462 7.8547 11 8.9091 11"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M8.9091 21C7.8547 21 7 20.2538 7 19.3333"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z"
          fill="white"
        />
        <path
          d="M13.8172 14.2914C14.0916 14.1892 14.3196 14.2914 14.4125 14.5847L14.9729 16.4201L15.5333 14.5575C15.5919 14.3597 15.7671 14.2436 16.0009 14.2503C16.2632 14.257 16.4035 14.3936 16.4616 14.5847L17.0278 16.4268L17.5708 14.5847C17.6294 14.3869 17.7872 14.1892 18.1486 14.2775C18.3766 14.3325 18.5692 14.578 18.4758 14.8508L17.6642 17.3001C17.6062 17.4845 17.384 17.7501 17.022 17.7501C16.6136 17.7501 16.4384 17.4773 16.3861 17.3001L15.9887 15.9902L15.6151 17.3001C15.5687 17.4773 15.3702 17.7501 14.9729 17.7501C14.5819 17.7501 14.4009 17.4707 14.3428 17.3001L13.5254 14.892C13.4494 14.6737 13.5428 14.3936 13.8172 14.2914Z"
          fill={active ? "#1C84FF" : "#B2BEC9"}
        />
      </g>
      <defs>
        <clipPath id="clip0_124_21801">
          <rect
            width="28"
            height="28"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
