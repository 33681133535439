import styled from "styled-components";
import ViewNoticeTable from "./ViewNoticeTable";
import { useEffect, useState } from "react";
import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import NoticePopup from "./NoticePopup";
import { useGetNoticeList } from "@hooks/influencer";

export default function ViewNoticeContainer() {
  const { mutate, data: tableData } = useGetNoticeList();
  const [data, setData] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [popupContent, setPopupContent] = useState({
    original: { idx: 0, content: "", title: "", publishedDate: "" },
  });

  useEffect(() => {
    mutate(undefined);
  }, []);

  useEffect(() => {
    if (tableData) {
      setData(tableData.announcements);
    }
  }, [tableData]);

  const onClick = (data) => {
    setIsVisible(true);
    setPopupContent(data);
  };

  return (
    <Container>
      <ViewNoticeTable data={data} onClick={onClick} />
      <PopUpContainer isVisible={isVisible} setIsVisible={setIsVisible}>
        <NoticePopup data={popupContent.original} setIsVisible={setIsVisible} />
      </PopUpContainer>
    </Container>
  );
}

const Container = styled.div``;
