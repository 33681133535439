import { Title } from "@components/styles";
import styled from "styled-components";
import ViewNoticeContainer from "@components/page/influencer/notice/ViewNoticeContainer";
import { useMenu } from "@features/useMenu";
import { useEffect } from "react";
import ContentsTable from "@components/page/admin/report/contents/ContentsTable";

export default function Notice() {
  const { setCurrentMenu } = useMenu();

  useEffect(() => {
    setCurrentMenu("notice");
  }, []);

  return (
    <Container>
      <Title>공지사항</Title>
      <ViewNoticeContainer />
    </Container>
  );
}

const Container = styled.div``;
