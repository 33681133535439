import ClockIcon from "@assets/icon/ClockIcon";
import { Arrow22 } from "@components/core/icon/arrow";
import { ArrowDirectionEnum } from "@components/core/icon/arrow/Arrow.interfaces";
import { Radio24 } from "@components/core/icon/radio";
import { RadioEnum } from "@components/core/icon/radio/Radio.interfaces";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import Calendar28 from "@assets/icon/Calendar28";
import { useEffect, useState } from "react";
import moment from "moment";
import { formatTime } from "@utils/date";

const methodArr = [
  { title: "즉시 발송", value: "direct" },
  { title: "예약 발송", value: "scheduled" },
];

const defaultTime = new Date();
defaultTime.setHours(9, 0, 0, 0); // 오전 9시로 설정

export default function PushTime({ pushInfo, setPushInfo }) {
  const [method, setMethod] = useState(pushInfo.push_type || "scheduled");
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(defaultTime);

  const 시간_선택 = () => {
    const 날짜 = moment(date).format("YYYY-MM-DD");
    const 시간 = moment(time).format("HH:mm");

    setPushInfo((prev) => ({ ...prev, schedule: `${날짜} ${시간}` }));
  };

  useEffect(() => {
    if (!pushInfo.schedule) return;

    const dateTimeString = pushInfo.schedule;
    const dateString = dateTimeString.split(" ")[0];
    const timeString = dateTimeString.split(" ")[1];

    const [year, month, day] = dateString.split("-").map(Number);
    const [hours, minutes] = timeString.split(":").map(Number);

    const newDate = new Date(year, month - 1, day);
    const newTime = new Date();
    newTime.setHours(hours, minutes, 0, 0); // Set hours, minutes, seconds, and milliseconds

    setDate(newDate);
    setTime(newTime);
    setMethod(pushInfo.push_type);
  }, [pushInfo]);

  useEffect(() => {
    setPushInfo((prev) => ({ ...prev, push_type: method }));
  }, [method]);

  return (
    <Container>
      <Row style={{ gap: 24, cursor: "pointer" }}>
        {methodArr.map((el, idx) => (
          <Row key={idx} onClick={() => setMethod(el.value)}>
            <Radio24
              status={
                el.value === method ? RadioEnum.Activate : RadioEnum.Default
              }
            />
            <p>{el.title}</p>
          </Row>
        ))}
      </Row>

      <DateInputWrapper style={{ marginTop: 16 }}>
        <p style={{ marginLeft: 0, marginRight: 10 }}>일시</p>
        <DatePicker
          selected={new Date(date)}
          onChange={(v) => setDate(v)}
          dateFormat="yyyy.MM.dd"
          minDate={new Date()}
          locale={ko}
          disabled={method === "direct"}
          shouldCloseOnSelect={false}
          onCalendarClose={시간_선택}
          customInput={
            <InputContainer style={{ marginRight: 10 }}>
              <Calendar28 />
              {date ? (
                <div
                  className="value"
                  style={{
                    color: method === "direct" ? "#808690" : "#4d5664",
                  }}
                >
                  {moment(date).format("YYYY-MM-DD")}
                </div>
              ) : (
                <div className="placeholder">날짜 선택</div>
              )}

              <div style={{ position: "absolute", right: 16, top: 10 }}>
                <Arrow22 direction={ArrowDirectionEnum.DOWN} />
              </div>
            </InputContainer>
          }
        />
        <DatePicker
          selected={time}
          onChange={(v) => setTime(v)}
          showTimeInput
          locale={ko}
          // isClearable={startDate ? true : false}
          shouldCloseOnSelect={false}
          onCalendarClose={시간_선택}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
          disabled={method === "direct"}
          timeCaption="Time"
          dateFormat="h:mm aa"
          customInput={
            <InputContainer>
              <ClockIcon />
              {date ? (
                <div
                  className="value"
                  style={{
                    color: method === "direct" ? "#808690" : "#4d5664",
                  }}
                >
                  {formatTime(time)}
                </div>
              ) : (
                <div className="placeholder">시간 선택</div>
              )}

              <div style={{ position: "absolute", right: 16, top: 10 }}>
                <Arrow22 direction={ArrowDirectionEnum.DOWN} />
              </div>
            </InputContainer>
          }
        />
      </DateInputWrapper>
    </Container>
  );
}

const Container = styled.div`
  padding: 20px 16px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
`;

const Row = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 12px;
    color: ${({ theme }) => theme.colors.blueGray700};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }
`;

const DateInputWrapper = styled(Row)`
  .react-datepicker-wrapper {
    display: flex;
    flex: 1;
    cursor: pointer;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px 12px;
  flex: 1;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: ${({ theme }) => theme.colors.blueGrayA100};

  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 18px;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;

  .placeholder {
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
  }

  .value {
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.blueGray700};
  }
`;
