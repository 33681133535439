import { Arrow22 } from "@components/core/icon/arrow";
import { ArrowDirectionEnum } from "@components/core/icon/arrow/Arrow.interfaces";
import { useGetPushGroup } from "@hooks/management";
import styled from "styled-components";

export default function SelectCohortGroup({ pushInfo, setPushInfo }) {
  const { data } = useGetPushGroup();

  return (
    <Container>
      <InputContainer>
        <select
          onChange={(e) =>
            setPushInfo((prev) => ({
              ...prev,
              target_group: e.target.value,
            }))
          }
          style={{
            color: pushInfo.target_group ? "#333e4d" : "#808690",
          }}
          value={pushInfo.target_group || "default"}
          required
        >
          <option value={"default"} disabled>
            코호트를 선택해주세요.
          </option>
          <option value={null}>코호트 선택 안 함</option>
          {data?.targets.map((el, idx) => (
            <option value={el.target_name} key={idx}>
              {el.target_name}
            </option>
          ))}
        </select>
        <div style={{ position: "absolute", right: 16, top: 10 }}>
          <Arrow22 direction={ArrowDirectionEnum.DOWN} />
        </div>
      </InputContainer>
    </Container>
  );
}

const Container = styled.div``;

const InputContainer = styled.div`
  position: relative;
  padding: 10px 20px;
  flex: 1;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: ${({ theme }) => theme.colors.blueGrayA100};

  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 18px;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;

  select {
    background-color: ${({ theme }) => theme.colors.blueGrayA100};
    width: 100%;
    border: none;

    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
    appearance: none;

    &:focus {
      outline: none;
    }
  }
`;
