import { Title } from "@components/styles";
import styled from "styled-components";
import StatChart from "./StatChart";
import { useEffect, useState } from "react";
import Legend from "./Legend";
import { useGetContentsStat } from "@hooks/influencer";

export default function AnnualContentsStat() {
  const [dateRange, setDateRange] = useState({
    start: "",
    end: "",
  });
  const { data } = useGetContentsStat({
    startDate: dateRange.start,
    endDate: dateRange.end,
  });

  useEffect(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const params = {
      start: `${currentYear}`,
      end: `${currentYear + 1}`,
    };
    setDateRange(params);
  }, []);

  return (
    <Container>
      <Title>연간 컨텐츠 통계</Title>
      <Legend />
      <StatChart
        data={data?.statistics || []}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
    </Container>
  );
}

const Container = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
`;
