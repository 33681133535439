import styled from "styled-components";
import GoogleLoginButton from "./GoogleLogin";
import NaverLoginButton from "./NaverLogin";
import { useNavigate } from "react-router-dom";
import { useMenu } from "@features/useMenu";
import { useEffect } from "react";

export default function Login() {
  const navigate = useNavigate();
  const { setGlobalThemeMode } = useMenu();

  useEffect(() => {
    setGlobalThemeMode("light");
  }, []);

  const reqHandle = () => {
    navigate("/login/req");
  };

  return (
    <Container>
      <Title>로그인</Title>
      <GoogleLoginButton />
      <div style={{ height: 10 }} />
      <NaverLoginButton />
      <LoginReqBtn onClick={reqHandle}>
        <p>회원가입에 문제가 있나요?</p>
      </LoginReqBtn>
    </Container>
  );
}

const Title = styled.h1`
  margin-bottom: 40px;
  font-family: Pretendard;
  font-size: 32px;
  font-weight: 600;
  line-height: 28px; /* 87.5% */
  letter-spacing: -0.64px;
  color: ${({ theme }) => theme.colors.blueGray800};
`;

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoginReqBtn = styled.button`
  position: fixed;
  bottom: 70px;
  cursor: pointer;

  p {
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
    color: ${({ theme }) => theme.colors.blueGray600};
  }
`;
