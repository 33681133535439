import AddUserContainer from "@components/page/admin/report/users/AddUserContainer";
import { Title } from "@components/styles";
import { useMenu } from "@features/useMenu";
import { useEffect } from "react";
import styled from "styled-components";

export default function UserAdd() {
  const { setCurrentMenu } = useMenu();

  useEffect(() => {
    setCurrentMenu("users");
  }, []);

  return (
    <Container>
      <Title>회원 추가</Title>
      <AddUserContainer />
    </Container>
  );
}

const Container = styled.div``;
