/* eslint-disable no-restricted-globals */
import B1TypeInputBoxTyping from "@components/core/BTypeInput/B1TypeInputBoxTyping";
import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import { usePostContent } from "@hooks/influencer";
import { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function PreQuestionInput({
  onSubmit,
  preQuestionInfo,
  setPreQuestionInfo,
}) {
  const inputRef_1 = useRef<HTMLInputElement>(null);
  const inputRef_2 = useRef<HTMLInputElement>(null);

  const isDone = useMemo(() => {
    return !!(preQuestionInfo.title && preQuestionInfo.notionUrl);
  }, [preQuestionInfo]);

  const checkUrl = (url) => {
    let regex =
      /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

    if (regex.test(url)) {
      return true;
    } else {
      return false;
    }
  };

  const onClick = () => {
    if (checkUrl(preQuestionInfo.notionUrl)) {
      onSubmit();
    } else {
      alert("유효하지 않은 링크입니다.");
    }
  };

  return (
    <Container>
      <B1TypeInputBoxTyping
        inputRef={inputRef_1}
        inputType={InputTypeEnum.OnlyText}
        label={"보고서 이름"}
        placeholder={"보고서 이름"}
        value={preQuestionInfo.title}
        onChangeValue={(v: InputTypeEnum.OnlyText) =>
          setPreQuestionInfo((prev) => ({ ...prev, title: v }))
        }
        style={{ width: "40vw", minWidth: 570 }}
      />
      <B1TypeInputBoxTyping
        inputRef={inputRef_2}
        inputType={InputTypeEnum.OnlyText}
        placeholder={"사전질문 항목이 입력된 노션 링크를 입력하세요."}
        label={"보고서 작성 노션 링크"}
        value={preQuestionInfo.notionUrl}
        onChangeValue={(v: InputTypeEnum.OnlyText) =>
          setPreQuestionInfo((prev) => ({ ...prev, notionUrl: String(v) }))
        }
        style={{ width: "40vw", minWidth: 570 }}
      />
      <Button $isDone={isDone} onClick={onClick} disabled={!isDone}>
        제출하기
      </Button>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 4vw;
  justify-content: center;
  width: fit-content;
`;

const Button = styled.button<{ $isDone: boolean }>`
  margin: 16px auto 0;
  padding: 14px 55px;
  width: fit-content;
  border-radius: 8px;
  border: none;
  background: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.primaryBlue100 : theme.colors.blueGray100};
  cursor: pointer;

  color: ${({ theme, $isDone }) =>
    $isDone ? "white" : theme.colors.blueGray500};
  font-size: 20px;
  font-weight: 500;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;

  &:disabled {
    cursor: not-allowed;
  }
`;
