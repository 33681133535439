import { Title } from "@components/styles";
import {
  convertToKoreanDate,
  convertToKoreanTime,
} from "@hooks/calc/converToKoreanDate";
import { useGetApplicantInfo } from "@hooks/consultation";
import { handleDownload } from "@utils/download";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

export default function ViewComment() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data } = useGetApplicantInfo({ applicantId: id });

  const approvalState = data?.report.approvalState;
  const goRepost = () => {
    navigate(`/influencer/consultation/consulting/upload/${id}`);
  };

  const goEdit = () => {
    window.open(data?.report?.notionUrl, "_blank");
  };

  const pdf = useMemo(() => {
    if (data) {
      if (data.report.pdf) {
        return data.report.pdf;
      } else return [];
    } else return [];
  }, [data]);

  return (
    <Container>
      <Title>{data?.report.title || ""}</Title>
      <Content>
        <p className="comment">{data?.report.comment}</p>
        <p className="date">{convertToKoreanTime(data?.report.approvedAt)}</p>
      </Content>
      {approvalState === "approve" && (
        <File>
          <h5>최종 보고서 다운받기</h5>
          {pdf.map((el, idx) => (
            <a href={el.file} key={idx}>
              {el.fileName}
            </a>
          ))}
        </File>
      )}
      {approvalState === "reject" && (
        <Row>
          <Edit onClick={goEdit}>
            <p>수정하기</p>
          </Edit>
          <Upload onClick={goRepost}>
            <p>보고서 다시 제출</p>
          </Upload>
        </Row>
      )}
    </Container>
  );
}

const Container = styled.div``;

const Row = styled.div`
  display: flex;
`;

const Content = styled.div`
  margin-top: 32px;
  padding-bottom: 16px;
  max-width: 70vw;
  white-space: pre-wrap;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueGray100};

  .comment {
    margin-bottom: 32px;
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }

  .date {
    color: ${({ theme }) => theme.colors.blueGray400};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.28px;
  }
`;

const File = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: column;

  h5 {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.blueGray700};
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
  }

  a {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.primaryBlue100};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

const Upload = styled.div`
  margin-top: 40px;
  padding: 12px 10px;
  width: 118px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.primaryBlue100};
  cursor: pointer;

  p {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
    color: white;
  }
`;

const Edit = styled(Upload)`
  margin-right: 10px;
  background: ${({ theme }) => theme.colors.blue100};

  p {
    color: ${({ theme }) => theme.colors.primaryBlue100};
  }
`;
