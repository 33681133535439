export default function SourceDownIcon({
  active,
}: {
  active: boolean | undefined;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clipPath="url(#clip0_12016_4908)">
        <path
          d="M7.11268 6.22266H24.8905C25.8716 6.22266 26.6682 7.01928 26.6682 8.00043V11.556H5.3349V8.00043C5.3349 7.01928 6.13152 6.22266 7.11268 6.22266Z"
          fill={active ? "#99C8FF" : "#B2BEC9"}
        />
        <path
          d="M6.22379 11.5547H25.7793V24.888C25.7793 25.8692 24.9827 26.6658 24.0016 26.6658H8.00156C7.02041 26.6658 6.22379 25.8692 6.22379 24.888V11.5547Z"
          fill={active ? "#1c84ff" : "#98A8B6"}
        />
        <path
          d="M10.6682 18.668V23.1124H21.3349V18.668"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0016 14.2227V19.556"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.2238 18.668L16.0016 20.4457L17.7793 18.668"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_12016_4908">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.00156403)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
