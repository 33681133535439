import DevNav from "@components/nav/DevNav";
import { useMenu } from "@features/useMenu";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

export default function DevLayout() {
  const { setGlobalThemeMode } = useMenu();

  useEffect(() => {
    setGlobalThemeMode("dark");
  }, []);

  return (
    <Container>
      <DevNav />
      <OutletWrapper>
        <Outlet />
      </OutletWrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  background-color: #18171c;
  flex: 1;
  min-height: 100vh;
`;

const OutletWrapper = styled.main`
  display: flex;
  flex: 1;
  padding: 60px 0 40px 40px;
`;
