export default function IRIcon({ active }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clipPath="url(#clip0_19343_9293)">
        <rect x="9" y="5" width="2" height="5" rx="1" fill="#0069E5" />
        <rect x="21" y="5" width="2" height="5" rx="1" fill="#0069E5" />
        <path
          opacity="0.2"
          d="M16 22C19.3137 22 22 19.3137 22 16C22 12.6863 19.3137 10 16 10C12.6863 10 10 12.6863 10 16C10 19.3137 12.6863 22 16 22Z"
          fill="white"
        />
        <path
          d="M23.7148 6.99878H8.2862C7.57612 6.99878 7.00049 7.59573 7.00049 8.33211V25.6654C7.00049 26.4018 7.57612 26.9988 8.2862 26.9988H23.7148C24.4249 26.9988 25.0005 26.4018 25.0005 25.6654V8.33211C25.0005 7.59573 24.4249 6.99878 23.7148 6.99878Z"
          fill={active ? "#4D9EFF" : "#B2BEC9"}
        />
        <path
          d="M14.0303 21.4844V12H17.4101C19.6109 12 20.6982 13.2314 20.6982 14.9737C20.6982 16.2509 20.1218 17.2203 18.969 17.6461L21.065 21.4844H19.4275L17.5018 17.9081H17.4101H15.4713V21.4844H14.0303ZM15.4713 16.6636H17.266C18.6677 16.6636 19.2441 16.0479 19.2441 14.9737C19.2441 13.8995 18.6677 13.2314 17.266 13.2314H15.4713V16.6636Z"
          fill="white"
        />
        <path d="M12.3726 12V21.4844H10.9316V12H12.3726Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_19343_9293">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
