import { ActionEnum } from "@components/core/icon/action/Action.interfaces";
import Action24 from "@components/core/icon/action/Action24";
import { Close22 } from "@components/core/icon/close";
import { deeplinkList } from "@constants/deeplink";
import styled from "styled-components";

const options: Record<number, string> = {
  1: "[BNB] 처방전 스크린",
  3: "[BNB] 홈 스크린",
  4: "[BNB] 상담소",
  5: "[BNB] 마이 스크린",
  31: "[미니테스트] 금리체력",
  32: "[미니테스트] 적정가격",
  33: "[미니테스트] 교통",
  54: "임장 온보딩",
  53: "단지 상세",
};

export default function SendCheckPopup({
  pushInfo,
  postPush,
  setIsVisible발송확인팝업,
}) {
  const handleBtn = () => {
    setIsVisible발송확인팝업(false);
    postPush();
  };

  // 스크린 찾는 함수
  const findSelectedOption = (value) => {
    if (!value) return "-";
    for (const group of deeplinkList) {
      const selectedOption = group.contents.find(
        (content) => content.value === value
      );
      if (selectedOption) {
        return selectedOption.title;
      }
    }
    return "-";
  };

  return (
    <Container>
      <Row>
        <Title>메시지 발송하기</Title>
        <span onClick={() => setIsVisible발송확인팝업(false)}>
          <Close22 />
        </span>
      </Row>
      <Content>
        <div className="row">
          <p className="head">발송 방법</p>
          <p className="body">
            {pushInfo.method === "single" ? "단일 발송" : "다중 발송"}
          </p>
        </div>
        <div className="row">
          <p className="head">발송 대상</p>
          {pushInfo.method === "single" ? (
            <p className="body">
              {pushInfo.nickname}
              <span style={{ fontSize: 14, color: "#808690" }}>
                ({pushInfo.target})
              </span>
            </p>
          ) : (
            <p className="body">{pushInfo.target_group}</p>
          )}
        </div>
        <div className="row">
          <p className="head">발송 내용</p>
          <div className="body">
            <p>{pushInfo.push_title}</p>
            <p style={{ marginTop: 8 }}>{pushInfo.push_body}</p>
          </div>
        </div>
        <div className="row">
          <p className="head">연결 스크린</p>
          <p className="body">
            {findSelectedOption(pushInfo.deeplink)}{" "}
            {pushInfo.id ? `/ ${pushInfo.id}` : ""}
          </p>
        </div>
        <div className="row">
          <p className="head">발송 시점</p>
          <p className="body">
            {pushInfo.push_type === "direct" ? "즉시" : pushInfo.schedule}
          </p>
        </div>
      </Content>
      <Caution>
        <Action24 status={ActionEnum.CAUTION} />
        <p>
          앞서 작성한 내용을 바탕으로 실제 유저에게 푸시가 발송됩니다. {"\n"}
          발송 후 취소가 불가능하니 주의하시길 바랍니다.
        </p>
      </Caution>

      <Btn onClick={handleBtn}>
        <p>발송</p>
      </Btn>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 20px;
  width: 460px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.blueGray800};
  text-align: left;
  font-size: 24px;
  font-weight: 600;
  line-height: 26px; /* 108.333% */
  letter-spacing: -0.48px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding: 20px 18px;
  border-radius: 8px;
  gap: 24px;
  background-color: ${({ theme }) => theme.colors.blueGrayA200};

  .row {
    display: flex;
    width: 100%;
  }

  .head {
    width: 25%;
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }

  .body {
    width: 75%;
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 18px;
    font-weight: 500;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.36px;
  }
`;

const Caution = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0 20px;

  p {
    flex: 1;
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
    white-space: pre-wrap;
  }
`;

const Btn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  padding: 9px 0;
  width: 90px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.primaryBlue100};
  cursor: pointer;

  p {
    color: ${({ theme }) => theme.colors.white};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }
`;
