import { useMutation } from "@tanstack/react-query";
import { AuthInstance } from "../../api";

// index

interface LoginRes {
  access_token: string;
  userType: string;
}

interface NaverTokenRes {
  message: string;
  naverToken: string;
}

export const useGoogleLogin = () =>
  useMutation<LoginRes, Error, { token: string }>(async (data) => {
    const response = await AuthInstance({
      url: "/auth/google",
      method: "POST",
      data,
    });

    return response.data;
  });

export const useNaverLogin = () =>
  useMutation<LoginRes, Error, { token: string; phone_number?: string }>(
    async (data) => {
      const response = await AuthInstance({
        url: "/auth/naver",
        method: "POST",
        data,
      });

      return response.data;
    }
  );

export const useNaverToken = () =>
  useMutation<NaverTokenRes, Error, { code: string }>(async (data) => {
    const response = await AuthInstance({
      url: "/auth/naver/token",
      method: "POST",
      data,
    });

    return response.data;
  });

export const useGetMe = () =>
  useMutation(async (data) => {
    const response = await AuthInstance({
      url: "/me",
      method: "GET",
      data,
    });

    return response.data;
  });
