import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function DevMain() {
  const navigate = useNavigate();
  const goDev = () => {
    navigate("/dev/version");
  };
  const goAdmin = () => {
    navigate("/admin/config/contents");
  };

  return (
    <Container>
      <Title>일하러 왔는가 자네</Title>
      <Row style={{ marginTop: 20 }}>
        <UploadButton onClick={goAdmin} style={{ backgroundColor: "#f0f2f5" }}>
          <p style={{ color: "#666e7a" }}>아니요</p>
        </UploadButton>
        <div style={{ width: 20 }} />
        <UploadButton onClick={goDev}>
          <p>네</p>
        </UploadButton>
      </Row>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.blueGray800};
  font-weight: 800;
  font-size: 32px;
`;

const Row = styled.div`
  display: flex;
`;

const UploadButton = styled.div`
  padding: 9px 16px;
  width: fit-content;
  height: fit-content;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.primaryBlue100};
  cursor: pointer;

  p {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
    color: white;
  }
`;
