import { useEffect, useMemo, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { getCoreRowModel } from "@tanstack/react-table";
import styled from "styled-components";
import cn from "classnames";
import Pagination from "@components/core/paging/Pagination";
import "../../../table.css";
import {
  convertToKoreanDate,
  convertToKoreanTime,
  convertToKoreanTimeFromUTC,
} from "@hooks/calc/converToKoreanDate";
import Empty from "@components/common/Empty";
import { Link, useNavigate } from "react-router-dom";
import { useMenu } from "@features/useMenu";

export default function IRTable({ data }) {
  const navigate = useNavigate();
  const { globalThemeMode } = useMenu();

  const columnHelper = createColumnHelper();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("number", {
        cell: (info) => info.row.index + 1,
        header: "순번",
        maxSize: 12,
      }),
      columnHelper.accessor("createdAt", {
        cell: ({ getValue }) => {
          return convertToKoreanTimeFromUTC(getValue());
        },
        header: "요청 날짜",
        maxSize: 15,
      }),
      columnHelper.accessor("name", {
        header: "이름",
        maxSize: 12,
      }),
      columnHelper.accessor("company", {
        header: "소속",
        maxSize: 15,
      }),
      columnHelper.accessor("email", {
        header: "이메일",
        maxSize: 25,
      }),
      columnHelper.accessor("id", {
        cell: ({ row }) => {
          const onClick = () => {
            navigate(`/admin/ir/${row.original["id"]}`);
          };
          return <EditContents onClick={onClick}>요청 상세정보</EditContents>;
        },
        header: "상세",
        maxSize: 20,
      }),
    ];
  }, []);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false, //페이지를 변경하는 상태 변화가 발생할 때 pageIndex는 0으로 리셋되는 옵션
  });

  useEffect(() => {
    table.setPageSize(10);
  }, []);

  return (
    <Container>
      <table className="contentsTable">
        <thead
          className={cn("contentsThead", {
            darkContentsThead: globalThemeMode === "dark",
          })}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={cn("contentsHeader", {
                    darkContentsHeader: globalThemeMode === "dark",
                  })}
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className={cn("contentsData", {
                    darkContentsData: globalThemeMode === "dark",
                  })}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Empty data={data} title="컨텐츠가 없어요" />
      <Pagination table={table} />
    </Container>
  );
}

const Container = styled.div``;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.primaryBlue100};
`;

const EditContents = styled.p`
  color: ${({ theme }) => theme.colors.primaryBlue100};
  cursor: pointer;
`;

const Title = styled.h2`
  margin-left: 20px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
