import { Title } from "@components/styles";
import styled from "styled-components";
import { useMenu } from "@features/useMenu";
import { useEffect } from "react";
import NoticeListContent from "@components/page/admin/report/notice/NoticeListContent";

export default function NoticeList() {
  const { setCurrentMenu } = useMenu();

  useEffect(() => {
    setCurrentMenu("notice");
  }, []);

  return (
    <Container>
      <Title> 공지사항 업로드</Title>
      <InnerContainer>
        <NoticeListContent />
      </InnerContainer>
    </Container>
  );
}

const Container = styled.div``;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: fit-content;
`;
