import {
  createColumnHelper,
  flexRender,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo } from "react";
import styled from "styled-components";
import { getCoreRowModel } from "@tanstack/react-table";
import "../../../../table.css";
import Empty from "@components/common/Empty";
import { Link } from "react-router-dom";
import ManualPagination from "@components/core/paging/ManualPagination";
import PushStatusChip from "./PushStatusChip";

export default function PushScheduleTable({ data, total }) {
  const moment = require("moment-timezone");

  const columnHelper = createColumnHelper();

  const columns = useMemo(() => {
    return [
      columnHelper.accessor("status", {
        cell: ({ getValue }) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PushStatusChip status={getValue()} />
            </div>
          );
        },

        header: "상태",
        maxSize: 9,
      }),
      columnHelper.accessor("schedule", {
        cell: ({ getValue }) => {
          return moment(getValue()).format("YYYY.MM.DD HH:mm");
        },
        header: "발송 날짜",
        maxSize: 14,
      }),
      columnHelper.accessor("target", {
        // cell: ({ getValue }) => getValue().toLocaleString(),
        header: "대상",
        maxSize: 20,
      }),
      columnHelper.accessor("title", {
        header: "타이틀",
        maxSize: 20,
      }),
      columnHelper.accessor("requested_number", {
        header: "총 발송 인원",
        maxSize: 9,
      }),
      columnHelper.accessor("valid_number", {
        header: "수신 대상 인원",
        maxSize: 9,
      }),
      columnHelper.accessor("accepted_number", {
        header: "수신 확인 인원",
        maxSize: 9,
      }),
      columnHelper.accessor("schedule_id", {
        cell: ({ getValue }) => (
          <StyledLink to={`/admin/push-schedule/${getValue()}`}>
            상세정보 보기
          </StyledLink>
        ),
        header: "상세정보",
        maxSize: 10,
      }),
    ];
  }, []);

  // useReactTable로 테이블 구조 정의
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false, //페이지를 변경하는 상태 변화가 발생할 때 pageIndex는 0으로 리셋되는 옵션
  });

  useEffect(() => {
    table.setPageSize(10);
  }, [table]);

  return (
    <Container>
      <table className="mediumContentsTable">
        <thead className="contentsThead">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="contentsHeader"
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="contentsData">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Empty data={data} title="해당기간 내 푸시 내역이 없어요" />
      <ManualPagination total={total} />
    </Container>
  );
}

const Container = styled.div`
  padding-top: 24px;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primaryBlue100};
`;
