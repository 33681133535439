import { useMemo } from "react";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
} from "@tanstack/react-table";
import { getCoreRowModel } from "@tanstack/react-table";
import styled from "styled-components";
import cn from "classnames";
import "../../../table.css";
import Empty from "@components/common/Empty";
import { useNavigate } from "react-router-dom";
import { useMenu } from "@features/useMenu";

export default function MaemulTable({ data, setSelectedValue }) {
  const navigate = useNavigate();
  const { globalThemeMode } = useMenu();

  const columnHelper = createColumnHelper();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("danjiCode", {
        cell: ({ row }) => row.original["_source"].단지코드,
        header: "단지코드",
        maxSize: 10,
      }),
      columnHelper.accessor("danjiName", {
        cell: ({ row }) => (
          <HightLight
            dangerouslySetInnerHTML={{
              __html:
                row.original["highlight"].단지명_ngram ||
                row.original["_source"].단지명,
            }}
          ></HightLight>
        ),
        header: "단지명",
        maxSize: 15,
      }),
      columnHelper.accessor("doroAddress", {
        cell: ({ row }) => row.original["_source"].도로명주소,
        header: "도로명 주소",
        maxSize: 15,
      }),
      columnHelper.accessor("세대수", {
        cell: ({ row }) => row.original["_source"].세대수 + "세대",
        header: "세대수",
        maxSize: 10,
      }),
      columnHelper.accessor("면적", {
        cell: ({ row }) =>
          `${row.original["_source"].평형별정보[0].전용면적}m² ~ ${
            row.original["_source"].평형별정보[
              row.original["_source"].평형별정보.length - 1
            ].전용면적
          }m²`,
        header: "면적",
        maxSize: 10,
      }),
      // columnHelper.accessor("detail", {
      //   cell: ({ row }) => {
      //     const onClick = () => {
      //       navigate(`/admin/maemul/${row.original["_source"].단지코드}`);
      //     };
      //     return <EditContents onClick={onClick}>매물상세</EditContents>;
      //   },
      //   header: "-",
      //   maxSize: 10,
      // }),
    ];
  }, []);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false, //페이지를 변경하는 상태 변화가 발생할 때 pageIndex는 0으로 리셋되는 옵션
  });

  return (
    <Container>
      <table className="contentsTable">
        <thead
          className={cn("contentsThead", {
            darkContentsThead: globalThemeMode === "dark",
          })}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={cn("contentsHeader", {
                    darkContentsHeader: globalThemeMode === "dark",
                  })}
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <TableRow
              key={row.id}
              onClick={() => setSelectedValue(row.original)}
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className={cn("contentsData", {
                    darkContentsData: globalThemeMode === "dark",
                  })}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </TableRow>
          ))}
        </tbody>
      </table>
      <Empty data={data} title="컨텐츠가 없어요" />
    </Container>
  );
}

const Container = styled.div``;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.primaryBlue100};
`;

const EditContents = styled.p`
  color: ${({ theme }) => theme.colors.primaryBlue100};
  cursor: pointer;
`;

const TableRow = styled.tr`
  &:hover {
    background-color: ${({ theme }) => theme.colors.blueA100};
  }
`;

const HightLight = styled.p`
  em {
    color: ${({ theme }) => theme.colors.primaryBlue100};
  }
`;
