import consultantSlice from "@features/consultantSlice";
import routerSlice from "@features/routerSlice";
import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
  reducer: { router: routerSlice, consultantSlice },
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
