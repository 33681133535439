import { useState } from "react";
import styled from "styled-components";
import StatusChip from "./StatusChip";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../../firebase";

const stateArr = ["deprecated", "live", "review", "develop"];
const color = {
  review: ["#0a3929", "#00cf8dd2"],
  deprecated: ["#6969696e", "#f4f6f894"],
  live: ["#1f2c4d", "#247cf7"],
  develop: ["#6c2a30be", "#f25763"],
};
export default function SelectVersionStatus({ state, version }) {
  const [editMode, setEditMode] = useState(false);

  const onClick = async (status) => {
    setEditMode((prev) => !prev);

    if (editMode) {
      onChange(status);
    }
  };

  const onChange = async (status) => {
    await setDoc(doc(db, "versions", version.version), {
      ...version,
      status,
    });
  };

  return (
    <Container>
      <StatusChip state={state} onClick={onClick} />
      {editMode && (
        <SelectBox>
          <div className="currentState">
            <ChipBox color={color[state][0]} onClick={() => onClick(state)}>
              <Circle color={color[state][1]} />
              <ChipText color={color[state][1]}> {state}</ChipText>
            </ChipBox>
          </div>
          {stateArr.map((el, idx) => (
            <SelectList style={{ borderWidth: idx === 2 ? 0 : 1 }} key={idx}>
              <StatusChip state={el} onClick={() => onClick(el)} />
            </SelectList>
          ))}
        </SelectBox>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const ChipBox = styled.div`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 20px;
  background: ${({ color }) => color};
  cursor: pointer;
`;

const Circle = styled.div`
  margin-right: 4px;
  width: 10px;
  height: 10px;
  border-radius: 20px;
  background-color: ${({ color }) => color};
`;

const ChipText = styled.p`
  color: ${({ color }) => color};
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.24px;
`;

const SelectBox = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  min-width: 170px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray400};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.blueGrayA200};
  box-shadow: 0 0 10px 6px rgba(255, 255, 255, 0.02);

  .currentState {
    padding: 10px;
    background-color: ${({ theme }) => theme.colors.blueGrayA100};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.blueGray400};
  }
`;

const SelectList = styled.div`
  padding: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueGray400};

  &:hover {
    background-color: ${({ theme }) => theme.colors.blueGray200};
  }
`;
