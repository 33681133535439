import { css } from "styled-components";

import PretendardRegular from "../fonts/Pretendard-Regular.woff2";
import PretendardMedium from "../fonts/Pretendard-Medium.woff2";
import PretendardSemiBold from "../fonts/Pretendard-SemiBold.woff2";
import PretendardBold from "../fonts/Pretendard-Bold.woff2";

export const fonts = css`
  @font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local("Pretendard"), url(${PretendardRegular}) format("woff2");
  }

  @font-face {
    font-family: "Pretendard-Medium";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local("Pretendard"), url(${PretendardMedium}) format("woff2");
  }

  @font-face {
    font-family: "Pretendard-SemiBold";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local("Pretendard"), url(${PretendardSemiBold}) format("woff2");
  }

  @font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local("Pretendard"), url(${PretendardBold}) format("woff2");
  }
`;
