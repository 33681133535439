import { GoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";
import styled from "styled-components";
import { useGoogleLogin } from "../../hooks/login";
import { AuthInstance } from "../../api";
import { useNavigate } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import AbsoluteFillLoading from "@components/common/AbsoluteFillLoading";

const GoogleLoginButton = () => {
  const navigate = useNavigate();
  const { mutate, isLoading } = useGoogleLogin();
  const cookies = new Cookies();

  // 쿠키 설정

  // const [cookies, setCookie] = useCookies(["access_token"]);

  const saveToken = (token: string) => {
    if (token) {
      cookies.set("access_token", token, { path: "/" });
    }
    // setCookie("access_token", token);
  };

  return (
    <>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <Button>
          <GoogleLogin
            onSuccess={(res) => {
              if (res.credential) {
                const token = res.credential;
                console.log("구글토큰", token);
                mutate(
                  { token },
                  {
                    onSuccess: (res) => {
                      console.log("🪄  GoogleLoginButton  res", res);
                      saveToken(res.access_token);
                      AuthInstance.defaults.headers.common.Authorization = `Bearer ${res.access_token}`;

                      if (res.userType === "influencer") {
                        navigate("/influencer/contents/upload");
                      } else {
                        navigate("/admin/config/contents");
                      }

                      console.log("access_token", res.access_token);
                    },
                    onError: () => {
                      console.log("구글 로그인 실패");
                    },
                  }
                );
              }
            }}
            onError={() => {
              alert("구글 로그인에 문제가 있습니다.");
            }}
            size="large"
            width={365}
            theme="filled_blue"
            // logo_alignment="center"
          />
        </Button>
      </GoogleOAuthProvider>
      {isLoading && <AbsoluteFillLoading />}
    </>
  );
};

const Button = styled.div`
  height: 40px;
`;

export default GoogleLoginButton;
