import styled from "styled-components";
import UserInput from "./UserInput";
import { useEditInfluencer, useGetInfluencerInfo } from "@hooks/admin";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function EditUserContainer() {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  console.log("🐙 ~ EditUserContainer ~ id:", id);

  const { mutate: getInfo } = useGetInfluencerInfo();
  const { mutate: editInfo } = useEditInfluencer();
  const [userInfo, setUserInfo] = useState({
    nickname: "",
    phone_number: "",
    name: "",
    email: "",
    memo: "",
    is_deleted: false,
  });

  useEffect(() => {
    getInfo(
      { id },
      {
        onSuccess: (res) => {
          const { name, email, phone_number, nickname, memo, is_deleted } =
            res.influencer;
          setUserInfo({
            name,
            email,
            phone_number,
            nickname,
            memo,
            is_deleted,
          });
        },
        onError: () => {
          return;
        },
      }
    );
  }, []);

  const onSubmit = () => {
    editInfo(
      { id, data: userInfo },
      {
        onSuccess: (res) => {
          navigate("/admin/config/users");
        },
        onError: (result) => {
          const error =
            result.response.data.message ||
            "일시적인 오류가 발생했어요. 잠시후 다시 시도해주세요.";
          console.log(error);
        },
      }
    );
  };
  return (
    <Container>
      <UserInput
        userInfo={userInfo}
        setUserInfo={setUserInfo}
        onSubmit={onSubmit}
      />
    </Container>
  );
}

const Container = styled.div``;
