import { ThemeProvider as StyledProvider } from "styled-components";
import { darkTheme, lightTheme, theme } from "./styles/theme";
import { useMenu } from "@features/useMenu";
// import { useMenu } from "@features/useMenu";

export const ThemeProvider = ({ children }) => {
  const { globalThemeMode } = useMenu();
  const theme = globalThemeMode === "light" ? lightTheme : darkTheme; // 테마 환경에 맞는 테마 컬러 가져오기.

  return <StyledProvider theme={theme}>{children}</StyledProvider>;
};
