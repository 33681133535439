export default function SourceIcon({
  active,
}: {
  active: boolean | undefined;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clipPath="url(#clip0_11319_161278)">
        <path
          d="M7.10981 6.22266H24.8876C25.8687 6.22266 26.6654 7.01928 26.6654 8.00043V11.556H5.33203V8.00043C5.33203 7.01928 6.12865 6.22266 7.10981 6.22266Z"
          fill={active ? "#99C8FF" : "#B2BEC9"}
        />
        <path
          d="M6.22266 11.5547H25.7782V24.888C25.7782 25.8692 24.9816 26.6658 24.0004 26.6658H8.00043C7.01928 26.6658 6.22266 25.8692 6.22266 24.888V11.5547Z"
          fill={active ? "#1C84FF" : "#98A8B6"}
        />
        <path
          d="M11.5547 13.332V17.7765H20.4436V13.332"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11319_161278">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
