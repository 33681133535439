import styled from "styled-components";
import NoticeInput from "./NoticeInput";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEditNotice, useGetNotice } from "@hooks/admin";
import { log } from "console";

export default function EditNoticeContainer() {
  const navigate = useNavigate();
  const params = useParams().id;
  const { mutate: getNotice } = useGetNotice();
  const { mutate: editNotice } = useEditNotice();
  const [noticeInfo, setNoticeInfo] = useState({
    title: "",
    content: "",
  });

  useEffect(() => {
    getNotice(
      { id: params },
      {
        onSuccess: (res) => {
          console.log(res);

          const { title, content } = res.announcement;
          setNoticeInfo({ title, content });
        },
      }
    );
  }, []);

  const onSubmit = () => {
    editNotice(
      { id: params, data: noticeInfo },
      {
        onSuccess: () => {
          alert("성공적으로 수정되었습니다.");
          navigate("/admin/config/notice/list");
        },
      }
    );
  };

  return (
    <Container>
      <NoticeInput
        noticeInfo={noticeInfo}
        setNoticeInfo={setNoticeInfo}
        onSubmit={onSubmit}
      />
    </Container>
  );
}

const Container = styled.div``;
