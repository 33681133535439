import styled from "styled-components";

import { useMemo } from "react";
import { ImjangStatusEnum } from "@hooks/imjang/Imjang.interfaces";

export default function ImjangStatusChip({ status }) {
  const bgColor = useMemo(() => {
    switch (status) {
      case ImjangStatusEnum["reception"]:
        return "#E8F3FF";
      case ImjangStatusEnum["waiting"]:
        return "#E9F9E8";
      case ImjangStatusEnum["proceed"]:
        return "#FFE9C7";
      case ImjangStatusEnum["cancel"]:
        return "#FDECEE";
      default:
        return "#F4F6F8";
    }
  }, [status]);

  const textColor = useMemo(() => {
    switch (status) {
      case ImjangStatusEnum["reception"]:
        return "#1C84FF";
      case ImjangStatusEnum["waiting"]:
        return "#22C014";
      case ImjangStatusEnum["proceed"]:
        return "#FF9C00";
      case ImjangStatusEnum["cancel"]:
        return "#F04452";
      default:
        return "#4D5664";
    }
  }, [status]);

  const text = useMemo(() => {
    switch (status) {
      case ImjangStatusEnum["reception"]:
        return "방문 접수";
      case ImjangStatusEnum["waiting"]:
        return "방문 대기";
      case ImjangStatusEnum["proceed"]:
        return "방문 진행";
      case ImjangStatusEnum["cancel"]:
        return "방문 취소";
      case ImjangStatusEnum["done"]:
        return "방문 완료";
      default:
        return "#4D5664";
    }
  }, [status]);

  return (
    <Container style={{ backgroundColor: bgColor }}>
      <Text style={{ color: textColor }}>{text}</Text>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  height: 32px;
  border-radius: 8px;
`;

const Text = styled.p`
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.28px;
`;
