import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import BTypeInputBoxTyping from "@components/core/BTypeInput/BTypeInputBoxTyping";
import BTypeTextArea from "@components/core/BTypeInput/BTypeTextArea";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import StarRating from "./StarRating";
import { useConsultantInfo } from "@features/useConsultantInfo";

export default function ReviewPopUp({
  setIsVisible,
  handleSubmit,
  selectedIdx,
}) {
  const { globalConsultantInfo, setGlobalConsultantInfo } = useConsultantInfo();
  const [reviewInfo, setReviewInfo] = useState({
    rating: 0,
    name: "",
    bodyText: "",
  });

  useEffect(() => {
    if (selectedIdx || selectedIdx === 0) {
      setReviewInfo({ ...globalConsultantInfo.reviews[selectedIdx] });
    }
  }, [selectedIdx]);

  const isDone = useMemo(() => {
    return reviewInfo.rating && reviewInfo.name && reviewInfo.bodyText;
  }, [reviewInfo]);

  return (
    <Container>
      <Title>리뷰등록</Title>
      <StarRating
        onChangeValue={(v: number) =>
          setReviewInfo((prev) => ({ ...prev, rating: v }))
        }
        rating={reviewInfo.rating}
        containerStyle={{ margin: "22px 0 20px" }}
      />
      <BTypeInputBoxTyping
        inputType={InputTypeEnum.OnlyText}
        placeholder={"작성자명"}
        value={reviewInfo.name}
        onChangeValue={(v: InputTypeEnum.OnlyText) =>
          setReviewInfo((prev) => ({ ...prev, name: v }))
        }
        style={{ padding: 0 }}
        inputStyle={{ paddingTop: 11, paddingBottom: 11 }}
      />
      <BTypeTextArea
        placeholder="작성글"
        textareaStyle={{
          padding: 0,
          maxWidth: 500,
          minHeight: 200,
          height: 200,
        }}
        value={reviewInfo.bodyText}
        onChangeValue={(v: InputTypeEnum.OnlyText) =>
          setReviewInfo((prev) => ({ ...prev, bodyText: v }))
        }
      />
      <div style={{ height: 13 }} />
      <Btn
        onClick={() => {
          setIsVisible(false);
          handleSubmit(reviewInfo);
        }}
        $isDone={Boolean(isDone)}
        disabled={!Boolean(isDone)}
      >
        <p>저장</p>
      </Btn>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  width: 376px;
  border-radius: 20px;
  background-color: white;
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.blueGray800};
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;
`;

const Btn = styled.button<{ $isDone: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  padding: 9px 0;
  border-radius: 8px;
  background: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.primaryBlue100 : theme.colors.blueGray100};
  cursor: pointer;

  p {
    color: ${({ theme }) => theme.colors.white};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }
`;
