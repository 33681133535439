import B1TypeInputBoxTyping from "@components/core/BTypeInput/B1TypeInputBoxTyping";
import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import BTypeInputBoxTyping from "@components/core/BTypeInput/BTypeInputBoxTyping";
import BTypeTextArea from "@components/core/BTypeInput/BTypeTextArea";
import BTypeInputBoxBtn from "@components/core/BTypeInput/BtypeInputBoxBtn";
import { usePostNotice } from "@hooks/admin";
import { useMemo, useRef, useState } from "react";
import styled from "styled-components";

export default function NoticeInput({ noticeInfo, setNoticeInfo, onSubmit }) {
  const inputRef_1 = useRef<HTMLInputElement>(null);
  const isDone = useMemo(() => {
    return noticeInfo.title && noticeInfo.content;
  }, [noticeInfo]);

  return (
    <Container>
      <BTypeInputBoxTyping
        inputRef={inputRef_1}
        inputType={InputTypeEnum.OnlyText}
        label={"공지제목"}
        placeholder={"공지제목을 등록하세요"}
        value={noticeInfo.title}
        onChangeValue={(v: InputTypeEnum.OnlyText) =>
          setNoticeInfo((prev) => ({ ...prev, title: v }))
        }
        style={{ width: "70%", maxWidth: 1200 }}
      />
      <BTypeTextArea
        label="공지내용"
        placeholder="공지내용을 등록하세요"
        textareaStyle={{ width: "70%", maxWidth: 1200, height: 550 }}
        value={noticeInfo.content}
        onChangeValue={(v: InputTypeEnum.OnlyText) =>
          setNoticeInfo((prev) => ({ ...prev, content: v }))
        }
      />
      <div style={{ padding: "20px 0 0", width: "70%", maxWidth: 1200 }}>
        <Button $isDone={isDone} disabled={!isDone} onClick={onSubmit}>
          등록하기
        </Button>
      </div>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  margin-top: 60px;
  margin-bottom: 30px;
  min-width: 1100px;
  flex: 1;

  .row {
    display: flex;
    flex-grow: 1;
  }
`;

const Button = styled.button<{ $isDone: boolean }>`
  padding: 10px 0;
  width: 100%;
  border-radius: 8px;
  border: none;
  background: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.primaryBlue100 : theme.colors.blueGray100};
  cursor: pointer;
  color: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.white : theme.colors.blueGray500};
  font-size: 18px;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.36px;

  &:disabled {
    cursor: not-allowed;
  }
`;
