import Naver from "@assets/logo/Naver";
import styled from "styled-components";

const NaverLoginButton = () => {
  const NaverLogin = () => {
    const CLIENT_ID = process.env.REACT_APP_NAVER_CLIENT_ID;
    const REDIRECT_URI = `${process.env.REACT_APP_HOST}/auth`;
    const STATE = "oauth"; // 앱에서 만튼 토큰을 넣는 자리 / 임시로 string 부여
    const NAVER_AUTH_URL =
      `https://nid.naver.com/oauth2.0/authorize` +
      `?response_type=code` +
      `&client_id=${CLIENT_ID}` +
      `&redirect_uri=${REDIRECT_URI}` +
      `&state=${STATE}`;

    window.location.href = NAVER_AUTH_URL;
  };

  return (
    <Container>
      <Logo>
        <Naver />
      </Logo>
      <Button onClick={NaverLogin}>네이버 로그인</Button>
    </Container>
  );
};

export default NaverLoginButton;

const Container = styled.div`
  display: flex;
  height: 40px;
  width: 365px;
  border: none;
  border-radius: 4px;
  background-color: #03c75a;
  position: relative;
  cursor: pointer;
`;

const Logo = styled.div`
  position: absolute;
  padding: 13px 10px;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  justify-content: center;
  flex-grow: 2;
  padding: 0;
  color: white;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px; /* 100% */
  letter-spacing: -0.28px;
  border: none;
  background: none;
  cursor: pointer;
`;
