import styled from "styled-components";
import MainConsultant from "./MainConsultant";
import DetailConsultant from "./DetailConsultant";
import { useEffect, useState } from "react";
import { ConsultantInputProps } from "./Consulting.interfaces";
import { useSearchParams } from "react-router-dom";

const dataSet = [
  { title: "상담소 메인페이지", value: "main" },
  { title: "컨설팅 상세페이지", value: "detail" },
];
export default function ConsultingInput({
  handleSave,
  data,
  image,
  setImage,
}: ConsultantInputProps) {
  const [searchParams] = useSearchParams();
  const dbVersion = searchParams.get("db");
  const [selectedTab, setSelectedTab] = useState<string>("main");
  // redux에 file 형식으로 저장할 수 없어서 useState 사용

  useEffect(() => {
    if (data) {
      const spotlightImg = data.exposures.find(
        (item) => item.title === "spotlight"
      )?.imgUrl;
      const consultingImg = data.exposures.find(
        (item) => item.title === "expertAdvice"
      )?.imgUrl;
      const copyImg = { ...image };
      copyImg.profileImgUrl.data = data.profileImgUrl;
      copyImg.spotlightImgUrl.data = spotlightImg;
      copyImg.consultingImgUrl.data = consultingImg;
      copyImg.mainImgUrl.data = data.mainImgUrl;
      copyImg.introImgUrl.data = data.introImgUrl;
      copyImg.frequentVisitImgUrl.data = data.frequentVisitImgUrl;
      copyImg.detailImgUrl.data = data.detailImgUrl;

      setImage(copyImg);
    }
  }, [data, setImage]);

  return (
    <Container>
      <ControlContainer>
        <TabBox>
          {dataSet.map((el, idx) => (
            <TabMenu
              onClick={() => {
                setSelectedTab(el.value);
              }}
              $active={el.value === selectedTab}
              key={idx}
            >
              {el.title}
            </TabMenu>
          ))}
        </TabBox>
        {dbVersion === "live" ? null : (
          <SaveButton onClick={handleSave}>
            <p>정보 저장</p>
          </SaveButton>
        )}
      </ControlContainer>
      <MainConsultant
        isShow={selectedTab === "main"}
        image={image}
        setImage={setImage}
      />
      <DetailConsultant
        isShow={selectedTab === "detail"}
        image={image}
        setImage={setImage}
      />
    </Container>
  );
}

const Container = styled.div`
  max-width: 1500px;
`;

const ControlContainer = styled.div`
  position: relative;
  display: flex;
  margin-top: 48px;
  width: 95%;
  border-bottom: 2px solid ${({ theme }) => theme.colors.blueGray100};
`;

const SaveButton = styled.div`
  position: absolute;
  right: 0;
  bottom: 10px;
  padding: 9px 34px;
  width: fit-content;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.primaryBlue100};
  cursor: pointer;

  p {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
    color: white;
  }
`;

const TabBox = styled.div`
  display: flex;
  position: relative;
  top: 1.5px;
`;

const TabMenu = styled.h2<{ $active: boolean }>`
  margin-right: 40px;
  padding-bottom: 10px;
  color: ${({ theme, $active }) =>
    $active ? theme.colors.blueGray1000 : theme.colors.blueGray500};
  font-size: 18px;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;
  border-bottom: ${({ $active }) => ($active ? "2px" : 0)} solid
    ${({ theme }) => theme.colors.blueGray1000};
  cursor: pointer;
`;
