export default function AlarmIcon({ active }: { active: boolean | undefined }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_266_2732)">
        <g opacity="0.9">
          <path
            d="M16 28.375C17.4498 28.375 18.625 27.1998 18.625 25.75H13.375C13.375 27.1998 14.5502 28.375 16 28.375Z"
            fill={active ? "#99C8FF" : "#B2BEC9"}
          />
          <path
            d="M25.1124 22.6124L23.875 21.375V15.5198C23.875 11.7398 21.3009 8.40094 17.7295 7.57783C17.7374 7.51045 17.75 7.44448 17.75 7.375C17.75 6.40848 16.9665 5.625 16 5.625C15.0335 5.625 14.25 6.40848 14.25 7.375C14.25 7.44334 14.2625 7.50818 14.2701 7.57441C10.7545 8.36384 8.125 11.4961 8.125 15.25V21.375L6.73742 22.7626C6.50537 22.9947 6.375 23.3094 6.375 23.6376C6.375 24.321 6.92905 24.875 7.61242 24.875H24.5998C25.166 24.875 25.625 24.4161 25.625 23.8498C25.625 23.3857 25.4406 22.9405 25.1124 22.6124Z"
            fill={active ? "#99C8FF" : "#B2BEC9"}
          />
        </g>
      </g>
      <circle cx="24" cy="8" r="6" fill={active ? "#1c84ff" : "#98A8B6"} />
      <path
        d="M26.7188 5V10.6562H25.7031L23.2188 7.0625H23.1719V10.6562H22V5H23.0312L25.5 8.59375H25.5547V5H26.7188Z"
        fill="white"
      />
      <defs>
        <clipPath id="clip0_266_2732">
          <rect
            width="28"
            height="28"
            fill="white"
            transform="translate(2 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
