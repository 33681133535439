export default function ConsultantIcon({
  active,
}: {
  active: boolean | undefined;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clipPath="url(#clip0_10583_1526)">
        <path
          opacity="0.2"
          d="M32 0H0V32H32V0Z"
          fill="#FF7BA1"
          fillOpacity="0.01"
        />
        <path
          opacity="0.2"
          d="M26.6667 2.66675H5.33337V29.3334H26.6667V2.66675Z"
          fill="#FF7BA1"
          fillOpacity="0.01"
        />
        <path
          opacity="0.2"
          d="M29.3334 26.6666V5.33325L2.66671 5.33325V26.6666H29.3334Z"
          fill="#FF7BA1"
          fillOpacity="0.01"
        />
        <path
          opacity="0.2"
          d="M28 4H4V28H28V4Z"
          fill="#FF7BA1"
          fillOpacity="0.01"
        />
        <path
          opacity="0.2"
          d="M16 22C19.3137 22 22 19.3137 22 16C22 12.6863 19.3137 10 16 10C12.6863 10 10 12.6863 10 16C10 19.3137 12.6863 22 16 22Z"
          fill="#FF7BA1"
          fillOpacity="0.01"
        />
        <path
          d="M10.3334 20C14.5675 20 18 16.866 18 13C18 9.13401 14.5675 6 10.3334 6C6.09917 6 2.66669 9.13401 2.66669 13C2.66669 16.866 6.09917 20 10.3334 20Z"
          fill={active ? "#99C8FF" : "#98A8B6"}
        />
        <path
          d="M18.8404 25.2292C23.8991 25.2292 28 21.4338 28 16.7518C28 12.0699 23.8991 8.27441 18.8404 8.27441C13.7817 8.27441 9.68079 12.0699 9.68079 16.7518C9.68079 21.4338 13.7817 25.2292 18.8404 25.2292Z"
          fill={active ? "#1C84FF" : "#B2BEC9"}
        />
        <path
          d="M12.6916 22.9172L11.9283 25.9999L15.7448 24.4586L12.6916 22.9172Z"
          fill={active ? "#1C84FF" : "#B2BEC9"}
        />
        <path
          d="M23.1641 13.677V19.3333H21.8281V14.927H21.7969L20.5234 15.7083V14.552L21.9219 13.677H23.1641Z"
          fill="white"
        />
        <path
          d="M18.9922 18.6692C18.6055 18.6692 18.2813 18.3528 18.2891 17.9583C18.2813 17.5754 18.6055 17.2629 18.9922 17.2629C19.3672 17.2629 19.6992 17.5754 19.7031 17.9583C19.6992 18.3528 19.3672 18.6692 18.9922 18.6692ZM18.2891 15.0364C18.2813 14.6536 18.6055 14.3411 18.9922 14.3411C19.3672 14.3411 19.6992 14.6536 19.7031 15.0364C19.6992 15.4309 19.3672 15.7473 18.9922 15.7473C18.6055 15.7473 18.2813 15.4309 18.2891 15.0364Z"
          fill="white"
        />
        <path
          d="M17 13.677V19.3333H15.6641V14.927H15.6328L14.3594 15.7083V14.552L15.7578 13.677H17Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_10583_1526">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
