import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import ImjangApplicantTable from "@components/page/admin/imjang/imjangStatus/ImjangApplicantTable";
import ImjangStatusChangePopUp from "@components/page/admin/imjang/imjangStatus/ImjangStatusChangePopUp";
import { Title } from "@components/styles";
import { useMenu } from "@features/useMenu";
import {
  useGetImjangApplicantsList,
  usePatchImjangStatus,
} from "@hooks/imjang";
import { useEffect, useState } from "react";
import styled from "styled-components";
import AbsoluteFillLoading from "@components/common/AbsoluteFillLoading";

export default function ImjangApplicant() {
  const { setCurrentMenu } = useMenu();
  const { data, isLoading } = useGetImjangApplicantsList();
  const [selectedValue, setSelectedValue] = useState({
    applyId: "",
    state: "",
  });
  const [isVisible, setIsVisible] = useState(false);
  const { mutate } = usePatchImjangStatus();

  useEffect(() => {
    setCurrentMenu("imjangApply");
  }, []);

  const handleState = ({ applyId, state }) => {
    if (applyId) {
      setSelectedValue((prev) => ({ ...prev, applyId }));
    } else if (state) {
      setSelectedValue((prev) => ({ ...prev, state }));
      mutate(
        { id: selectedValue.applyId, state },
        {
          onSuccess: (res) => console.log(res),
          onError: (res) => console.log(res.response.data),
        }
      );
    }
  };

  return (
    <Container>
      <Title>임장 비서 신청 관리</Title>
      <div style={{ height: 40 }} />
      {isLoading ? (
        <AbsoluteFillLoading />
      ) : (
        <ImjangApplicantTable
          data={data?.applyList || []}
          setIsVisible={setIsVisible}
          handleState={handleState}
        />
      )}
      <PopUpContainer isVisible={isVisible} setIsVisible={setIsVisible}>
        <ImjangStatusChangePopUp
          setIsVisible={setIsVisible}
          handleState={handleState}
        />
      </PopUpContainer>
    </Container>
  );
}

const Container = styled.div``;
