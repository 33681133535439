import { Props, WhitespaceEnum } from "./WhiteSpace.interfaces";

export default function Whitespace({ status = WhitespaceEnum.Color }: Props) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <g clipPath="url(#clip0_8360_8610)">
        <path opacity="0.2" d="M40 0H0V40H40V0Z" fill="white" />
        <path opacity="0.2" d="M34 2H6V38H34V2Z" fill="white" />
        <path opacity="0.2" d="M38 34V6L2 6V34H38Z" fill="white" />
        <path opacity="0.2" d="M36 4H4V36H36V4Z" fill="white" />
        <path
          opacity="0.2"
          d="M20 28C24.4183 28 28 24.4183 28 20C28 15.5817 24.4183 12 20 12C15.5817 12 12 15.5817 12 20C12 24.4183 15.5817 28 20 28Z"
          fill="white"
        />
        <path
          d="M34 3.89V26L23 32V38H7.87C6.8401 38 6 37.15 6 36.11V3.89C6 2.85 6.8401 2 7.87 2H32.13C33.1599 2 34 2.85 34 3.89Z"
          fill={status === "Color" ? "#80BAFF" : "#D5DBE4"}
        />
        <path
          d="M10 13H30"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M10 8H24"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M10 18H20"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M34 26V26.23C34 26.72 33.8101 27.2 33.48 27.57L24.6 37.35C24.22 37.76 23.68 38 23.12 38H23V28C23 26.8954 23.8954 26 25 26H34Z"
          fill={status === "Color" ? "#1C84FF" : "#BFC6D1"}
        />
      </g>
      <defs>
        <clipPath id="clip0_8360_8610">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
