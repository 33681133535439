import AccountingIcon from "@assets/icon/AccountingIcon";
import AlarmIcon from "@assets/icon/AlarmIcon";
import ConsultantIcon from "@assets/icon/ConsultantIcon";
import ConsultationReportIcon from "@assets/icon/ConsultationReportIcon";
import ContentsIcon from "@assets/icon/ContentsIcon";
import IRIcon from "@assets/icon/IRIcon";
import NewsIcon from "@assets/icon/NewsIcon";
import NoticeIcon from "@assets/icon/NoticeIcon";
import ReportDownIcon from "@assets/icon/ReportDownIcon";
import SourceDownIcon from "@assets/icon/SourceDownIcon";
import SourceIcon from "@assets/icon/SourceIcon";
import UsersIcon from "@assets/icon/UsersIcon";

export const adminNavList = [
  {
    title: "기고문 관리",
    content: [
      {
        menu: "컨텐츠 업로드 관리",
        value: "contents",
        linkUrl: "/admin/config/contents",
        activeIcon: <ContentsIcon active />,
        inactiveIcon: <ContentsIcon active={false} />,
      },
      {
        menu: "회원 및 권한 관리",
        value: "users",
        linkUrl: "/admin/config/users",
        activeIcon: <UsersIcon active />,
        inactiveIcon: <UsersIcon active={false} />,
      },
      {
        menu: "공지사항 업로드",
        value: "notice",
        linkUrl: "/admin/config/notice/list",
        activeIcon: <NoticeIcon active />,
        inactiveIcon: <NoticeIcon active={false} />,
      },
      {
        menu: "정산 관리",
        subMenu: [
          {
            menu: "정산",
            value: "accounting",
            linkUrl: "/admin/config/accounting",
          },
          {
            menu: "정산내역",
            value: "accountingList",
            linkUrl: "/admin/config/accounting/list",
          },
        ],
        value: "settlement",
        linkUrl: "/admin/config/accounting",
        activeIcon: <AccountingIcon active />,
        inactiveIcon: <AccountingIcon active={false} />,
      },
    ],
  },
  {
    title: "상담소 관리",
    content: [
      {
        menu: "상담 신청 현황",
        value: "consultationStatus",
        linkUrl: "/admin/consultation/status",
        activeIcon: <ConsultantIcon active />,
        inactiveIcon: <ConsultantIcon active={false} />,
      },
      {
        menu: "컨설팅 관리",
        value: "consulting",
        linkUrl: "/admin/consulting",
        activeIcon: <UsersIcon active />,
        inactiveIcon: <UsersIcon active={false} />,
      },
      {
        menu: "보고서 관리",
        value: "consultationReport",
        linkUrl: "/admin/consultation/report",
        activeIcon: <ConsultationReportIcon active />,
        inactiveIcon: <ConsultationReportIcon active={false} />,
      },
      {
        menu: "참고 자료 관리",
        value: "source",
        linkUrl: "/admin/consultation/source",
        activeIcon: <SourceIcon active />,
        inactiveIcon: <SourceIcon active={false} />,
      },
    ],
  },
  {
    title: "임장 비서 관리",
    content: [
      {
        menu: "임장 비서 신청 관리",
        value: "imjangApply",
        linkUrl: "/admin/imjang/applicant",
        activeIcon: <ConsultantIcon active />,
        inactiveIcon: <ConsultantIcon active={false} />,
      },
      {
        menu: "임장 비서 중개사 관리",
        value: "imjangExpert",
        linkUrl: "/admin/imjang/expert",
        activeIcon: <ConsultantIcon active />,
        inactiveIcon: <ConsultantIcon active={false} />,
      },
    ],
  },
  {
    title: "운영 관리",
    content: [
      {
        menu: "푸시 발송 내역",
        value: "pushList",
        linkUrl: "/admin/push/list",
        activeIcon: <NoticeIcon active />,
        inactiveIcon: <NoticeIcon active={false} />,
      },
      {
        menu: "코호트 관리",
        value: "cohort",
        linkUrl: "/admin/cohort/list",
        activeIcon: <NoticeIcon active />,
        inactiveIcon: <NoticeIcon active={false} />,
      },
      {
        menu: "푸시 스케줄 관리",
        value: "push-schedule",
        linkUrl: "/admin/push-schedule?page=1",
        activeIcon: <AlarmIcon active />,
        inactiveIcon: <AlarmIcon active={false} />,
      },
      // {
      //   menu: "뉴스 업로드 관리",
      //   value: "news",
      //   linkUrl: "/admin/news",
      //   activeIcon: <NewsIcon active />,
      //   inactiveIcon: <NewsIcon active={false} />,
      // },
      {
        menu: "투자 요청 관리",
        value: "IR",
        linkUrl: "/admin/ir-list",
        activeIcon: <IRIcon active />,
        inactiveIcon: <IRIcon active={false} />,
      },
      // {
      //   menu: "매물 찾기",
      //   value: "maemul",
      //   linkUrl: "/admin/maemul",
      //   activeIcon: <IRIcon active />,
      //   inactiveIcon: <IRIcon active={false} />,
      // },
    ],
  },
];

export const influencerNavList = [
  {
    title: "기고문 관리",
    content: [
      {
        menu: "컨텐츠 업로드",
        value: "upload",
        linkUrl: `/influencer/contents/upload`,
        activeIcon: <ContentsIcon active />,
        inactiveIcon: <ContentsIcon active={false} />,
      },
      {
        menu: "내 컨텐츠",
        subMenu: [
          {
            menu: "컨텐츠 통계",
            value: "stat",
            linkUrl: `/influencer/my_contents/stat`,
          },
          {
            menu: "정산내역",
            value: "accounting",
            linkUrl: `/influencer/my_contents/accounting`,
          },
        ],
        value: "my_contents",
        linkUrl: `/influencer/my_contents/stat`,
        activeIcon: <UsersIcon active />,
        inactiveIcon: <UsersIcon active={false} />,
      },
      {
        menu: "공지사항",
        value: "notice",
        linkUrl: "/notice",
        activeIcon: <NoticeIcon active />,
        inactiveIcon: <NoticeIcon active={false} />,
      },
    ],
  },
  {
    title: "내 상담소",
    content: [
      {
        menu: "보고서 작성",
        value: "consulting",
        linkUrl: `/influencer/consultation/consulting`,
        activeIcon: <ContentsIcon active />,
        inactiveIcon: <ContentsIcon active={false} />,
      },
      {
        menu: "보고서 관리",
        value: "report",
        linkUrl: `/influencer/consultation/report`,
        activeIcon: <ReportDownIcon active />,
        inactiveIcon: <ReportDownIcon active={false} />,
      },
      {
        menu: "참고 자료",
        value: "source",
        linkUrl: `/influencer/consultation/source`,
        activeIcon: <SourceDownIcon active />,
        inactiveIcon: <SourceDownIcon active={false} />,
      },
    ],
  },
];

export const devNavList = [
  {
    title: "개발 운영 관리",
    content: [
      {
        menu: "Firestore 버전관리",
        value: "version",
        linkUrl: `/dev/version`,
        activeIcon: <ContentsIcon active />,
        inactiveIcon: <ContentsIcon active={false} />,
      },
      {
        menu: "URL 관리",
        value: "url",
        linkUrl: `/dev/url`,
        activeIcon: <ContentsIcon active />,
        inactiveIcon: <ContentsIcon active={false} />,
      },
      {
        menu: "white list 관리",
        value: "whiteList",
        linkUrl: `/dev/white-list`,
        activeIcon: <ContentsIcon active />,
        inactiveIcon: <ContentsIcon active={false} />,
      },
    ],
  },
];
