import { useEffect, useState } from "react";
import { devNavList } from "@constants/navConstants";
import { useGetMe } from "@hooks/login";
import { useNavigate } from "react-router-dom";
import BigNav from "./BigNav";

export default function DevNav() {
  const navigate = useNavigate();
  const { mutate, data } = useGetMe();
  const [userInfo, setUserInfo] = useState({
    nickname: "개발자",
    email: "",
  });

  useEffect(() => {
    mutate(undefined, {
      onSuccess: (res) => {
        if (!res.me.email.includes("boodong.kr")) {
          navigate("/login");
        }
      },
      // onError: (res) => console.log(res),
    });
  }, []);

  useEffect(() => {
    if (data) {
      setUserInfo((prev) => ({
        ...prev,
        email: data.me.email,
      }));
    }
  }, [data]);

  return <BigNav navList={devNavList} userInfo={userInfo} />;
}
