import { useMemo, useRef, useState } from "react";
import {
  InputStatusEnum,
  InputTypeEnum,
  ValueByInputType,
  borderColorObj,
  darkBorderColorObj,
  labelColorObj,
} from "./BTypeInput.interfaces";
import { FormatOptions, numToKorean } from "num-to-korean";
import { Props } from "./BTypeInputBoxTyping.interfaces";
import { convertLocaleStringToNumber, isNumeric } from "@utils/cash";
import styled from "styled-components";
import { useMenu } from "@features/useMenu";

export default function BTypeInputBoxTyping({
  inputRef,
  inputType = InputTypeEnum.Cash,
  label,
  placeholder,
  value = null,
  onChangeValue = (v: ValueByInputType<InputTypeEnum>) => {},
  unitLabel = "",
  message = "",
  handleFocus,
  handleBlur,
  disabled = false,
  style,
  inputStyle,
  maxLength,
}: Props) {
  const { globalThemeMode } = useMenu();
  const [status, setStatus] = useState<InputStatusEnum>(
    disabled ? InputStatusEnum.Disabled : InputStatusEnum.Default
  );
  const labelRef: React.RefObject<HTMLLabelElement> = useRef(null);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 0 && status !== InputStatusEnum.Active) {
      setStatus(InputStatusEnum.Active);
    }

    switch (inputType) {
      case InputTypeEnum.OnlyText:
        onChangeValue(e.target.value);
        break;

      case InputTypeEnum.Cash:
        // 숫자, 콤마 이외의 값이 들어 왔을 때 체크
        if (/[^\d,]/g.test(e.target.value)) {
          return;
        }

        if (value === "0") {
          onChangeValue(0);
          return;
        }

        if (value === "") {
          onChangeValue(null);
          return;
        }

        const num = convertLocaleStringToNumber(e.target.value);
        onChangeValue(num * 10000);

        break;

      case InputTypeEnum.Number: // 숫자, 콤마 이외의 값이 들어 왔을 때 체크
        const inputValue = e.target.value;
        if (/[^\d,]/g.test(e.target.value)) {
          return;
        }

        if (inputValue === "0") {
          onChangeValue("0");
          return;
        }

        if (inputValue === "") {
          onChangeValue(null);
          return;
        }

        const num2 = convertLocaleStringToNumber(inputValue);
        onChangeValue(num2);

        break;

      case InputTypeEnum.Decimal:
        if (e.target.value === "") {
          onChangeValue(null);
        }

        if (!isNumeric(Number(e.target.value))) {
          return;
        }

        if (/^0\d/g.test(e.target.value)) {
          onChangeValue(e.target.value.substring(1));
          return;
        }

        if (value === "0") {
          onChangeValue(0);
          return;
        }

        if (/^\d+\.$/g.test(e.target.value)) {
          onChangeValue(e.target.value);
          return;
        }

        onChangeValue(Number(e.target.value));
        break;
    }
  };

  const showValue = useMemo(() => {
    if (value) {
      switch (inputType) {
        case InputTypeEnum.Cash:
          return (Number(value) / 10000).toLocaleString();
        case InputTypeEnum.Number:
          return Number(value).toLocaleString();
        default:
          return value;
      }
    } else {
      return "";
    }
  }, [value]);

  const onFocus = (e) => {
    setStatus(value ? InputStatusEnum.Active : InputStatusEnum.Focus);
    handleFocus && handleFocus(e);
  };

  const onBlur = (e) => {
    e.preventDefault();
    setStatus(value ? InputStatusEnum.Completion : InputStatusEnum.Default);
    handleBlur && handleBlur(e);
  };

  return (
    <Container style={{ ...style }}>
      <label
        className="label"
        style={{ color: labelColorObj[status] }}
        ref={labelRef}
      >
        {label}
      </label>
      <InputContainer
        className={[
          status === InputStatusEnum.Disabled ? "disabledBG" : "defaultBG",
        ].join(" ")}
        style={{
          ...inputStyle,
          borderColor:
            globalThemeMode === "light"
              ? borderColorObj[status]
              : darkBorderColorObj[status],
          marginTop: label ? "8px" : 0,
        }}
      >
        <Input
          ref={inputRef}
          className={[
            status === InputStatusEnum.Disabled
              ? ["disabledBG", "disabledInputColor"].join(" ")
              : ["defaultBG", "defaultInputColor"].join(" "),
          ].join(" ")}
          placeholder={placeholder}
          value={showValue}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={status === InputStatusEnum.Disabled}
          style={{
            width: inputType === InputTypeEnum.OnlyText ? "100%" : "80%",
          }}
          maxLength={maxLength}
          autoComplete="none"
          autoCapitalize="off"
          autoCorrect="off"
          type={inputType === InputTypeEnum.Cash ? "text" : ""}
          pattern={inputType === InputTypeEnum.Cash ? "\\d*" : undefined}
          onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            if (event.key === "Enter") {
              target.blur();
            }
          }}
          inputMode="none"
        />
        <Unit
          className={[
            status === InputStatusEnum.Active ||
            status === InputStatusEnum.Focus ||
            status === InputStatusEnum.Completion ||
            (status === InputStatusEnum.Default && value)
              ? "visible"
              : "invisible",
          ].join(" ")}
        >
          {unitLabel}
        </Unit>
      </InputContainer>
      <Message>
        <span className={["message", "leftBottomMsg"].join(" ")}>
          {message}
        </span>
        {inputType === InputTypeEnum.Cash &&
          (status === InputStatusEnum.Active ||
            status === InputStatusEnum.Completion) && (
            <span className={["message", "leftBottomMsg"].join(" ")}>
              {Number(value) === 0
                ? "0만"
                : numToKorean(Number(value), FormatOptions.MIXED)}
              원
            </span>
          )}
      </Message>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  padding: 22px 0 28px;

  .label {
    position: absolute;
    top: 0;
    color: ${({ theme }) => theme.colors.blueGray400};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 144.444% */
    letter-spacing: -0.36px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  border-radius: 10px;

  &.disabledBG {
    background-color: ${({ theme }) => theme.colors.blueGray100};
  }

  &.defaultBG {
    background-color: ${({ theme }) => theme.colors.blueGrayA100};
  }
`;

const Input = styled.input`
  width: 80%;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primaryBlueGray};
  border: none;
  border-radius: 10px;
  outline: none;
  letter-spacing: -0.02em;

  &::placeholder {
    color: ${({ theme }) => theme.colors.blueGray500};
  }

  &.disabledBG {
    background-color: ${({ theme }) => theme.colors.blueGray100};
  }

  &.defaultBG {
    background-color: ${({ theme }) => theme.colors.blueGrayA100};
  }

  .defaultInputColor {
    color: ${({ theme }) => theme.colors.primaryBlueGray};
  }

  .disabledInputColor {
    color: ${({ theme }) => theme.colors.blueGray500};
  }
`;

const Unit = styled.span`
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -1px;
  color: var(--blue-gray-400);
  white-space: "nowrap";

  &.visible {
    visibility: visible;
  }

  &.invisible {
    visibility: hidden;
  }
`;

const Message = styled.div`
  &.message {
    font-size: 14px;
    color: var(--blue-gray-400);
    line-height: 22px;
    letter-spacing: -1px;
  }

  &.leftBottomMsg {
    position: absolute;
    left: 24px;
    bottom: 0px;
  }

  &.rightBottomMsg {
    position: absolute;
    right: 24px;
    bottom: 0px;
    text-align: right;
  }
`;
