import React, { useState, useRef, useEffect, useMemo } from "react";
import { InputStatusEnum, InputTypeEnum } from "./BTypeInput.interfaces";

import styled from "styled-components";
import { Props } from "./BTypeInputBoxBtn.interfaces";
import SelectDataList from "./SelectDataList";
import DownArrow from "@assets/arrow/DownArrow";

export default function B1TypeInputBoxBtn({
  label,
  value,
  onChangeValue,
  placeholder,
  listData,
  disabled,
  style,
  inputStyle,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const title = useMemo(() => {
    if (value || value === false) {
      const select = listData.find((el) => el.value === value);
      return select?.label;
    } else return "";
  }, [value]);

  const isFalsyValue = useMemo(() => {
    if (value === false) {
      return true;
    } else if (value) {
      return true;
    } else return false;
  }, [value]);

  return (
    <Container style={{ ...style }} $value={isFalsyValue} $disabled={disabled}>
      {label ? <label className="label">{label}</label> : null}
      <InputContainer
        $disabled={disabled}
        $value={isFalsyValue}
        style={{ ...inputStyle }}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {title ? (
          <p className="title">{title}</p>
        ) : (
          <p className="placeholder">{placeholder}</p>
        )}
        <DownArrow />
      </InputContainer>
      {isOpen && listData.length > 0 ? (
        <ListContainer>
          <SelectDataList
            data={listData}
            setIsOpen={setIsOpen}
            onChangeValue={onChangeValue}
          />
        </ListContainer>
      ) : null}
    </Container>
  );
}

const Container = styled.div<{
  $value: boolean;
  $disabled: boolean;
}>`
  position: relative;

  .label {
    left: 24px;
    top: 2px;
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
  }
`;

const InputContainer = styled.div<{
  $disabled: boolean;
  $value: any;
}>`
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
  padding: 14px 16px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background-color: ${({ theme, $disabled }) =>
    $disabled ? theme.colors.blueGray100 : theme.colors.blueGrayA100};
  border-radius: 10px;

  p {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
  }

  .title {
    color: ${({ theme, $value }) => theme.colors.blueGray800};
  }

  .placeholder {
    color: ${({ theme, $value }) => theme.colors.blueGray500};
  }
`;

const ListContainer = styled.div`
  position: absolute;
  width: 100%;
  z-index: 5;
`;
