export default function ReportDownIcon({
  active,
}: {
  active: boolean | undefined;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clipPath="url(#clip0_12016_3563)">
        <path
          opacity="0.2"
          d="M16 22C19.3137 22 22 19.3137 22 16C22 12.6863 19.3137 10 16 10C12.6863 10 10 12.6863 10 16C10 19.3137 12.6863 22 16 22Z"
          fill="white"
        />
        <path
          d="M23.7145 7H8.28596C7.57588 7 7.00024 7.59695 7.00024 8.33333V25.6667C7.00024 26.403 7.57588 27 8.28596 27H23.7145C24.4246 27 25.0002 26.403 25.0002 25.6667V8.33333C25.0002 7.59695 24.4246 7 23.7145 7Z"
          fill={active ? "#4D9EFF" : "#B2BEC9"}
        />
        <rect
          x="9.00024"
          y="5"
          width="2"
          height="5"
          rx="1"
          fill={active ? "#4D5664" : "#B2BEC9"}
        />
        <rect
          x="21.0002"
          y="5"
          width="2"
          height="5"
          rx="1"
          fill={active ? "#4D5664" : "#B2BEC9"}
        />
        <path
          d="M10.0002 18V22H22.0002V18"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0002 12V17.3333"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.0002 16L16.0002 18L18.0002 16"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_12016_3563">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
