export default function Plus16() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <rect width="16" height="16" fill="#111111" />
      <g>
        <rect
          width="1920"
          height="1279"
          transform="translate(-1136 -317)"
          fill="white"
        />
        <g id="Frame 400443">
          <g id="Frame 400442">
            <rect
              x="-11.5"
              y="-10.5"
              width="120"
              height="37"
              rx="9.5"
              fill="white"
            />
            <rect
              x="-11.5"
              y="-10.5"
              width="120"
              height="37"
              rx="9.5"
              stroke="#D9D9D9"
            />
            <g id="Component 21">
              <g id="Group 38403">
                <g id="Group 38402">
                  <g id="Group 38406">
                    <rect
                      id="Rectangle 21578"
                      x="7"
                      y="3"
                      width="2"
                      height="10"
                      fill="#808690"
                    />
                    <rect
                      id="Rectangle 21579"
                      x="13"
                      y="7"
                      width="2"
                      height="10"
                      transform="rotate(90 13 7)"
                      fill="#808690"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
