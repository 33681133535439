import styled from "styled-components";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Dispatch, SetStateAction, useMemo } from "react";
import {
  CustomDot,
  CustomTooltip,
  CustomXAxisTick,
  CustomYAxisTick,
} from "./CustomChart";
import { ContentsStateArr } from "@hooks/influencer/influencer.interfaces";
import DownArrow from "@assets/arrow/DownArrow";

interface StatChartProps {
  data: ContentsStateArr[];
  dateRange: {
    start: string;
    end: string;
  };
  setDateRange: Dispatch<SetStateAction<{ start: string; end: string }>>;
}

type ButtonState = "minus" | "plus";

export default function StatChart({
  data,
  dateRange,
  setDateRange,
}: StatChartProps) {
  const chartData = useMemo(() => {
    let cumulativeViews = 0;
    let cumulativeLikes = 0;
    const today = new Date();
    if (data?.length > 1) {
      const afterArray = data
        .map((item) => {
          cumulativeViews += item.views;
          cumulativeLikes += item.likes;

          return {
            views: cumulativeViews,
            likes: cumulativeLikes,
            date: item.date,
          };
        })
        .map((item) => {
          const itemDate = new Date(item.date);
          if (itemDate > today) {
            return {
              views: null,
              likes: null,
              date: item.date,
            };
          } else {
            return {
              views: item.views,
              likes: item.likes,
              date: item.date,
            };
          }
        });
      return afterArray;
    } else {
      return [{ views: 0, likes: 0, date: "" }];
    }
  }, [data]);

  const maxViews = useMemo(() => {
    let maxviews = 0;
    chartData.forEach((group) => {
      maxviews = Math.max(maxviews, group.views);
    });
    return maxviews;
  }, [chartData]);

  const maxLikes = useMemo(() => {
    let maxSumLikes = 0;
    chartData.forEach((group) => {
      maxSumLikes = Math.max(maxSumLikes, group.likes);
    });
    return maxSumLikes;
  }, [chartData]);

  const handleButton = (state: ButtonState) => {
    let start = Number(dateRange.start);
    let end = Number(dateRange.end);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    if (state === "minus") {
      start -= 1;
      end -= 1;
    } else if (state === "plus") {
      if (start < currentYear) {
        start += 1;
        end += 1;
      }
    }
    setDateRange({ start: String(start), end: String(end) });
  };

  return (
    <Container>
      <Calender>
        <button
          style={{ transform: "rotate(90deg)", marginRight: 20 }}
          onClick={() => handleButton("minus")}
        >
          <DownArrow />
        </button>
        <h3>{dateRange.start}</h3>
        <button
          style={{ transform: "rotate(270deg)", marginLeft: 20 }}
          onClick={() => handleButton("plus")}
        >
          <DownArrow />
        </button>
      </Calender>
      <ResponsiveContainer>
        <LineChart
          data={chartData}
          margin={{
            top: 20,
            right: 5,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="date"
            tick={<CustomXAxisTick />}
            padding={{ right: 12, left: 12 }}
            // interval={1}
          />
          <YAxis
            yAxisId="left"
            tick={<CustomYAxisTick />}
            tickLine={false}
            domain={["auto", maxViews * 1.3]}
            strokeOpacity={0}
          />
          <YAxis
            yAxisId="right"
            tick={<CustomYAxisTick side={"right"} />}
            tickLine={false}
            domain={["auto", maxLikes * 1.3]}
            strokeOpacity={0}
            orientation="right"
          />
          <Tooltip content={<CustomTooltip />} />
          <Line
            yAxisId="left"
            type="linear"
            dataKey="views"
            stroke="#FFA800"
            strokeWidth={2}
            activeDot={{ r: 8 }}
            // dot={false}
            dot={<CustomDot color={"#FFA800"} />}
          />
          <Line
            yAxisId="right"
            type="linear"
            dataKey="likes"
            stroke="#1C84FF"
            strokeWidth={2}
            activeDot={{ r: 8 }}
            // dot={false}
            dot={<CustomDot color={"#1C84FF"} />}
          />
        </LineChart>
      </ResponsiveContainer>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 20px;
  max-width: 1400px;
  width: 80%;
  height: 400px;
`;

const Calender = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.48px;
  }
`;
