import AnnualContentsStat from "@components/page/influencer/stat/AnnualContentsStat";
import RecentContentsStat from "@components/page/influencer/stat/RecentContentsStat";
import { useMenu } from "@features/useMenu";
import { useEffect } from "react";
import styled from "styled-components";

export default function Stat() {
  const { setCurrentMenu, setCurrentSubMenu } = useMenu();

  useEffect(() => {
    setCurrentSubMenu("stat");
    setCurrentMenu("my_contents");

    return () => {
      setCurrentSubMenu("");
    };
  }, []);

  return (
    <Container>
      <RecentContentsStat />
      <HorizontalDivideLine />
      <AnnualContentsStat />
    </Container>
  );
}

const Container = styled.div``;

const HorizontalDivideLine = styled.div`
  position: relative;
  left: -40px;
  width: 110%;
  height: 12px;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors.blueGrayA100};
`;
