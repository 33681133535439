import IRTable from "@components/page/admin/manage/ir/IRTable";
import { Title } from "@components/styles";
import { useMenu } from "@features/useMenu";
import { useGetIRApplicant } from "@hooks/management";
import { useEffect } from "react";
import styled from "styled-components";

export default function IRList() {
  const { setCurrentMenu } = useMenu();
  useEffect(() => {
    setCurrentMenu("IR");
  }, []);

  const { data } = useGetIRApplicant();

  return (
    <Container>
      <Title>투자 요청 관리</Title>
      <div style={{ height: 40 }} />
      <IRTable data={data?.applicants || []} />
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
`;
