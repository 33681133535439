import styled from "styled-components";

export default function Logo() {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none">
      <path
        d="M44.6406 31.0312V50.615L53.1206 55.5108V35.9271L44.6406 31.0312Z"
        fill="#0B31B0"
      />
      <path
        d="M19.1992 13.6953V65.3031L27.6792 70.1991V18.5913L19.1992 13.6953Z"
        fill="#0B31B0"
      />
      <path
        d="M44.6401 50.6172L36.1602 55.513L44.6401 60.409L53.1201 55.513L44.6401 50.6172Z"
        fill="#64C4FA"
      />
      <path
        d="M36.1597 35.9275V13.6953L27.6797 18.5913V70.1991L36.1597 65.3031V45.7194L44.6396 40.8235V31.0316L36.1597 35.9275Z"
        fill="#0F76EE"
      />
      <path
        d="M27.6792 8.80078L19.1992 13.6966L27.6792 18.5927L36.1593 13.6966L27.6792 8.80078Z"
        fill="#64C4FA"
      />
      <path
        d="M44.6401 60.4077L36.1602 55.5117V65.3036L44.6401 70.1996V60.4077Z"
        fill="#0B31B0"
      />
      <path
        d="M61.6007 31.0312L53.1206 35.9271V55.5108L44.6406 60.4068V70.1987L61.6007 60.4068L61.6007 31.0312Z"
        fill="#0F76EE"
      />
      <path
        d="M36.1602 26.1381V35.9299L44.6401 31.0341L53.1201 35.9299L61.6001 31.0341L44.6401 21.2422L36.1602 26.1381Z"
        fill="#64C4FA"
      />
      <path
        d="M44.6401 31.0312L36.1602 35.9271V45.719L44.6401 40.8231V31.0312Z"
        fill="#0F76EE"
      />
    </svg>
  );
}
