import B1TypeInputBoxBtn from "@components/core/BTypeInput/B1TypeInputBoxBtn";
import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import BTypeInputBoxTyping from "@components/core/BTypeInput/BTypeInputBoxTyping";
import BTypeTextArea from "@components/core/BTypeInput/BTypeTextArea";
import { useMemo, useRef } from "react";
import styled from "styled-components";

export default function UserInput({ userInfo, setUserInfo, onSubmit }) {
  const inputRef_1 = useRef<HTMLInputElement>(null);
  const inputRef_2 = useRef<HTMLInputElement>(null);

  const autoHyphen2 = (v) => {
    const result = v
      .slice(0, 13)
      .replace(/[^0-9]/g, "")
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/(\-{1,2})$/g, "");

    return result;
  };

  const checkEmail = () => {
    const regExp =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

    if (userInfo.email.match(regExp) != null) {
    } else {
      alert("이메일 주소가 올바르지 않습니다");
    }
  };

  const isDone = useMemo(() => {
    return Boolean(
      userInfo.nickname &&
        userInfo.phone_number &&
        userInfo.name &&
        userInfo.email
    );
  }, [userInfo]);

  return (
    <Container>
      <div className="row">
        <BTypeInputBoxTyping
          inputRef={inputRef_1}
          inputType={InputTypeEnum.OnlyText}
          label={"활동명"}
          placeholder={"활동명"}
          value={userInfo.nickname}
          onChangeValue={(v: InputTypeEnum.OnlyText) =>
            setUserInfo((prev) => ({ ...prev, nickname: v }))
          }
          style={{ flex: 1 }}
        />
        <div style={{ width: 20 }} />
        <BTypeInputBoxTyping
          inputRef={inputRef_2}
          inputType={InputTypeEnum.OnlyText}
          label={"전화번호"}
          placeholder={"전화번호"}
          value={autoHyphen2(userInfo.phone_number)}
          onChangeValue={(v: InputTypeEnum.OnlyText) =>
            setUserInfo((prev) => ({ ...prev, phone_number: v }))
          }
          style={{ flex: 1 }}
        />
      </div>
      <div className="row">
        <BTypeInputBoxTyping
          inputRef={inputRef_1}
          inputType={InputTypeEnum.OnlyText}
          label={"실명"}
          placeholder={"실명"}
          value={userInfo.name}
          onChangeValue={(v: InputTypeEnum.OnlyText) =>
            setUserInfo((prev) => ({ ...prev, name: v }))
          }
          style={{ flex: 1 }}
        />
        <div style={{ width: 20 }} />
        <BTypeInputBoxTyping
          inputRef={inputRef_2}
          inputType={InputTypeEnum.OnlyText}
          label={"이메일주소"}
          placeholder={"이메일주소"}
          value={userInfo.email}
          handleBlur={checkEmail}
          onChangeValue={(v: InputTypeEnum.OnlyText) =>
            setUserInfo((prev) => ({ ...prev, email: v }))
          }
          style={{ flex: 1 }}
        />
      </div>
      <div className="row">
        <B1TypeInputBoxBtn
          inputType={InputTypeEnum.OnlyText}
          label={"권한"}
          value={userInfo.is_deleted}
          placeholder="권한 선택"
          onChangeValue={({ label, value }) => {
            setUserInfo((prev) => ({ ...prev, is_deleted: value }));
          }}
          style={{ flex: 0.47 }}
          listData={[
            { label: "활성화", value: false },
            { label: "비활성화", value: true },
          ]}
        />
      </div>
      <div style={{ height: 30 }} />
      <BTypeTextArea
        label="기타메모"
        placeholder="기타메모를 작성하세요."
        textareaStyle={{ height: 300 }}
        value={userInfo.memo}
        onChangeValue={(v: InputTypeEnum.OnlyText) =>
          setUserInfo((prev) => ({ ...prev, memo: v }))
        }
      />
      <div style={{ height: 30 }} />
      <div style={{ padding: "20px 0" }}>
        <Button $isDone={isDone} onClick={onSubmit} disabled={!isDone}>
          완료
        </Button>
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  min-width: 1000px;
  max-width: 1000px;

  .row {
    display: flex;
    max-width: 1000px;
    gap: 20px;
  }
`;

const Button = styled.button<{ $isDone: boolean }>`
  padding: 10px 35px;
  width: 100%;
  border-radius: 8px;
  border: none;
  background: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.primaryBlue100 : theme.colors.blueGray100};
  cursor: pointer;

  color: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.white : theme.colors.blueGray500};
  font-size: 18px;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.36px;

  &:disabled {
    cursor: not-allowed;
  }
`;
