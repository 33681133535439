import B1TypeInputBoxTyping from "@components/core/BTypeInput/B1TypeInputBoxTyping";
import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import { useNaverLogin } from "@hooks/login";
import { AuthInstance } from "../../api";
import { useEffect, useRef, useState } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useMenu } from "@features/useMenu";

export default function PhoneNumber() {
  const ref = useRef();
  const navigate = useNavigate();
  const { globalThemeMode, setGlobalThemeMode } = useMenu();

  const { state }: { state: any } = useLocation();
  const { naverToken } = state;

  const { mutate } = useNaverLogin();
  const [loginInfo, setLoginInfo] = useState({
    phone_number: "",
    nickname: "",
  });
  const cookies = new Cookies();

  const saveToken = (token: string) => {
    cookies.set("access_token", token, { path: "/" });
    // setCookie("access_token", token);
  };

  useEffect(() => {
    setGlobalThemeMode("light");
  }, []);

  const onSubmit = () => {
    console.log({ naverToken });
    mutate(
      { token: naverToken, ...loginInfo },
      {
        onSuccess: (res) => {
          saveToken(res.access_token);
          AuthInstance.defaults.headers.common.Authorization = `Bearer ${res.access_token}`;

          navigate("/influencer/contents/upload");

          console.log("access_token", res.access_token);
        },
        onError: (err: any) => {},
      }
    );
  };

  const autoHyphen2 = (v) => {
    const result = v
      .slice(0, 13)
      .replace(/[^0-9]/g, "")
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/(\-{1,2})$/g, "");

    return result;
  };

  return (
    <Container>
      <Title>
        {"계약하실 때 알려주신 \n전화번호 및 활동명을 입력해주세요"}
      </Title>
      <B1TypeInputBoxTyping
        inputRef={ref}
        inputType={InputTypeEnum.OnlyText}
        label={""}
        placeholder={"활동명"}
        value={loginInfo.nickname}
        handleKeyDown={onSubmit}
        onChangeValue={(v: InputTypeEnum.OnlyText) =>
          setLoginInfo((prev) => ({ ...prev, nickname: v }))
        }
        style={{ maxWidth: "50vw", minWidth: "670px" }}
      />
      <B1TypeInputBoxTyping
        inputRef={ref}
        inputType={InputTypeEnum.OnlyText}
        label={""}
        placeholder={"예시 : 010-1234-1234"}
        value={loginInfo.phone_number}
        handleKeyDown={onSubmit}
        onChangeValue={(v: InputTypeEnum.OnlyText) =>
          setLoginInfo((prev) => ({ ...prev, phone_number: autoHyphen2(v) }))
        }
        style={{ maxWidth: "50vw", minWidth: "670px", paddingTop: 0 }}
      />
      <Button $isDone={Boolean(loginInfo.phone_number)} onClick={onSubmit}>
        제출하기
      </Button>
    </Container>
  );
}

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.64px;
  white-space: pre-wrap;
`;

const Button = styled.button<{ $isDone: boolean }>`
  margin: 16px auto 0;
  padding: 14px 55px;
  width: fit-content;
  border-radius: 8px;
  border: none;
  background: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.primaryBlue100 : theme.colors.blueGray100};
  cursor: pointer;

  color: ${({ theme, $isDone }) =>
    $isDone ? "white" : theme.colors.blueGray500};
  font-size: 20px;
  font-weight: 500;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;
`;
