import { useMemo } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import ImjangHouseTable from "@components/page/admin/imjang/imjangExpert/ImjangHouseTable";
import ImjangTimeTable from "@components/page/admin/imjang/imjangStatus/ImjangTimeTable";
import ImjangApplyReason from "@components/page/admin/imjang/imjangExpert/ImjangApplyReason";
import MaemulImjangTable from "@components/page/admin/imjang/imjangExpert/MaemulImjangTable";
import { Title } from "@components/styles";
import { useGetImjangApplicantDetail } from "@hooks/imjang";

export default function ApplicantDetail() {
  const { id } = useParams();
  const { data } = useGetImjangApplicantDetail({ id });

  return (
    <Container>
      <Title>{data?.contactInfo.name || ""}님 신청 상세정보</Title>
      <div style={{ height: 40 }} />
      <ImjangTimeTable data={data?.dateInfo || []} />
      <div style={{ height: 60 }} />

      <ImjangHouseTable data={data?.danjiInfo || []} />

      <div style={{ height: 60 }} />
      {data?.danjiInfo.every((item) => item.maemulInfo !== null) ? null : (
        <>
          <MaemulImjangTable
            data={data?.danjiInfo.filter((item) => item.maemulInfo) || []}
          />
          <div style={{ height: 60 }} />
        </>
      )}
      <ImjangApplyReason data={data?.reason} />
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
`;
