import {
  createColumnHelper,
  flexRender,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { getCoreRowModel } from "@tanstack/react-table";
import "../../table.css";
import { convertToKoreanDate } from "@hooks/calc/converToKoreanDate";
import Pagination from "@components/core/paging/Pagination";
import Empty from "@components/common/Empty";

const contentStatus = (status) => {
  switch (status) {
    case "submitted":
      return "제출완료";
    case "in review":
      return "심사중";
    case "deployed":
      return "배포완료";
    case "in payment":
      return "정산중";
    case "paid":
      return "정산완료";
  }
};

export default function ViewAccountingTable({ data: tableData }) {
  const [data, setData] = useState([]);
  const [columnOrder, setColumnOrder] = useState([]);
  const columnHelper = createColumnHelper();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("number", {
        cell: (info) => info.row.index + 1,
        header: "순번",
        maxSize: 5,
      }),
      columnHelper.accessor("title", {
        cell: ({ row }) => {
          return (
            <Title title={row.original["title"]}>{row.original["title"]}</Title>
          );
        },
        header: "컨텐츠 제목",
        size: 55,
      }),
      columnHelper.accessor("content_status", {
        cell: ({ row }) => {
          const status = contentStatus(row.original["content_status"]);
          return <ColorText status={status}>{status}</ColorText>;
        },
        header: "진행상태",
        maxSize: 10,
      }),
      columnHelper.accessor("released_at", {
        cell: ({ row }) => {
          const releasedDate = row.original["released_at"];
          return convertToKoreanDate(releasedDate);
        },
        header: "업로드 날짜",
        maxSize: 10,
      }),
      columnHelper.accessor("settlementDate", {
        cell: ({ row }) => {
          const payment = row.original["payments"];
          return convertToKoreanDate(payment?.paid_at);
        },
        header: "정산기준 날짜",
        maxSize: 10,
      }),
      columnHelper.accessor("settlementAmount", {
        cell: ({ row }) => {
          const payment = row.original["payments"];
          return payment ? payment.price?.toLocaleString() + "원" : "-";
        },
        header: "정산금액",
        maxSize: 10,
      }),
    ];
  }, []);
  useEffect(() => {
    if (tableData && tableData.length > 0) {
      setData(tableData);
    }
  }, [tableData]);

  const table = useReactTable({
    data,
    columns,
    state: {
      columnOrder,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false, //페이지를 변경하는 상태 변화가 발생할 때 pageIndex는 0으로 리셋되는 옵션
  });

  useEffect(() => {
    table.setPageSize(10);
  }, [table]);

  return (
    <Container>
      <table className="contentsTable">
        <thead className="contentsThead">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="contentsHeader"
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="contentsData">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Empty data={data} title="내역이 없습니다" />
      <Pagination table={table} />
    </Container>
  );
}

const Container = styled.div``;

const ColorText = styled.p<{ status: string }>`
  font-weight: 500;
  color: ${({ status, theme }) => {
    switch (status) {
      case "정산중":
        return theme.colors.red1000;
      case "정산완료":
        return theme.colors.primaryBlue100;
      default:
        return "#000";
    }
  }};
`;

const Title = styled.h2`
  margin-left: 20px;
  /* max-width: 800px;
  min-width: 300px;
  width: 50vw; */
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
