import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./pages/main/Main";
import Login from "./pages/login/Login";
import LoginReq from "@pages/login/LoginReq";
import DevPrivateRouter from "@components/layout/DevPrivateRouter";
import PrivateRouter from "@components/layout/PrivateRouter";
import AdminLayout from "@components/layout/AdminLayout";
import InfluencerLayout from "@components/layout/InfluencerLayout";
import DevLayout from "@components/layout/DevLayout";
import { NaverCallback } from "@pages/login/NaverCallback";
import PhoneNumber from "@pages/login/PhoneNumber";
import {
  AccountingConfig,
  AccountingDetail,
  AccountingList,
  NoticeEdit,
  NoticeList,
  NoticeWrite,
  UserAdd,
  UserEdit,
  UsersList,
} from "./pages/admin/report";
import {
  AddContent,
  Contents,
  EditContents,
} from "@pages/admin/report/contents";
import {
  AddSource,
  ApplicantList,
  CheckSource,
  ConsultationApplicant,
  ConsultationApproval,
  ConsultationReportList,
  ConsultationSourceList,
  ConsultingAdd,
  ConsultingEdit,
  ConsultingList,
  EditSource,
  PostPreQuestion,
} from "@pages/admin/consultation";
import { Accounting, Notice, Stat, Upload } from "@pages/influencer";
import {
  CompletedReportList,
  ReuploadConsultingReport,
  SourceList,
  UploadConsultingReport,
  UploadReportList,
  ViewComment,
  ViewSource,
} from "@pages/influencer/consultation";
import {
  ApplicantDetail,
  ExpertAdd,
  ExpertEdit,
  ImjangApplicant,
  ImjangExpert,
} from "@pages/admin/imjang";
import {
  PushList,
  PushChart,
  AddPushSchedule,
  EditPushSchedule,
  PushScheduleList,
  CohortList,
} from "@pages/admin/manage";

import DevMain from "@pages/dev/DevMain";
import AppVersion from "@pages/dev/AppVersion";

import BreakTime from "@pages/dev/BreakTime";
import WhiteList from "@pages/dev/WhiteList";
import Url from "@pages/dev/Url";
import NewsList from "@pages/admin/manage/news/NewsList";
import NewsAdd from "@pages/admin/manage/news/NewsAdd";
import IRList from "@pages/admin/manage/IR/IRList";
import IRDetail from "@pages/admin/manage/IR/IRDetail";
import NewsEdit from "@pages/admin/manage/news/NewsEdit";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PrivateRouter authentication={false} />}>
          <Route path="/login" element={<Login />} />
          <Route path="/login/number" element={<PhoneNumber />} />
          <Route path="/login/req" element={<LoginReq />} />
          <Route path="/auth" element={<NaverCallback />} />
        </Route>
        <Route element={<PrivateRouter authentication={true} />}>
          <Route path="/" element={<Main />} />
          <Route element={<AdminLayout />}>
            <Route path="/admin/config/contents" element={<Contents />} />
            <Route path="/admin/config/content/add" element={<AddContent />} />
            <Route
              path="/admin/config/contents/:id"
              element={<EditContents />}
            />
            <Route path="/admin/config/users" element={<UsersList />} />
            <Route path="/admin/config/users/edit/:id" element={<UserEdit />} />
            <Route path="/admin/config/users/add" element={<UserAdd />} />
            <Route path="/admin/config/notice/list" element={<NoticeList />} />
            <Route path="/admin/config/notice" element={<NoticeWrite />} />
            <Route path="/admin/config/notice/:id" element={<NoticeEdit />} />
            <Route
              path="/admin/config/accounting"
              element={<AccountingConfig />}
            />
            <Route
              path="/admin/config/accounting/list"
              element={<AccountingList />}
            />
            <Route
              path="/admin/config/accounting/list/:id"
              element={<AccountingDetail />}
            />
            <Route
              path="/admin/consultation/status"
              element={<ApplicantList />}
            />
            <Route
              path="/admin/consultation/status/:id"
              element={<PostPreQuestion />}
            />
            <Route
              path="/admin/consultation/applicant/:id"
              element={<ConsultationApplicant />}
            />
            <Route path="/admin/consulting" element={<ConsultingList />} />
            <Route path="/admin/consulting/add" element={<ConsultingAdd />} />
            <Route
              path="/admin/consulting/edit/:id"
              element={<ConsultingEdit />}
            />
            <Route
              path="/admin/consultation/report"
              element={<ConsultationReportList />}
            />
            <Route
              path="/admin/consultation/report/:id"
              element={<ConsultationApproval />}
            />
            <Route
              path="/admin/consultation/source"
              element={<ConsultationSourceList />}
            />
            <Route
              path="/admin/consultation/source/add"
              element={<AddSource />}
            />
            <Route
              path="/admin/consultation/source/edit/:id"
              element={<EditSource />}
            />
            <Route
              path="/admin/consultation/source/:id"
              element={<CheckSource />}
            />
            <Route
              path="/admin/imjang/applicant"
              element={<ImjangApplicant />}
            />
            <Route
              path="/admin/imjang/applicant/:id"
              element={<ApplicantDetail />}
            />
            <Route path="/admin/imjang/expert" element={<ImjangExpert />} />
            <Route path="/admin/imjang/expert/:id" element={<ExpertEdit />} />
            <Route path="/admin/imjang/expert/add" element={<ExpertAdd />} />
            <Route path="/admin/push/list" element={<PushList />} />
            <Route path="/admin/push/chart" element={<PushChart />} />
            <Route path="/admin/cohort/list" element={<CohortList />} />
            <Route path="/admin/push-schedule" element={<PushScheduleList />} />
            <Route path="/admin/ir-list" element={<IRList />} />
            <Route path="/admin/ir/:id" element={<IRDetail />} />
            <Route
              path="/admin/push-schedule/add"
              element={<AddPushSchedule />}
            />
            <Route
              path="/admin/push-schedule/:id"
              element={<EditPushSchedule />}
            />
            <Route path="/admin/news" element={<NewsList />} />
            <Route path="/admin/news/add" element={<NewsAdd />} />
            <Route path="/admin/news/:id" element={<NewsEdit />} />
          </Route>
          <Route element={<InfluencerLayout />}>
            <Route path="/influencer/contents/upload" element={<Upload />} />
            <Route path="/influencer/my_contents/stat" element={<Stat />} />
            <Route
              path="/influencer/my_contents/accounting"
              element={<Accounting />}
            />
            <Route path="/notice" element={<Notice />} />
            <Route
              path="/influencer/consultation/consulting"
              element={<UploadReportList />}
            />
            <Route
              path="/influencer/consultation/consulting/upload"
              element={<UploadConsultingReport />}
            />
            <Route
              path="/influencer/consultation/consulting/upload/:id"
              element={<ReuploadConsultingReport />}
            />
            <Route
              path="/influencer/consultation/report"
              element={<CompletedReportList />}
            />
            <Route
              path="/influencer/consultation/report/:id"
              element={<ViewComment />}
            />
            <Route
              path="/influencer/consultation/source"
              element={<SourceList />}
            />
            <Route
              path="/influencer/consultation/source/:id"
              element={<ViewSource />}
            />
          </Route>
        </Route>
        <Route element={<DevPrivateRouter authentication={false} />}>
          <Route element={<DevLayout />}>
            <Route path="/dev" element={<DevMain />} />
          </Route>
        </Route>
        <Route element={<DevPrivateRouter authentication />}>
          <Route element={<DevLayout />}>
            <Route path="/dev/version" element={<AppVersion />} />
            <Route path="/dev/version/:id" element={<BreakTime />} />
            <Route path="/dev/url" element={<Url />} />
            <Route path="/dev/white-list" element={<WhiteList />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
