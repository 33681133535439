import { Title } from "@components/styles";
import { useEffect } from "react";
import styled from "styled-components";
import InputContainer from "../../../components/page/influencer/upload/InputContainer";
import coachImg from "@assets/image/coachImg.png";
import { useMenu } from "@features/useMenu";

export default function Upload() {
  const { setCurrentMenu } = useMenu();

  useEffect(() => {
    setCurrentMenu("upload");
  }, []);

  return (
    <>
      <Title>컨텐츠 업로드</Title>
      <ContentsWrapper>
        <InputContainer />
        <CoachImage src={coachImg} />
      </ContentsWrapper>
    </>
  );
}

const CoachImage = styled.img`
  position: relative;
  top: -15px;
  width: 42%;
  max-width: 591px;
  max-height: 830px;
`;

const ContentsWrapper = styled.div`
  display: flex;
  margin-right: 20px;
`;
