import {
  createColumnHelper,
  flexRender,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  ChangeEvent,
  HTMLProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import { getCoreRowModel } from "@tanstack/react-table";
import "../../../table.css";
import {
  Accounting,
  CheckButton,
  SubmitDataState,
} from "./AccountingInterfaces";
import { convertToKoreanDate } from "@hooks/calc/converToKoreanDate";
import DayPickCalender from "@components/core/calendar/DayPickCalender";
import Empty from "@components/common/Empty";
import { isNullOrUndefined } from "@utils/index";

declare module "@tanstack/react-table" {
  interface TableMeta<TData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void;
  }
}

interface ConfigAccountingTableProps {
  tableData: any;
  submitData: SubmitDataState[];
  setSubmitData: React.Dispatch<React.SetStateAction<SubmitDataState[]>>;
}

export default function ConfigAccountingTable({
  tableData,
  submitData,
  setSubmitData,
}: ConfigAccountingTableProps) {
  const columnHelper = createColumnHelper();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("checkBox", {
        cell: ({ row }) => {
          return (
            <IndeterminateCheckbox
              {...{
                disabled: !row.getCanSelect(),
                rowData: row.original as Accounting,
                setSubmitData,
                submitData,
                rowtype: "cell",
              }}
            />
          );
        },
        header: ({ table, column }) => {
          return (
            <IndeterminateHeaderCheckbox
              {...{
                setSubmitData,
                submitData,
                wholeData: table.getRowModel() as { rows },
                rowtype: "header",
                checked: table.getIsAllRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          );
        },
        maxSize: 10,
      }),
      columnHelper.accessor("index", {
        cell: (info) => info.row.index + 1,
        header: "순번",
        maxSize: 10,
      }),
      columnHelper.accessor("author", {
        cell: ({ row }) => {
          return (
            <p title={row.original["title"]}>
              {row.original["influencer"]?.nickname}
            </p>
          );
        },
        header: "작성자",
        maxSize: 15,
      }),
      columnHelper.accessor("released_at", {
        cell: ({ row }) => {
          return convertToKoreanDate(row.original["released_at"]);
        },
        header: "발행날짜",
        maxSize: 15,
      }),
      columnHelper.accessor("settlementDate", {
        cell: ({
          getValue,
          row: { index, original },
          column: { id },
          table,
        }) => {
          const initialValue = getValue();
          const onBlur = (value) => {
            table.options.meta?.updateData(index, id, value);
          };
          return (
            <DateInputBox
              data={initialValue}
              onBlur={onBlur}
              original={original}
              setSubmitData={setSubmitData}
              submitData={submitData}
            />
          );
        },
        header: "정산날짜",
        maxSize: 20,
      }),
      columnHelper.accessor("settlementAmount", {
        cell: ({
          getValue,
          row: { index, original },
          column: { id },
          table,
        }) => {
          const initialValue = getValue(); // 해당 column의 모든 값을 가져옴
          const onBlur = (value) => {
            table.options.meta?.updateData(index, id, value);
          };
          return (
            <PriceInputBox
              data={initialValue}
              onBlur={onBlur}
              original={original}
              setSubmitData={setSubmitData}
              submitData={submitData}
            />
          );
        },
        header: "정산금액",
        maxSize: 30,
      }),
    ];
  }, [submitData]);

  // 테이블에 표시할 데이터를 state로 정의
  const [data, setData] = useState([]);
  const [columnOrder, setColumnOrder] = useState([]);

  useEffect(() => {
    const transformedData = tableData?.map((el) => {
      return { ...el, accountAmount: 0, settlementDate: 0 };
    });
    setData(transformedData);
  }, [tableData]);

  // useReactTable로 테이블 구조 정의
  const table = useReactTable({
    data,
    columns,
    state: {
      // columnVisibility,
      columnOrder,
    },
    meta: {
      updateData: (rowIndex, columnId, value) => {
        // Skip page index reset until after next rerender
        setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex]!,
                [columnId]: value,
              };
            }
            return row;
          })
        );
      },
    },
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Container>
      <table className="smallContentsTable">
        <thead className="contentsThead">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="contentsHeader"
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="contentsData"
                  style={{ padding: "18px 0" }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Empty data={data} title="정산할 내역이 없어요" />
    </Container>
  );
}

// 체크박스
function IndeterminateCheckbox({
  className = "",
  rowData,
  submitData,
  setSubmitData,
  onChange,
  wholeData,
  ...rest
}: CheckButton & HTMLProps<HTMLInputElement>) {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (submitData) {
      const hasId = submitData.some((item) => item.content === rowData._id);
      if (hasId) {
        setIsChecked(true);
      } else {
        setIsChecked(false);
      }
    }
  }, [submitData, rowData]);

  const handleRowCheck = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        // 켜기전 validation
        if (
          !rowData.settlementDate ||
          isNullOrUndefined(rowData.settlementAmount)
        ) {
          alert("날짜와 금액을 모두 입력해야 합니다.");
        } else {
          const processedRowData = {
            content: rowData._id,
            paid_at: rowData.settlementDate,
            price: rowData.settlementAmount,
            nickname: rowData.influencer.nickname,
          };
          setSubmitData((prev) => [...prev, processedRowData]);
        }
      } else {
        // 끄면서 validation
        setSubmitData((prev) =>
          prev.filter((obj) => obj.content !== rowData._id)
        );
      }
    },
    [isChecked, rowData, submitData]
  );

  return (
    <input
      type="checkbox"
      className={className + " cursor-pointer"}
      {...rest}
      onChange={handleRowCheck}
      checked={isChecked}
    />
  );
}

function IndeterminateHeaderCheckbox({
  className = "",
  setSubmitData,
  checked,
  submitData,
  onChange,
  wholeData,
}: CheckButton & HTMLProps<HTMLInputElement>) {
  const [wholeCheck, setWholeCheck] = useState(false);
  const handleRowCheck = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const transformedData = [];
      wholeData.rows.forEach((rowData) => {
        const { original } = rowData;
        if (
          !original.settlementDate ||
          isNullOrUndefined(original.settlementAmount)
        ) {
          return;
        } else {
          const transformedItem = {
            content: original._id,
            paid_at: original.settlementDate,
            price: original.settlementAmount,
            nickname: original.influencer.nickname,
          };
          transformedData.push(transformedItem);
        }
        onChange(e);
      });
      if (!transformedData.length) {
        alert("날짜 혹은 정산금액이 수정된 항목이 없습니다.");
      }
      setSubmitData(transformedData);
    } else {
      onChange(e);
      setSubmitData([]);
    }
  };

  useEffect(() => {
    if (!submitData.length) {
      setWholeCheck(false);
    } else {
      setWholeCheck(true);
    }
  }, [submitData]);

  return (
    <input
      type="checkbox"
      className={className + " cursor-pointer"}
      onChange={handleRowCheck}
      checked={checked && wholeCheck}
    />
  );
}

const DateInputBox = ({
  data,
  onBlur,
  original,
  setSubmitData,
  submitData,
}) => {
  const [date, setDate] = useState(data || "");

  useEffect(() => {
    onBlur(date);
  }, [date]);

  return (
    <Center>
      <InputContainer>
        <DayPickCalender setStartDate={setDate} date={date} />
      </InputContainer>
    </Center>
  );
};

const PriceInputBox = ({
  data,
  onBlur,
  original,
  setSubmitData,
  submitData,
}) => {
  const [price, setPrice] = useState(data === "undefined" ? null : data);

  const convertLocaleStringToNumber = (s) => {
    if (!s) {
      return 0;
    }

    const result = parseInt(s.replace(/,/g, ""));

    if (isNaN(result)) {
      return 0;
    } else return result;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value === "0") {
      setPrice(0);
      return;
    }

    if (value === "") {
      setPrice(0);
      return;
    }

    const num = convertLocaleStringToNumber(value);
    setPrice(num);
  };

  const handleBlur = () => {
    onBlur(price);
    if (submitData.length) {
      setSubmitData((prev) => {
        if (Number(price)) {
          return prev.map((item) => {
            if (item.content === original.id) {
              return { ...item, price: Number(price) };
            }
            return item;
          });
        } else {
          return prev.filter((item) => item.content !== original.id);
        }
      });
    }
  };

  return (
    <Center>
      <InputContainer>
        <input
          className="price"
          placeholder="0"
          onChange={handleChange}
          onBlur={handleBlur}
          value={isNullOrUndefined(price) ? null : price.toLocaleString()}
        />
        <span className="unit">원</span>
      </InputContainer>
    </Center>
  );
};

const Container = styled.div`
  margin-bottom: 40px;
  width: fit-content;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  width: 90%;
  background-color: ${({ theme }) => theme.colors.blueGrayA100};
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  border-radius: 10px;

  input {
    width: 100%;
    font-family: Pretendard-Medium;
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.blueGray900};
    background-color: ${({ theme }) => theme.colors.blueGrayA100};
    border: none;
    outline: none;
    letter-spacing: -0.02em;

    &.price {
      text-align: right;
    }

    &.date {
      text-align: center;
      caret-color: transparent;

      &::placeholder {
        color: ${({ theme }) => theme.colors.blueGray400};
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px; /* 155.556% */
        letter-spacing: -0.36px;
      }
    }

    &.datepick {
      text-align: center;
      font-family: Pretendard-Medium;

      &::placeholder {
        color: ${({ theme }) => theme.colors.blueGray900};
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px; /* 155.556% */
        letter-spacing: -0.36px;
      }
    }
  }

  .unit {
    margin-left: 6px;
    color: ${({ theme }) => theme.colors.blueGray400};
  }
`;
