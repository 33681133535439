import { Title } from "@components/styles";
import styled from "styled-components";
import UsersListContent from "@components/page/admin/report/usersList/UserListContent";
import { useMenu } from "@features/useMenu";
import { useEffect } from "react";

export default function UsersList() {
  const { setCurrentMenu } = useMenu();

  useEffect(() => {
    setCurrentMenu("users");
  }, []);

  return (
    <Container>
      <Title>회원 및 권한 관리</Title>
      <UsersListContent />
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
`;
