import { AxiosError } from "axios";
import { useCallback } from "react";
import { useCookies } from "react-cookie";

const useApiError = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const handleError = useCallback((axiosError: AxiosError) => {
    const statusCode = axiosError.response.status;
    const message = (axiosError.response.data as { message: string }).message;

    if (message.includes("검증되지 않은")) {
      return;
    } else if (message.includes("존재하지")) {
      return;
    }

    switch (statusCode) {
      // 토큰 에러
      case 401:
        removeCookie("access_token");
        alert(
          `잘못된 요청입니다. 로그인 화면으로 이동합니다. \n🚨 서버 에러메시지 : ${
            (axiosError.response.data as { message: string }).message
          }`
        );
        window.location.replace("/login");
        break;
      case 403:
        alert(
          `접근 권한이 없습니다. 로그인 화면으로 이동합니다. \n🚨 서버 에러메시지 : ${
            (axiosError.response.data as { message: string }).message
          }`
        );
        window.location.replace("/login");
        break;
      default:
        alert(
          `일시적인 오류가 발생했어요. \n🚨 서버 에러메시지 : ${
            (axiosError.response.data as { message: string }).message ||
            (axiosError.response.data as { msg: string }).msg
          }`
        );
        break;
    }
  }, []);
  return { handleError } as const;
};

export default useApiError;
