import BTypeTextArea from "@components/core/BTypeInput/BTypeTextArea";
import { Arrow22 } from "@components/core/icon/arrow";
import { ArrowDirectionEnum } from "@components/core/icon/arrow/Arrow.interfaces";
import { Tooltip16 } from "@components/core/icon/tooltip";
import { deeplinkList } from "@constants/deeplink";
import { useState } from "react";
import styled from "styled-components";

export default function PushContent({ pushInfo, setPushInfo }) {
  const [requiresId, setRequiresId] = useState(false);

  // id 필요여부 찾는 함수
  const findSelectedOption = (value) => {
    for (const group of deeplinkList) {
      const selectedOption = group.contents.find(
        (content) => content.value === value
      );
      if (selectedOption) {
        return selectedOption;
      }
    }
    return false;
  };

  // 연결 스크린 핸들러
  const onChange = (event) => {
    const selectedValue = event.target.value;
    setPushInfo((prev) => ({ ...prev, id: "", deeplink: selectedValue }));
    const selectedOption = findSelectedOption(selectedValue);

    if (selectedOption) {
      setRequiresId(selectedOption.requiresId);
    }
  };

  return (
    <Container>
      <Input
        placeholder="타이틀"
        style={{ width: "100%" }}
        value={pushInfo.push_title}
        onChange={(e) =>
          setPushInfo((prev) => ({ ...prev, push_title: e.target.value }))
        }
      />
      <BTypeTextArea
        placeholder="설명 (선택사항)"
        textareaStyle={{
          width: "100%",
          height: 84,
        }}
        value={pushInfo.push_body}
        onChangeValue={(v) =>
          setPushInfo((prev) => ({ ...prev, push_body: v }))
        }
      />
      <Row style={{ marginTop: 20 }}>
        <Text15 style={{ marginRight: 4 }}>연결 스크린</Text15>
        <a
          href="https://dune-basketball-bb5.notion.site/eae2096b838a4ca095c8ce52805e1417?pvs=4"
          target="_blank"
          rel="noreferrer"
        >
          <Tooltip16 />
        </a>
      </Row>
      <Row style={{ marginTop: 10, gap: 8 }}>
        <InputContainer>
          <select
            onChange={(e) => onChange(e)}
            style={{
              color: pushInfo.deeplink ? "#333e4d" : "#808690",
            }}
            value={pushInfo.deeplink}
          >
            <option value={null}>스크린 없음</option>
            {deeplinkList.map((el) => (
              <optgroup label={el.group}>
                {el.contents.map((el) => (
                  <option value={el.value} label={el.title} />
                ))}
              </optgroup>
            ))}
          </select>
          <div style={{ position: "absolute", right: 16, top: 10 }}>
            <Arrow22 direction={ArrowDirectionEnum.DOWN} />
          </div>
        </InputContainer>
        <Input
          placeholder="관련 ID"
          style={{ width: "40%" }}
          value={pushInfo.id}
          disabled={!requiresId}
          onChange={(e) =>
            setPushInfo((prev) => ({ ...prev, id: e.target.value }))
          }
        />
      </Row>
    </Container>
  );
}

const Container = styled.div``;
const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  padding: 10px 20px;
  flex: 1;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: ${({ theme }) => theme.colors.blueGrayA100};

  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 18px;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.blueGray100};
    cursor: not-allowed;
  }
`;

const Text15 = styled.p`
  color: ${({ theme }) => theme.colors.blueGray600};
  font-size: 15px;
  font-weight: 500;
  line-height: 22px; /* 146.667% */
  letter-spacing: -0.3px;
`;

const InputContainer = styled.div`
  position: relative;
  padding: 10px 20px;
  flex: 1;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: ${({ theme }) => theme.colors.blueGrayA100};

  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 18px;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;

  select {
    background-color: ${({ theme }) => theme.colors.blueGrayA100};
    width: 100%;
    border: none;

    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
    appearance: none;

    &:focus {
      outline: none;
    }
  }
`;
