import { useEffect, useMemo } from "react";
import styled from "styled-components";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { hyphenPhoneNum } from "@utils/phoneNumber";
import { ConsultationInfo } from "@hooks/consultation/Consulting.interfaces";
import Pagination from "@components/core/paging/Pagination";
import { convertToKoreanTimeFromUTC } from "@hooks/calc/converToKoreanDate";
import { Link } from "react-router-dom";
import UnderLineCopyCell from "@components/common/UnderLineCopyCell";
import ImjangStatusChip from "./ImjangStatusChip";
import {
  ImjangListInfo,
  ImjangStatusEnum,
} from "@hooks/imjang/Imjang.interfaces";
import Empty from "@components/common/Empty";

export default function ImjangApplicantTable({
  data,
  setIsVisible,
  handleState,
}) {
  const columnHelper = createColumnHelper();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("state", {
        cell: ({ getValue, row }) => {
          const original = row.original as ImjangListInfo;
          return (
            <Center
              onClick={() => {
                setIsVisible(true);
                handleState({
                  applyId: original.applyId,
                });
              }}
            >
              <ImjangStatusChip status={getValue()} />
            </Center>
          );
        },
        header: "진행상태",
        maxSize: 10,
      }),
      columnHelper.accessor("applyDate", {
        cell: ({ getValue }) => {
          return convertToKoreanTimeFromUTC(getValue());
        },
        header: "신청날짜",
        maxSize: 20,
      }),
      columnHelper.accessor("name", { header: "신청자", maxSize: 20 }),
      columnHelper.accessor("phoneNumber", {
        cell: ({ getValue }) => (
          <UnderLineCopyCell content={hyphenPhoneNum(getValue())} />
        ),
        header: "신청자 연락처",
        maxSize: 20,
      }),
      columnHelper.accessor("isWebUser", {
        cell: ({ row }) => {
          const value = row.original["isWebUser"];
          if (value === null) return "-";
          else {
            if (value) return "웹";
            else return "앱";
          }
        },
        header: "구분",
        maxSize: 15,
      }),

      columnHelper.accessor("applyId", {
        cell: ({ getValue }) => (
          <StyledLink to={`/admin/imjang/applicant/${getValue()}`}>
            신청 상세정보
          </StyledLink>
        ),
        header: "상세",
        maxSize: 15,
      }),
    ];
  }, []);

  // useReactTable로 테이블 구조 정의
  const table = useReactTable({
    data,
    columns,
    enableRowSelection: true,
    enableMultiRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false, //페이지를 변경하는 상태 변화가 발생할 때 pageIndex는 0으로 리셋되는 옵션
  });

  useEffect(() => {
    table.setPageSize(10);
  }, []);

  return (
    <Container>
      <table className="contentsTable">
        <thead className="contentsThead">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="contentsHeader"
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            const state = (row.original as ConsultationInfo).state;
            const disabled =
              state === ImjangStatusEnum["done"] ||
              state === ImjangStatusEnum["cancel"];
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className="contentsData"
                    style={{
                      padding: "12px 0",
                      color: disabled ? "#B2B7BC" : "#4D5664",
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination table={table} />
      <Empty data={data} title="임장 비서 신청 내역이 없어요" />
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primaryBlue100};
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
`;
