import {
  createColumnHelper,
  flexRender,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { HTMLProps, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { getCoreRowModel } from "@tanstack/react-table";
import "../../../../page/table.css";
import Pagination from "@components/core/paging/Pagination";
import { useNavigate } from "react-router-dom";
import { convertToKoreanDate } from "@hooks/calc/converToKoreanDate";
import { theme } from "styles/theme";
import moment from "moment";
import { convertTimeInKorean } from "@utils/date";

export default function ImjangTimeTable({ data }) {
  const navigate = useNavigate();

  const [columnOrder, setColumnOrder] = useState([]);

  const columnHelper = createColumnHelper();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("date", {
        cell: ({ getValue }) => {
          return <p>{moment(getValue()).format("YYYY년 M월 DD일")}</p>;
        },
        header: "날짜",
        maxSize: 30,
      }),

      columnHelper.accessor("timeRange", {
        cell: ({ getValue }) => {
          return (
            <p style={{ textAlign: "left" }}>
              {convertTimeInKorean(getValue()) as string}
            </p>
          );
        },
        header: "시간",
        maxSize: 70,
      }),
    ];
  }, []);

  const table = useReactTable({
    data,
    columns,
    state: {
      columnOrder,
    },
    enableRowSelection: true,
    enableMultiRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false, //페이지를 변경하는 상태 변화가 발생할 때 pageIndex는 0으로 리셋되는 옵션
  });

  useEffect(() => {
    table.setPageSize(5);
  }, []);

  return (
    <Container>
      <SubTitle>방문 신청 날짜 및 시간</SubTitle>
      <table className="contentsTable">
        <thead className="contentsThead">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="contentsHeader"
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="contentsData">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination table={table} />
    </Container>
  );
}

function IndeterminateCheckbox({
  className = "",
  onChange,
  setSelectedRow,
  noticeId,
  ...rest
}: {
  setSelectedRow?: React.Dispatch<React.SetStateAction<number>>;
  noticeId?: number;
} & HTMLProps<HTMLInputElement>) {
  const onClick = (e) => {
    onChange(e);
    setSelectedRow(noticeId);
  };

  return (
    <input
      type="checkbox"
      className={className + " cursor-pointer"}
      onChange={onClick}
      {...rest}
    />
  );
}

const Container = styled.div`
  /* padding-top: 80px; */
`;

const SubTitle = styled.h2`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 18px;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;
`;
