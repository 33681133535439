import VersionTable from "@components/page/dev/version/VersionTable";
import { Button, Title } from "@components/styles";
import { db } from "../../firebase";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import PlusIcon from "@assets/icon/PlusIcon";
import AddVersionPopUp from "@components/page/dev/version/AddVersionPopUp";
import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import { useMenu } from "@features/useMenu";

export default function AppVersion() {
  const { setCurrentMenu } = useMenu();
  const [data, setData] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setCurrentMenu("version");
  }, []);

  const subscribeVersionCollection = () => {
    const arr = [];
    const versionRef = collection(db, "versions");
    const q = query(versionRef, orderBy("createdAt", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        arr.push({ ...doc.data(), version: doc.id });
      });
      setData(arr);
    });

    // unsubscribe();
  };

  useEffect(() => {
    subscribeVersionCollection();
  }, []);

  const handleAddVersion = async (e) => {
    setIsVisible(true);
  };

  return (
    <Container>
      <Title>Firestore 버전 관리</Title>
      <Row>
        <Button onClick={handleAddVersion}>
          <PlusIcon />
          <p>버전 추가</p>
        </Button>
      </Row>
      <VersionTable data={data || []} />
      <PopUpContainer isVisible={isVisible} setIsVisible={setIsVisible}>
        <AddVersionPopUp setIsVisible={setIsVisible} data={data} />
      </PopUpContainer>
    </Container>
  );
}

const Container = styled.div``;

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 8px 0 24px;
`;
