import {
  createColumnHelper,
  flexRender,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { HTMLProps, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { getCoreRowModel } from "@tanstack/react-table";
import "../../../table.css";
import Pagination from "@components/core/paging/Pagination";
import { useNavigate } from "react-router-dom";
import { convertToKoreanDate } from "@hooks/calc/converToKoreanDate";
import { Link } from "react-router-dom";
import Empty from "@components/common/Empty";

export default function SourceDownTable({ data }) {
  const navigate = useNavigate();

  const columnHelper = createColumnHelper();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("title", {
        cell: ({ row }) => {
          const href = `/influencer/consultation/source/${row.original["contentsId"]}`;

          return (
            <Title>
              <a href={href}>{row.original["title"]}</a>
            </Title>
          );
        },
        header: "제목",
        maxSize: 85,
      }),

      columnHelper.accessor("createdAt", {
        cell: ({ getValue }) => {
          return (
            <p style={{ textAlign: "center" }}>
              {convertToKoreanDate(getValue())}
            </p>
          );
        },
        header: "등록 날짜",
        maxSize: 15,
      }),
    ];
  }, []);

  const table = useReactTable({
    data,
    columns,
    enableRowSelection: true,
    enableMultiRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false, //페이지를 변경하는 상태 변화가 발생할 때 pageIndex는 0으로 리셋되는 옵션
  });

  useEffect(() => {
    table.setPageSize(10);
  }, []);

  // useEffect(() => {
  //   table.reset();
  // }, [data]);

  return (
    <Container>
      <table className="contentsTable">
        <thead className="contentsThead">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="contentsHeader"
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="noticeTableTd">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Empty data={data} title="데이터 자료가 없어요" />
      <Pagination table={table} />
    </Container>
  );
}

const Container = styled.div`
  /* padding-top: 80px; */
`;

const Title = styled.div`
  text-align: left;
  padding: 16px 0;
  cursor: pointer;
  width: 55vw;
  max-width: 60vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  a {
    color: ${({ theme }) => theme.colors.blueGray700};

    &:visited {
      color: ${({ theme }) => theme.colors.blueGray400};
    }
  }
`;
