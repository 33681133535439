import { Instance } from "@api/index";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  MaemulListReq,
  MaemulListRes,
  NewsReq,
  PushListRes,
  PushReq,
  PushSchedule,
} from "./management.interfaces";

// 푸시 통계 조회
export const useGetPushStat = ({ startDate, endDate }) =>
  useQuery({
    queryKey: ["pushStat", startDate, endDate],
    queryFn: async () => {
      console.log(
        `/push/push_stats${startDate && `?start_date=${startDate}`}${
          endDate && `&end_date=${endDate}`
        }`
      );
      const response = await Instance({
        url: `/push/push_stats${startDate && `?start_date=${startDate}`}${
          endDate
            ? `&end_date=${endDate}`
            : startDate
            ? `&end_date=${startDate}`
            : ""
        }`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 1000 * 60,
  });

// 닉네임으로 userId 조회
export const useGetUserId = () =>
  useMutation<{ userId: string }, AxiosError, { nickname: string }>(
    async (data) => {
      const response = await Instance({
        url: "/me/user/user_id_by_nickname",
        method: "POST",
        data,
      });
      return response.data;
    }
  );

// 푸시 발송
export const usePostPush = () =>
  useMutation<{ msg: string }, AxiosError, PushReq>(async (data) => {
    const response = await Instance({
      url: "/send_push",
      method: "POST",
      data,
    });
    return response.data;
  });

export const useCreatePushSchedule = () =>
  useMutation<{ msg: string }, AxiosError, PushReq>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: "/push/schedule_push",
        method: "POST",
        data,
      });
      return response.data;
    },
  });

export const useDeletePushSchedule = () =>
  useMutation<
    { msg: string },
    AxiosError,
    { schedule_group: string; schedule_id: string }
  >({
    mutationFn: async (data) => {
      const response = await Instance({
        url: "/push/cancel_push_schedule",
        method: "POST",
        data,
      });
      return response.data;
    },
  });

export const useGetPushScheduleList = () =>
  useMutation<
    PushListRes,
    AxiosError,
    { offset: number; limit: number; schedule_group: "admin" }
  >(async (data) => {
    const response = await Instance({
      url: "/push/get_schedules",
      method: "POST",
      data,
    });
    return response.data;
  });

export const useGetPushScheduleDetail = ({ schedule_id }) =>
  useQuery({
    queryKey: ["pushSchedule", schedule_id],
    queryFn: async () => {
      const response = await Instance({
        url: `/push/get_push_schedule`,
        method: "POST",
        data: { schedule_id },
      });
      return response.data;
    },
    staleTime: 1000 * 60,
  });

export const useCreatePushGroup = () => {
  const queryClient = useQueryClient();
  return useMutation<
    PushListRes,
    AxiosError,
    { target_name: string; target_users: string[] }
  >({
    mutationFn: async (data) => {
      const response = await Instance({
        url: "/push/create_push_target",
        method: "POST",
        data,
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["cohortList"],
      });
    },
  });
};

export const useGetPushGroup = () =>
  useQuery({
    queryKey: ["cohortList"],
    queryFn: async () => {
      const response = await Instance({
        url: `/push/get_push_targets`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 1000 * 60,
  });

export const useGetSearchResult = () =>
  useQuery({
    queryKey: ["searchResult"],
    queryFn: async () => {
      const response = await Instance({
        url: `https://m.boodong.kr/search/esSearch/api?keyword=반포&category=아파트&limit=100`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 1000 * 60,
  });

export const useDeletePushGroup = () => {
  const queryClient = useQueryClient();
  return useMutation<number, AxiosError, { target_name: string }>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: "/push/delete_push_target",
        method: "POST",
        data,
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["cohortList"],
      });
    },
  });
};

export const useGetIRApplicant = () =>
  useQuery({
    queryKey: ["IR"],
    queryFn: async () => {
      const response = await Instance({
        url: `/admin/IR/applicants`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 1000 * 60,
  });

export const useGetIRApplicantDetail = ({ id }) =>
  useQuery({
    queryKey: ["IR", id],
    queryFn: async () => {
      const response = await Instance({
        url: `/admin/IR/applicant/${id}`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 1000 * 60,
  });

export const useGetNews = () =>
  useQuery({
    queryKey: ["news"],
    queryFn: async () => {
      const response = await Instance({
        url: `/admin/news`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 1000 * 60,
  });

export const usePostNews = () => {
  const queryClient = useQueryClient();
  return useMutation<number, AxiosError, NewsReq>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: "/admin/news",
        method: "POST",
        data,
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["news"],
      });
    },
  });
};

export const useDeleteNews = () => {
  const queryClient = useQueryClient();
  return useMutation<number, AxiosError, { id: string }>({
    mutationFn: async ({ id }) => {
      const response = await Instance({
        url: `/admin/news/${id}`,
        method: "DELETE",
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["news"],
      });
      // queryClient.invalidateQueries({
      //   queryKey: ["cohortList"],
      // });
    },
  });
};

// 매물리스트 조회
export const useGetMaemulList = () => {
  const queryClient = useQueryClient();
  return useMutation<MaemulListRes, AxiosError, MaemulListReq>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: "/danjis/info/maemul_list",
        method: "POST",
        data,
      });
      return response.data;
    },
    onSuccess: () => {},
  });
};

// 매물 푸쉬 통계 조회
export const useGetMaemulPushStats = (data: {
  offset: number;
  limit: number;
}) =>
  useQuery({
    queryKey: ["push", "maemul"],
    queryFn: async () => {
      const response = await Instance({
        url: "/push/meamul_push_stats",
        method: "GET",
        data,
      });
      return response.data;
    },
  });
