/* eslint-disable no-restricted-globals */
import { Button, ButtonWrapper } from "@components/styles";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import UsersTable from "@components/page/admin/report/usersList/UsersTable";
import { useEffect, useState } from "react";
import {
  useDeleteInfluencer,
  useGetAdminsList,
  useGetInfluencersList,
} from "@hooks/admin";
import PlusIcon from "@assets/icon/PlusIcon";
import MinusIcon from "@assets/icon/MinusIcon";

export default function UsersListContent() {
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState();
  const { mutate } = useDeleteInfluencer();
  const goAddUser = () => navigate("/admin/config/users/add");
  const onDeleteUser = () => {
    // 클릭한 유저 아이디 가져오기
    if (!selectedRow) {
      alert("삭제할 회원을 선택해주세요.");
    } else {
      if (confirm("선택된 회원을 삭제합니다.")) {
        mutate({ id: selectedRow });
      }
    }
  };

  const { data: influencerData } = useGetInfluencersList();
  const { data: administratorData } = useGetAdminsList();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (influencerData) {
      setData(influencerData.influencers);
    }
    if (administratorData) {
      setData((prev) => [...administratorData.administrators, ...prev]);
    }
  }, [influencerData, administratorData]);

  return (
    <Container>
      <ButtonWrapper>
        <Button onClick={goAddUser}>
          <PlusIcon />
          <p>회원 추가</p>
        </Button>
        {/* <Button onClick={onDeleteUser}>
          <MinusIcon />
          <p>회원 삭제</p>
        </Button> */}
      </ButtonWrapper>
      <UsersTable setSelectedRow={setSelectedRow} data={data} />
    </Container>
  );
}

const Container = styled.div``;
