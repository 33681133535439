import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import { AuthInstance } from "../../api";
import { useNaverLogin, useNaverToken } from "../../hooks/login";

export const NaverCallback = () => {
  const navigate = useNavigate();
  const goAddPhoneNum = ({ naverToken }) => {
    navigate("/login/number", { state: { naverToken } });
  };
  const { mutate: getNaverToken } = useNaverToken();
  const { mutate } = useNaverLogin();

  const cookies = new Cookies();

  const saveToken = (token: string) => {
    cookies.set("access_token", token, { path: "/" });
  };

  useEffect(() => {
    (async () => {
      const code = new URL(window.location.href).searchParams.get("code");
      console.log("w", code);
      if (code) {
        getNaverToken(
          { code },
          {
            onSuccess: (res) => {
              mutate(
                { token: res.naverToken },
                {
                  onSuccess: (res2) => {
                    console.log("access_token", res2.access_token);
                    saveToken(res2.access_token);
                    AuthInstance.defaults.headers.common.Authorization = `Bearer ${res2.access_token}`;

                    navigate("/influencer/contents/upload");
                  },
                  onError: (err2: any) => {
                    if (err2.response.data.message.includes("phone_number")) {
                      goAddPhoneNum({ naverToken: res.naverToken });
                    } else {
                    }
                  },
                }
              );
            },
            onError: (err: any) => {
              alert(`네이버 토큰 발급에 문제가 있습니다. ${err.message}`);
            },
          }
        );
      }
    })();
  }, [mutate]);

  return <div></div>;
};
