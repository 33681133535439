import DownArrow from "@assets/arrow/DownArrow";
import { useMenu } from "@features/useMenu";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function Menu({ data, active, onClick }) {
  const { menu, subMenu, linkUrl, activeIcon, inactiveIcon } = data;
  const [isShow, setIsShow] = useState(true);
  const [isHover, setIsHover] = useState(false);
  const { currentSubMenu } = useMenu();

  return (
    <>
      <MenuItem
        style={{ marginBottom: subMenu?.length && isShow ? 6 : 16 }}
        onMouseOver={() => {
          setIsHover(true);
        }}
        onMouseOut={() => {
          setIsHover(false);
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {active ? activeIcon : inactiveIcon}
          <Link to={linkUrl}>
            <MenuTitle onClick={onClick} $active={active} $hover={isHover}>
              {menu}
            </MenuTitle>
          </Link>
          {subMenu?.length > 0 && (
            <Arrow
              onClick={() => setIsShow((prev: boolean) => !prev)}
              $isShow={isShow}
            >
              <DownArrow />
            </Arrow>
          )}
        </div>
      </MenuItem>
      {isShow && subMenu?.length ? (
        <SubMenuWrapper>
          {subMenu?.map((el: any, idx: number) => {
            const active = el.value === currentSubMenu;
            return <SubMenu el={el} active={active} key={idx} />;
          })}
        </SubMenuWrapper>
      ) : null}
    </>
  );
}

function SubMenu({
  el,
  active,
}: {
  el: { menu: string; linkUrl: string };
  active: boolean;
}) {
  // const onClick = () => {
  //   setCurrentSubMenu(el.menu);
  // };

  return (
    <SubMenuItem>
      <StyledLink to={el.linkUrl} $active={active}>
        {el.menu}
      </StyledLink>
    </SubMenuItem>
  );
}

const MenuItem = styled.li`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
`;

const MenuTitle = styled.h1<{ $active: boolean; $hover: boolean }>`
  padding-left: 10px;
  color: ${({ $active, theme }) =>
    $active ? theme.colors.primaryBlue100 : theme.colors.blueGray600};
  font-size: 18px;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.blueGray700};
    transform: scale(1.05);
  }
`;

const SubMenuWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const SubMenuItem = styled.li`
  margin-bottom: 8px;
  padding: 6px 0 6px 42px;
  list-style-type: none;
`;

const StyledLink = styled(Link)<{ $active: boolean }>`
  font-size: 16px;
  font-weight: ${({ $active }) => ($active ? 500 : 400)};
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
  color: ${({ $active, theme }) =>
    $active ? theme.colors.blueGray700 : theme.colors.blueGray500};
`;

const Arrow = styled.div<{ $isShow: boolean }>`
  position: absolute;
  right: 20px;
  transform: ${({ $isShow }) => ($isShow ? "rotate(180deg)" : "rotate(0deg)")};
  cursor: pointer;
`;
