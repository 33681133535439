import InfluencerNav from "@components/nav/InfluencerNav";
import { useMenu } from "@features/useMenu";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

export default function InfluencerLayout() {
  const { setGlobalThemeMode } = useMenu();

  useEffect(() => {
    setGlobalThemeMode("light");
  }, []);

  return (
    <Container>
      <InfluencerNav />
      <OutletWrapper>
        <Outlet />
      </OutletWrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
`;

const OutletWrapper = styled.main`
  flex: 1;
  padding: 60px 0 40px 40px;
  margin-right: auto;
`;
