import { useGetMe } from "@hooks/login";
import { db, fireAuth } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import styled from "styled-components";
import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import { SyncLoader } from "react-spinners";
import { signInWithEmailAndPassword } from "firebase/auth";

export default function DevPrivateRouter({ authentication }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { mutate, data } = useGetMe();

  const checkAuth = async () => {
    let arr = [];
    const authRef = collection(db, "authentication");
    const querySnapshot = await getDocs(authRef);
    querySnapshot.forEach((doc) => {
      arr.push(doc.id);
    });

    if (arr.includes(data.me.email)) {
      setIsAuthenticated(true);
      // auth
      signInWithEmailAndPassword(fireAuth, "admin@boodong.kr", "a123456")
        .then((userCredential) => {
          const user = userCredential.user;
          console.log("🪄  signInWithEmailAndPassword  user", user);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMsg = error.message;
          console.log("error", errorCode, errorMsg);
        });
    } else {
      setIsAuthenticated(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    mutate();
  }, []);

  useEffect(() => {
    if (!data) return;

    checkAuth();
  }, [data]);

  if (!authentication) {
    // 인증 필요없는 화면
    return <Outlet />;
  }

  // 토큰 인증 필요한 화면
  if (isLoading) {
    return (
      <PopUpContainer isVisible={true} setIsVisible={null}>
        <SyncLoader />
        <p
          style={{
            marginTop: 20,
            fontSize: 20,
            color: "white",
            fontWeight: 500,
          }}
        >
          권한 확인 중
        </p>
      </PopUpContainer>
    );
  }

  if (isAuthenticated) {
    return <Outlet />;
  } else {
    return <Navigate to="/login" />;
  }
}

const Container = styled.div``;
