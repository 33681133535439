import { useEffect, useState } from "react";
import Nav from "./Nav";
import { influencerNavList } from "@constants/navConstants";
import { useGetMe } from "@hooks/login";
import { useNavigate } from "react-router-dom";
import BigNav from "./BigNav";

export default function InfluencerNav() {
  const navigate = useNavigate();
  const { mutate, data } = useGetMe();
  const [userInfo, setUserInfo] = useState({
    nickname: "",
    email: "",
  });

  useEffect(() => {
    mutate(undefined, {
      onSuccess: (res) => {
        if (res.me.email.includes("boodong.kr")) {
          navigate("/login");
        }
      },
    });
  }, []);

  useEffect(() => {
    if (data) {
      setUserInfo((prev) => ({
        nickname: data.me.nickname,
        email: data.me.email,
      }));
    }
  }, [data]);

  return <BigNav navList={influencerNavList} userInfo={userInfo} />;
}
