import AbsoluteFillLoading from "@components/common/AbsoluteFillLoading";
import { Calendar } from "@components/core/calendar";
import PushScheduleTable from "@components/page/admin/manage/push/PushScheduleList/PushScheduleTable";
import { Title } from "@components/styles";
import { useMenu } from "@features/useMenu";
import { useGetPushScheduleList } from "@hooks/management";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

export default function PushScheduleList() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || 1;

  const { setCurrentMenu } = useMenu();

  const { mutate, data, isLoading } = useGetPushScheduleList();

  useEffect(() => {
    mutate({
      offset: (Number(page) - 1) * 10,
      limit: 10,
      schedule_group: "admin",
    });
  }, [page]);

  useEffect(() => {
    setCurrentMenu("push-schedule");
  }, []);

  return (
    <Container>
      <Title>푸시 스케줄 관리</Title>
      <ControlWrapper>
        <UploadButton
          onClick={() => {
            navigate("/admin/push-schedule/add");
          }}
          style={{ marginTop: 24 }}
        >
          <p>푸시 생성하기</p>
        </UploadButton>
        {/* <Calendar setRangeDate={() => {}} text="기준: 발송날짜" /> */}
      </ControlWrapper>
      <PushScheduleTable
        data={data?.schedules || []}
        total={data?.total_count}
      />
      {isLoading ? <AbsoluteFillLoading /> : null}
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
`;

const ControlWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const UploadButton = styled.div`
  padding: 9px 16px;
  width: fit-content;
  height: fit-content;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.primaryBlue100};
  cursor: pointer;

  p {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
    color: white;
  }
`;
