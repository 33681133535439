import { useEffect, useMemo, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { getCoreRowModel } from "@tanstack/react-table";
import styled from "styled-components";
import cn from "classnames";
import Pagination from "@components/core/paging/Pagination";
import "../../../table.css";
import { convertToKoreanDate } from "@hooks/calc/converToKoreanDate";
import Empty from "@components/common/Empty";
import { Link, useNavigate } from "react-router-dom";
import { useMenu } from "@features/useMenu";
import { WhitespaceEnum } from "@components/core/icon/whiteSpace/WhiteSpace.interfaces";

export default function CohortTable({ data, onClickRow }) {
  const navigate = useNavigate();
  const { globalThemeMode } = useMenu();

  const columnHelper = createColumnHelper();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("number", {
        cell: (info) => info.row.index + 1,
        header: "순번",
        maxSize: 5,
      }),
      columnHelper.accessor("target_name", {
        cell: ({ row }) => {
          return (
            <Title
              title={row.original["target_name"]}
              onClick={() => onClickRow(row.original)}
            >
              {row.original["target_name"]}
            </Title>
          );
        },
        header: "그룹 이름",
        maxSize: 45,
      }),

      columnHelper.accessor("requested_user_count", {
        header: "총 인원",
        maxSize: 10,
      }),
      columnHelper.accessor("valid_user_count", {
        header: "발송 대상 인원",
        maxSize: 10,
      }),
      columnHelper.accessor("created_at", {
        cell: ({ getValue }) => {
          return convertToKoreanDate(getValue());
        },
        header: "생성일자",
        maxSize: 10,
      }),
    ];
  }, []);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false, //페이지를 변경하는 상태 변화가 발생할 때 pageIndex는 0으로 리셋되는 옵션
  });

  useEffect(() => {
    table.setPageSize(10);
  }, []);

  return (
    <Container>
      <table className="contentsTable">
        <thead
          className={cn("contentsThead", {
            darkContentsThead: globalThemeMode === "dark",
          })}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={cn("contentsHeader", {
                    darkContentsHeader: globalThemeMode === "dark",
                  })}
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className={cn("contentsData", {
                    darkContentsData: globalThemeMode === "dark",
                  })}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Empty
        data={data}
        title="생성된 그룹이 없어요"
        iconType={WhitespaceEnum.Default}
      />
      <Pagination table={table} />
    </Container>
  );
}

const Container = styled.div``;

const Title = styled.h2`
  margin-left: 20px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
`;
