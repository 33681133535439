import AddNoticeContainer from "@components/page/admin/report/notice/AddNoticeContainer";
import { Title } from "@components/styles";
import { useMenu } from "@features/useMenu";
import { useEffect } from "react";
import styled from "styled-components";

export default function NoticeWrite() {
  const { setCurrentMenu } = useMenu();

  useEffect(() => {
    setCurrentMenu("notice");
  }, []);

  return (
    <Container>
      <Title>공지사항 등록</Title>
      <AddNoticeContainer />
    </Container>
  );
}

const Container = styled.div``;
