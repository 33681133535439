import B1TypeInputBoxTyping from "@components/core/BTypeInput/B1TypeInputBoxTyping";
import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import B1TypeDateInput from "@components/core/input/B1TypeDateInput";
import { Title } from "@components/styles";
import { useMenu } from "@features/useMenu";
import { usePostNews } from "@hooks/management";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import styled from "styled-components";

export default function NewsEdit() {
  const navigate = useNavigate();
  const satate = useParams();
  console.log("🐙 ~ NewsEdit ~ params:", satate);
  const { setCurrentMenu } = useMenu();
  const { mutate, isLoading } = usePostNews();
  const [contentInfo, setContentInfo] = useState({
    releasedAt: null,
    title: null,
    url: null,
  });

  const 뉴스_추가 = () => {
    mutate(contentInfo, {
      onSuccess: () => {
        alert("업로드 성공");
        navigate(-1);
      },
    });
  };

  useEffect(() => {
    setCurrentMenu("news");
  }, []);

  return (
    <Container>
      <Title>뉴스 수정</Title>
      <div style={{ height: 16 }} />

      <B1TypeDateInput
        value={contentInfo.releasedAt}
        onChangeValue={(v: any) => {
          setContentInfo((prev) => ({
            ...prev,
            releasedAt: v,
          }));
        }}
        disabled={false}
        style={{ maxWidth: "30vw", minWidth: 670 }}
        label={"기사 발행일"}
        placeholder="기사 발행일을 선택하세요."
      />

      <B1TypeInputBoxTyping
        inputType={InputTypeEnum.OnlyText}
        label={"뉴스 제목"}
        placeholder={"뉴스 제목을 입력하세요."}
        value={contentInfo.title}
        onChangeValue={(v: InputTypeEnum.OnlyText) =>
          setContentInfo((prev) => ({ ...prev, title: v }))
        }
        style={{ maxWidth: "30vw", minWidth: 670 }}
      />
      <B1TypeInputBoxTyping
        inputType={InputTypeEnum.OnlyText}
        label={"뉴스 링크"}
        placeholder={"뉴스 링크를 입력하세요."}
        value={contentInfo.url}
        onChangeValue={(v: InputTypeEnum.OnlyText) =>
          setContentInfo((prev) => ({ ...prev, url: v }))
        }
        style={{ maxWidth: "30vw", minWidth: 670 }}
      />
      <div style={{ marginTop: 20, maxWidth: "30vw", minWidth: 670 }}>
        <Button $isDone={true} disabled={!true} onClick={뉴스_추가}>
          {isLoading ? (
            <FadeLoader
              cssOverride={{
                width: 10,
                height: 10,
                transform: "scale(0.5)",
                top: -2,
                left: 0,
              }}
              color="white"
            />
          ) : (
            <p>등록하기</p>
          )}
        </Button>
      </div>
    </Container>
  );
}

const Container = styled.div``;

const Button = styled.button<{ $isDone: boolean }>`
  padding: 10px 0;
  width: 100%;
  border-radius: 8px;
  border: none;
  background: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.primaryBlue100 : theme.colors.blueGray100};
  cursor: pointer;
  color: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.white : theme.colors.blueGray500};
  font-size: 18px;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.36px;

  &:disabled {
    cursor: not-allowed;
  }
`;
