export const copyText = (text, alertText) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      alert(alertText);
    })
    .catch((error) => {
      console.error("복사 실패:", error);
    });
};
