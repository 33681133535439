export enum ImjangStatusEnum {
  "reception" = "reception",
  "waiting" = "waiting",
  "proceed" = "proceed",
  "cancel" = "cancel",
  "done" = "done",
}

export const tradeTypeObj = {
  sell: "매매",
  rent: "전/월세",
  none: "상관없음",
};

export type tradeType = keyof typeof tradeTypeObj;
export interface ImjangListInfo {
  applyDate: string;
  applyId: string;
  danjiNum: number;
  name: string;
  phoneNumber: string;
  state: string;
}
