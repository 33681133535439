import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import BTypeTextArea from "@components/core/BTypeInput/BTypeTextArea";
import { Radio24 } from "@components/core/icon/radio";
import { RadioEnum } from "@components/core/icon/radio/Radio.interfaces";
import { Title } from "@components/styles";
import {
  useGetApplicantInfo,
  useGetApplicantPaymentInfo,
  usePostCardRefund,
  usePostPaymentUpdate,
} from "@hooks/consultation";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import EmptyPreQuestion from "./EmptyPreQuestion";
import { Plus16 } from "@components/core/icon/plus";

export default function ConsultationApplicant() {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const { mutate } = usePostPaymentUpdate();
  const { mutate: cardRefund } = usePostCardRefund();
  const { data: paymentData } = useGetApplicantPaymentInfo({ applicantId: id });
  const { data: applicantData } = useGetApplicantInfo({
    applicantId: id,
  });

  const [paymentInfo, setPaymentInfo] = useState({
    paymentStatus: null,
    refundText: "환불 일자: \n환불 금액: \n환불 전 진행 상태: \n환불 사유: \n",
  });

  const 보고서_보내기 = () => navigate(`/admin/consultation/status/${id}`);

  useEffect(() => {
    if (!paymentData) return;
    setPaymentInfo((prev) => ({
      ...prev,
      paymentStatus: paymentData.status,
      refundText:
        paymentData.refundText ||
        "환불 일자: \n환불 금액: \n환불 전 진행 상태: \n환불 사유: \n",
    }));
  }, [paymentData]);

  const 결제상태변경 = (status) => {
    setPaymentInfo((prev) => ({ ...prev, paymentStatus: status }));
    mutate(
      { applicantId: id, paymentStatus: status, refundText: "" },
      {
        onError: (res) => {
          alert(res.response.data["message"]);
          setPaymentInfo((prev) => ({ ...prev, paymentStatus: !status }));
        },
      }
    );
  };

  const 환불메모작성 = () => {
    if (
      window.confirm(
        "환불처리를 진행할까요?\n환불이 된 경우 보고서를 수정할 수 없습니다."
      )
    ) {
      if (paymentData.method === "카드결제") {
        // 카드일 경우
        cardRefund(
          {
            applicantId: id,
            refundText: paymentInfo.refundText,
          },
          {
            onSuccess: (res) => alert("환불 완료"),
            onError: (res) => {
              alert(res.response.data["message"]);
            },
          }
        );
      } else {
        // 무통장 입금일 경우
        mutate(
          {
            applicantId: id,
            paymentStatus: "환불 완료",
            refundText: paymentInfo.refundText,
          },
          {
            onSuccess: () => alert("제출 성공"),
            onError: (res) => {
              alert(res.response.data["message"]);
            },
          }
        );
      }
    }
  };

  const 구글폼_추가 = () => {
    const 구글_링크 = prompt("구글폼 링크를 작성해주세요.");
  };

  const 보내기_활성화 = useMemo(() => {
    if (!applicantData || !paymentData) return;

    if (!applicantData.report.notionUrl) return false;
    if (paymentData.status === "환불 완료") return false;
    if (applicantData.state === "상담취소") return false;
    return true;
  }, [applicantData, paymentData]);

  if (!paymentData || !applicantData) return;

  return (
    <Container disabled={!!paymentData.refundText}>
      <BigRow>
        <Title>{applicantData.applicantName}님 상담신청 정보</Title>
        <UploadButton
          onClick={보고서_보내기}
          active={보내기_활성화}
          disabled={!보내기_활성화}
        >
          <p>보고서 보내기</p>
        </UploadButton>
      </BigRow>
      <div style={{ height: 48 }} />
      <TableTitle>상담 신청 및 결제정보</TableTitle>
      <BigRow>
        <Row>
          <p className="head">이름</p>
          <p className="body">{applicantData.applicantName}</p>
        </Row>
        <Row>
          <p className="head">승인번호</p>
          <p className="body">{paymentData.cardApprovalNumber || "-"}</p>
        </Row>
      </BigRow>
      <BigRow>
        <Row>
          <p className="head">담당자</p>
          <p className="body">{applicantData.influencer.name}</p>
        </Row>
        <Row>
          <p className="head">결제수단</p>
          <p className="body">{paymentData.method}</p>
        </Row>
      </BigRow>
      <BigRow>
        <Row>
          <p className="head">상담방식</p>
          <p className="body">{applicantData.type}</p>
        </Row>
        <Row>
          <p className="head" title={paymentData.status}>
            결제금액
          </p>
          <p className="body">{applicantData.price.toLocaleString() + "원"}</p>
        </Row>
      </BigRow>
      {paymentData.method === "무통장 입금" && (
        <BigRow>
          <Row style={{ borderBottomWidth: 0 }}></Row>
          <Row style={{ width: "100%" }}>
            <p className="head">결제확인</p>
            <div className="body" style={{ display: "flex", gap: 55 }}>
              <Radio onClick={() => 결제상태변경("결제 대기")}>
                <Radio24
                  status={
                    paymentInfo.paymentStatus === "결제 대기"
                      ? RadioEnum.Activate
                      : RadioEnum.Default
                  }
                />
                <p>미완료</p>
              </Radio>
              <Radio onClick={() => 결제상태변경("결제 완료")}>
                <Radio24
                  status={
                    paymentInfo.paymentStatus === "결제 완료"
                      ? RadioEnum.Activate
                      : RadioEnum.Default
                  }
                />
                <p>완료</p>
              </Radio>
            </div>
          </Row>
        </BigRow>
      )}
      <div style={{ height: 60 }} />

      <TableTitle>상담신청 정보</TableTitle>

      {applicantData.report.notionUrl ? (
        <BigRow style={{ marginBottom: 60 }}>
          <Row>
            <p className="head" style={{ width: "20%" }}>
              상담 신청 정보 노션
            </p>
            <p className="body">
              <a
                href={applicantData.report.notionUrl}
                target="_blank"
                rel="noreferrer"
              >
                {applicantData.report.notionUrl}
              </a>
            </p>
          </Row>
        </BigRow>
      ) : (
        <EmptyPreQuestion
          applicantId={applicantData.applicantId}
          hasPreQuestion={!!applicantData.preQuestion}
        />
      )}

      <TableTitle>환불 관리</TableTitle>
      <BTypeTextArea
        placeholder="기타메모를 작성하세요."
        textareaStyle={{
          width: "100%",
          maxWidth: 1221,
          height: 240,
        }}
        value={paymentInfo.refundText}
        onChangeValue={(v: InputTypeEnum.OnlyText) =>
          setPaymentInfo((prev) => ({ ...prev, refundText: v }))
        }
      />
      <RefundBtn
        onClick={환불메모작성}
        disabled={!!paymentData.refundText}
        aria-disabled={!!paymentData.refundText}
      >
        <p>환불정보 등록</p>
      </RefundBtn>
    </Container>
  );
}

const Container = styled.div<{ disabled: boolean }>`
  width: 75vw;
  max-width: 1800px;
  min-width: 1100px;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.blueGray400 : theme.colors.blueGray700};
`;

const TableTitle = styled.h1`
  padding-bottom: 12px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.blueGray900};
  font-size: 24px;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
  letter-spacing: -0.48px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.blueGray800};
`;

const Row = styled.div`
  display: flex;
  padding: 16px 0;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueGray100};

  .head {
    width: 40%;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }

  .body {
    width: 60%;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;

    a {
      color: #1c84ff;

      &:visited {
        color: #1c84ff;
      }
    }
  }
`;

const BigRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
`;

const UploadButton = styled.button<{ active: boolean }>`
  margin: 8px 0 16px auto;
  padding: 9px 16px;
  width: fit-content;
  border-radius: 8px;
  background: ${({ theme, active }) =>
    active ? theme.colors.primaryBlue100 : theme.colors.blueGray100};
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  p {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
    color: ${({ theme, active }) =>
      active ? theme.colors.white : theme.colors.blueGray500};
  }
`;

const RefundBtn = styled.button<{ disabled: boolean }>`
  margin-top: 16px;
  margin-left: auto;
  display: block;
  padding: 12px 18px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 10px;
  background: ${({ theme, disabled }) =>
    disabled ? theme.colors.blueGray100 : theme.colors.red1000};
  cursor: pointer;

  p {
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.blueGray500 : theme.colors.white};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }
`;

const Radio = styled.div`
  display: flex;
  gap: 12px;
`;
