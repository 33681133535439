import {
  createColumnHelper,
  flexRender,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";
import styled from "styled-components";
import { getCoreRowModel } from "@tanstack/react-table";
import "../../table.css";
import Pagination from "@components/core/paging/Pagination";
import { convertToKoreanDate } from "@hooks/calc/converToKoreanDate";

export default function ViewNoticeTable({ data, onClick }) {
  const columnHelper = createColumnHelper();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("title", {
        cell: ({ row }) => {
          const title = row.original["title"];
          return <Title>{title}</Title>;
        },
        header: "공지 내용",
        size: 300,
      }),

      columnHelper.accessor("created_at", {
        cell: ({ getValue }) => {
          return (
            <p style={{ textAlign: "center" }}>
              {convertToKoreanDate(getValue())}
            </p>
          );
        },
        header: "공지날짜",
        size: 100,
      }),
      columnHelper.accessor("empty", {
        header: "",
        size: 10,
      }),
    ];
  }, []);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false, //페이지를 변경하는 상태 변화가 발생할 때 pageIndex는 0으로 리셋되는 옵션
  });

  return (
    <Container>
      <table className="contentsTable">
        <thead className="contentsThead">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="contentsHeader"
                  style={{ width: header.getSize() }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              onClick={() => onClick(row)}
              style={{ cursor: "pointer" }}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="noticeTableTd">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination table={table} />
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
  padding-top: 40px;
`;

const Title = styled.h2`
  text-align: left;
  padding: 16px 0;
  cursor: pointer;
  width: 60vw;
  max-width: 65vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
