export default function Close16() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <rect width="16" height="16" fill="white" />
      <path d="M3 3L13 13" stroke="#666E7A" strokeLinecap="round" />
      <path d="M13 3L3 13" stroke="#666E7A" strokeLinecap="round" />
    </svg>
  );
}
