import AbsoluteFillLoading from "@components/common/AbsoluteFillLoading";
import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import SendCheckPopup from "@components/page/admin/manage/push/SendPush/SendCheckPopup";
import SendPreview from "@components/page/admin/manage/push/SendPush/SendPreview";
import SendPushContent from "@components/page/admin/manage/push/SendPush/SendPushContent";
import { Title } from "@components/styles";
import { useMenu } from "@features/useMenu";
import {
  useCreatePushSchedule,
  useDeletePushSchedule,
  useGetPushScheduleDetail,
  usePostPush,
} from "@hooks/management";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import styled from "styled-components";

// 예약 메시지만 수정 가능함
export default function EditPushSchedule() {
  const navigate = useNavigate();
  const { setCurrentMenu } = useMenu();
  const params = useParams();
  const schedule_id = params.id;

  const { data, isLoading: getLoading } = useGetPushScheduleDetail({
    schedule_id,
  });
  const { mutate } = useDeletePushSchedule();
  const { mutate: nowMutate, isLoading: nowLoading } = usePostPush();
  const { mutate: afterMutate, isLoading: afterLoading } =
    useCreatePushSchedule();

  useEffect(() => {
    setCurrentMenu("push-schedule");
  }, []);

  const canEdit = useMemo(() => {
    if (!data) return;

    const currentTime = moment();
    const givenTime = moment(data.push_schedule, "YYYY-MM-DD HH:mm");

    return givenTime.isAfter(currentTime);
  }, [data]);

  const [isVisible발송확인팝업, setIsVisible발송확인팝업] = useState(false);
  const [pushInfo, setPushInfo] = useState({
    method: "single",
    nickname: null,
    push_title: null,
    push_body: null,
    push_type: null,
    target: [],
    target_group: null, // 예약 발송
    schedule: null, // 예약 발송
    deeplink: null,
    screen_destination: null,
    needUpgradeNoti: false, // 즉시발송
  });

  useEffect(() => {
    if (!data) return;

    setPushInfo((prev) => ({
      ...prev,
      method: data.topic_type === "nickname" ? "single" : "multiple",
      target: [],
      target_group: data.topic_type === "nickname" ? null : data.topic_name,
      nickname: data.topic_type === "nickname" ? data.topic_name : null,
      push_title: data.push_title,
      push_body: data.push_body,
      push_type: data.push_type,
      schedule: data.push_schedule,
      deeplink: data.deeplink,
      schedule_group: "admin",
      schedule_id,
    }));
  }, [data]);

  const 푸시_취소 = () => {
    if (window.confirm("선택한 푸시를 취소합니다.")) {
      mutate(
        { schedule_group: "admin", schedule_id },
        { onSuccess: () => alert("취소 완료") }
      );
    }
  };

  const onSuccess = () => {
    navigate("/admin/push-schedule?page=1");
    alert("발송 성공");
  };

  const 푸시_수정 = () => {
    const {
      method,
      nickname,
      target,
      target_group,
      schedule,
      needUpgradeNoti,
      push_type,
      ...common
    } = pushInfo;

    let body;

    if (pushInfo.push_type === "scheduled") {
      if (method === "single") {
        body = {
          target_group: target[0],
          schedule,
          ...common,
        };
      } else if (method === "multiple") {
        body = { target_group, schedule, ...common };
      }
      console.log(body);
      afterMutate(body, {
        onSuccess: onSuccess,
        onError: () => alert("발송 실패"),
      });
    } else {
      if (method === "single") {
        body = { target, needUpgradeNoti, ...common };
      } else if (method === "multiple") {
        body = { target: target_group, needUpgradeNoti, ...common };
      }
      console.log(body);
      nowMutate(body, {
        onSuccess: onSuccess,
        onError: () => alert("발송 실패"),
      });
    }
  };

  const isDone = useMemo(() => {
    return (
      pushInfo.method &&
      (Boolean(pushInfo.method === "single" && pushInfo.target.length) ||
        Boolean(pushInfo.method === "multiple" && pushInfo.target_group)) &&
      pushInfo.push_title &&
      canEdit
    );
  }, [pushInfo]);

  return (
    <Container>
      <TitleRow>
        <Title>푸시 스케줄 수정</Title>
        {canEdit ? (
          <Btn onClick={푸시_취소}>
            <p>푸시 취소</p>
          </Btn>
        ) : null}
      </TitleRow>
      <div style={{ height: 40 }} />
      <Row>
        <div style={{ width: "50%", maxWidth: 700 }}>
          <SendPushContent
            pushInfo={pushInfo}
            setPushInfo={setPushInfo}
            setIsVisible발송확인팝업={() => {}}
          />
          <div
            style={{
              marginTop: 16,
              position: "relative",
              right: -10,
              width: "100%",
            }}
          >
            <CTAButton
              $isDone={isDone}
              disabled={!isDone}
              onClick={() => setIsVisible발송확인팝업(true)}
            >
              {nowLoading || afterLoading ? (
                <FadeLoader
                  cssOverride={{
                    width: 10,
                    height: 10,
                    transform: "scale(0.5)",
                    top: -2,
                    left: 0,
                  }}
                  color="white"
                />
              ) : (
                <p>수정하기</p>
              )}
            </CTAButton>
          </div>
        </div>
        <div
          style={{
            position: "sticky",
            top: "10%",
            height: "200%",
            width: "50%",
          }}
        >
          <SendPreview pushInfo={pushInfo} />
        </div>
      </Row>
      {getLoading ? <AbsoluteFillLoading /> : null}
      <PopUpContainer
        isVisible={isVisible발송확인팝업}
        setIsVisible={setIsVisible발송확인팝업}
      >
        <SendCheckPopup
          pushInfo={pushInfo}
          postPush={푸시_수정}
          setIsVisible발송확인팝업={setIsVisible발송확인팝업}
        />
      </PopUpContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-width: 1200px;
  position: relative;
`;

const TitleRow = styled.div`
  width: 50%;
  max-width: 700px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  gap: 100px;
`;

const Btn = styled.button`
  background-color: ${({ theme }) => theme.colors.red1000};
  border-radius: 10px;
  display: inline-flex;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;

  p {
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }
`;

const CTAButton = styled.button<{ $isDone: boolean }>`
  padding: 10px 0;
  width: 100%;
  border-radius: 8px;
  border: none;
  background: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.primaryBlue100 : theme.colors.blueGray100};
  cursor: pointer;
  color: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.white : theme.colors.blueGray500};
  font-size: 18px;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.36px;

  &:disabled {
    cursor: not-allowed;
  }
`;
