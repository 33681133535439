import BTypeTextArea from "@components/core/BTypeInput/BTypeTextArea";
import { Title } from "@components/styles";
import { useMenu } from "@features/useMenu";
import { useGetIRApplicantDetail } from "@hooks/management";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

export default function IRDetail() {
  const params = useParams();
  const id = params.id;
  const { setCurrentMenu } = useMenu();
  const { data } = useGetIRApplicantDetail({ id });
  useEffect(() => {
    setCurrentMenu("IR");
  }, []);

  if (!data) {
    return;
  }

  return (
    <Container>
      <Title>{data.name}님 요청 정보</Title>
      <div style={{ height: 40 }} />
      <TableTitle>기본 정보</TableTitle>
      <BigRow>
        <Row>
          <p className="head">이름</p>
          <p className="body">{data.name || "이름"}</p>
        </Row>
        <Row>
          <p className="head">소속</p>
          <p className="body">{data.company || "-"}</p>
        </Row>
      </BigRow>
      <BigRow>
        <Row>
          <p className="head">이메일</p>
          <p className="body">{data.email || "-"}</p>
        </Row>
        <Row style={{ borderBottomWidth: 0 }}></Row>
      </BigRow>
      <div style={{ height: 60 }} />
      <TableTitle>비고</TableTitle>
      <BTypeTextArea
        placeholder="기타메모를 작성하세요."
        textareaStyle={{
          width: "100%",
          maxWidth: 1221,
          height: 240,
          padding: 0,
        }}
        value={data.memo || "-"}
        onChangeValue={() => {}}
        readOnly
      />
    </Container>
  );
}

const Container = styled.div`
  width: 75vw;
  max-width: 1800px;
  min-width: 1100px;
`;

const TableTitle = styled.h1`
  padding-bottom: 12px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.blueGray900};
  font-size: 24px;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
  letter-spacing: -0.48px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.blueGray800};
`;

const Row = styled.div`
  display: flex;
  padding: 16px 0;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueGray100};
  color: ${({ theme }) => theme.colors.blueGray700};

  .head {
    width: 35%;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }

  .body {
    width: 65%;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;

    a {
      color: #1c84ff;

      &:visited {
        color: #1c84ff;
      }
    }
  }
`;

const BigRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
`;
