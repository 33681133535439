export default function NoticeIcon({
  active,
}: {
  active: boolean | undefined;
}) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_96_12509)">
        <path
          d="M25.4481 23.9818L11.3333 19.3334V10.6668L25.4481 6.68502C26.1447 6.55102 26.7787 7.17109 26.7787 7.98622V22.6806C26.7787 23.4958 26.1447 24.1159 25.4481 23.9818Z"
          fill={active ? "#FFC900" : "#B2BEC9"}
        />
        <path
          d="M6.66659 10.6667H11.3333V19.3334H6.66659C5.93072 19.3334 5.33325 18.7359 5.33325 18.0001V12.0001C5.33325 11.2642 5.93072 10.6667 6.66659 10.6667Z"
          fill={active ? "#FFC900" : "#B2BEC9"}
        />
        <path
          d="M8.6665 16H13.9998V20C13.9998 21.4718 12.805 22.6667 11.3332 22.6667C9.86137 22.6667 8.6665 21.4718 8.6665 20V16Z"
          stroke={active ? "#FFC900" : "#B2BEC9"}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_96_12509">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
