import DownArrow from "@assets/arrow/DownArrow";
import { useState } from "react";
import styled from "styled-components";
import Menu from "./Menu";
import { useMenu } from "@features/useMenu";

export default function Category({ data }) {
  const { currentMenu } = useMenu();
  const [isShow, setIsShow] = useState(true);
  const onClick = () => {
    setIsShow((prev) => !prev);
  };

  return (
    <Container>
      <Arrow onClick={onClick} $isShow={isShow}>
        <DownArrow />
      </Arrow>
      <h1 className="title" onClick={onClick}>
        {data.title}
      </h1>
      {isShow
        ? data.content.map((el, idx) => {
            const active = el.value === currentMenu;
            const onClick = () => {};
            return (
              <Menu data={el} active={active} onClick={onClick} key={idx} />
            );
          })
        : null}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  padding-bottom: 6px;

  .title {
    margin-left: 8px;
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
    cursor: pointer;
  }
`;

const Arrow = styled.div<{ $isShow: boolean }>`
  position: absolute;
  top: ${({ $isShow }) => ($isShow ? "0px" : "5px")};
  left: -20px;
  cursor: pointer;

  transform: ${({ $isShow }) => ($isShow ? "rotate(180deg)" : "rotate(0deg)")};
`;
