import { useState } from "react";
import { Cookies } from "react-cookie";
import { Navigate, Outlet } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";

import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import { SyncLoader } from "react-spinners";
import { communityBaseURL } from "@api/index";

export default function PrivateRouter({ authentication }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const checkAuth = async () => {
    try {
      const cookies = new Cookies();
      const token = cookies.get("access_token");
      await axios.get(`${communityBaseURL}/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return true;
    } catch (err) {
      return false;
    }
  };

  useEffect(() => {
    if (authentication) {
      (async () => {
        const result = await checkAuth();

        console.log({ result });
        setIsLoading(false);
        setIsAuthenticated(result);
      })();
    }
  }, [authentication]);

  if (!authentication) {
    // 인증 필요없는 화면
    return <Outlet />;
  }

  // 토큰 인증 필요한 화면
  if (isLoading) {
    return (
      <PopUpContainer isVisible={true} setIsVisible={() => {}}>
        <SyncLoader />
        <p
          style={{
            marginTop: 20,
            fontSize: 20,
            color: "white",
            fontWeight: 500,
          }}
        >
          권한 확인 중
        </p>
      </PopUpContainer>
    );
  }

  if (isAuthenticated) {
    return <Outlet />;
  } else {
    return <Navigate to="/login" />;
  }

  // const cookies = new Cookies();
  // const token = cookies.get("access_token");
  // console.log("토큰", token);

  // if (authentication) {
  //   // 인증이 필요한 페이지
  //   if (token === undefined || token === "" || !token) {
  //     return <Navigate to="/login" />; // 토큰 없으면 로그인
  //   } else {
  //     return <Outlet />;
  //   }
  // } else {
  //   // 인증이 반드시 필요 없는 페이지
  //   // 인증을 안햇을 경우 해당 페이지로 인증을 한 상태일 경우 main페이지로
  //   return token === undefined || token === "" || !token ? (
  //     <Outlet />
  //   ) : (
  //     <Navigate to="/" />
  //   );
  // }
}
