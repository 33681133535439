import EditUserContainer from "@components/page/admin/report/users/EditUserContainer";
import { Title } from "@components/styles";
import { useMenu } from "@features/useMenu";
import { useEffect } from "react";
import styled from "styled-components";

export default function UserEdit() {
  const { setCurrentMenu } = useMenu();

  useEffect(() => {
    setCurrentMenu("users");
  }, []);

  return (
    <Container>
      <Title>회원 정보 수정</Title>
      <EditUserContainer />
    </Container>
  );
}

const Container = styled.div``;
