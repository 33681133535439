import styled from "styled-components";
import logo from "@assets/logo/logo.png";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Category from "./Category";
import Logo from "@assets/logo/Logo";

export default function BigNav({ navList, userInfo }) {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const handleLogout = () => {
    cookies.set("access_token", "", { path: "/" });
    navigate("/login");
  };

  const goDevAdmin = () => {
    if (userInfo.email.includes("boodong.kr")) {
      navigate("/dev");
    }
  };

  return (
    <Container>
      <TopContainer>
        {/* <img
          className="logo"
          src={logo}
          style={{ width: 80, height: 80 }}
          alt="부동부동 로고"
          onClick={goDevAdmin}
        /> */}
        <div className="logo" onClick={goDevAdmin}>
          <Logo />
        </div>
        <UserInfo>
          <p className="nickname">{userInfo.nickname}</p>
          <p className="email">{userInfo.email}</p>
        </UserInfo>
        {navList.map((el, idx) => {
          return <Category data={el} key={idx} />;
        })}
      </TopContainer>
      <LogOut onClick={handleLogout}>로그아웃</LogOut>
    </Container>
  );
}

const Container = styled.nav`
  position: sticky;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 40px;
  width: 240px;
  min-width: 240px;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.white};
  border-right: 1px solid ${({ theme }) => theme.colors.blueGray100};
  z-index: 999;
  overflow-y: auto;
`;

const TopContainer = styled.div`
  padding: 60px 0 0 40px;

  .logo {
    margin: 0 0 24px;
  }
`;

const UserInfo = styled.div`
  margin-bottom: 56px;

  .nickname {
    color: ${({ theme }) => theme.colors.blueGray900};
    margin-bottom: 4px;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.48px;
  }

  .email {
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }
`;

const LogOut = styled.p`
  margin-top: 100px;
  text-align: center;
  color: ${({ theme }) => theme.colors.blueGray600};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
  cursor: pointer;
`;
