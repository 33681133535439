import { useGetMe } from "@hooks/login";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function Main() {
  const navigate = useNavigate();
  const { mutate } = useGetMe();

  useEffect(() => {
    mutate(undefined, {
      onSuccess: (res) => {
        res.me.email.includes("@boodong.kr")
          ? navigate("/admin/config/contents")
          : navigate("/influencer/contents/upload");
      },
      onError: () => {
        navigate("/login");
      },
    });
  }, []);

  return <StyledContainer></StyledContainer>;
}

const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryBlue100};
`;
