import styled from "styled-components";

const color = {
  review: ["#0a3929", "#00cf8dd2"],
  deprecated: ["#6969696e", "#f4f6f894"],
  live: ["#1f2c4d", "#247cf7"],
  develop: ["#6c2a30be", "#f25763"],
};
export default function StatusChip({ state, onClick }) {
  return (
    <ChipBox color={color[state][0]} onClick={onClick}>
      <Circle color={color[state][1]} />
      <ChipText color={color[state][1]}> {state}</ChipText>
    </ChipBox>
  );
}

const ChipBox = styled.div`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 20px;
  background: ${({ color }) => color};
  cursor: pointer;
`;

const Circle = styled.div`
  margin-right: 4px;
  width: 10px;
  height: 10px;
  border-radius: 20px;
  background-color: ${({ color }) => color};
`;

const ChipText = styled.p`
  color: ${({ color }) => color};
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.24px;
`;
