import { Title } from "@components/styles";
import { useMenu } from "@features/useMenu";
import { Fragment, useEffect, useState } from "react";
import addIcon from "@assets/icon/addUser.png";
import deleteIcon from "@assets/icon/deleteUser.png";
import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useDeleteConsulting,
  useGetConsultingList,
  usePostLiveDB,
} from "@hooks/consultation";
import ConsultingTable from "@components/page/admin/consultation/consulting/ConsultingTable";

// 상담소 컨설팅 관리
// 보여주는 DB의 종류는 url의 db 쿼리로 관리중
// 라이브 DB에서는 조회만 가능하고 수정, 삭제가 불가
export default function ConsultingList() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dbVersion = searchParams.get("db") || "dev";
  const { setCurrentMenu } = useMenu();

  const [selectedRow, setSelectedRow] = useState("");
  const { data } = useGetConsultingList({ dbVersion });
  const { mutate } = useDeleteConsulting({ consultingId: selectedRow });
  const { mutate: updateLiveDB } = usePostLiveDB();

  useEffect(() => {
    setCurrentMenu("consulting");
  }, []);

  const handleAddConsultant = () => {
    navigate("/admin/consulting/add");
  };

  const handleDeleteConsultant = () => {
    if (window.confirm("선택한 컨설팅을 삭제합니다.")) {
      mutate();
    }
  };

  const handleUploadLiveDBBtn = () => {
    if (dbVersion === "live") {
      alert("개발DB 리스트로 선택 후 시도해주세요");
      return;
    }

    if (window.confirm("라이브 DB에 업데이트할까요?")) {
      const consultingIdArr = data.consultings.map((el) => el.consultingId);
      updateLiveDB(
        { toDB: "liveDB", consultingIds: consultingIdArr },
        {
          onSuccess: (res) => alert("라이브DB 업데이트 완료"),
          onError: (res) => {
            alert("업데이트 실패, 잠시후 다시 시도해주세요");
          },
        }
      );
    }
  };

  const handleDownLiveDBBtn = () => {
    if (dbVersion === "dev") {
      alert("라이브DB 리스트로 선택 후 시도해주세요");
      return;
    }

    if (window.confirm("라이브 DB를 개발 DB로 다운 받을까요?")) {
      const consultingIdArr = data.consultings.map((el) => el.consultingId);
      updateLiveDB(
        { toDB: "devDB", consultingIds: consultingIdArr },
        {
          onSuccess: (res) => alert("개발DB 업데이트 완료"),
          onError: (res) => {
            alert("업데이트 실패, 잠시후 다시 시도해주세요");
          },
        }
      );
    }
  };

  return (
    <Container>
      <Title>상담소 컨설팅 관리</Title>
      <Row>
        <UploadBtn onClick={handleUploadLiveDBBtn}>
          <p>라이브DB로 업로드</p>
        </UploadBtn>
        <UploadBtn
          onClick={handleDownLiveDBBtn}
          style={{ backgroundColor: "#f4f6f8" }}
        >
          <p style={{ color: "#4d5664" }}>라이브DB 다운로드</p>
        </UploadBtn>
      </Row>
      <ControlContainer>
        <Toggle>
          {[
            { title: "라이브 DB 리스트", value: "live" },
            { title: "개발 DB 리스트", value: "dev" },
          ].map((el, idx) => (
            <Fragment key={idx}>
              <ToggleText
                $active={dbVersion === el.value}
                onClick={() => {
                  searchParams.set("db", el.value);
                  setSearchParams(searchParams);
                }}
              >
                {el.title}
              </ToggleText>
              {el.value === "live" && <span />}
            </Fragment>
          ))}
        </Toggle>
        {dbVersion === "dev" && (
          <ButtonWrapper>
            <img
              onClick={handleAddConsultant}
              src={addIcon}
              alt="plusIcon"
              style={{ width: 32, height: 32 }}
            />
            <div style={{ width: 20 }} />
            <img
              onClick={handleDeleteConsultant}
              src={deleteIcon}
              alt="minusIcon"
              style={{ width: 32, height: 32 }}
            />
          </ButtonWrapper>
        )}
      </ControlContainer>
      <ConsultingTable
        setSelectedRow={setSelectedRow}
        data={data?.consultings || []}
      />
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
`;

const Row = styled.div`
  display: flex;
`;

const ControlContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  min-height: 32px;
`;

const Toggle = styled.div`
  display: flex;
  align-items: center;

  span {
    margin: 0 12px;
    width: 1px;
    height: 14px;
    background-color: ${({ theme }) => theme.colors.blueGray100};
  }
`;

const ToggleText = styled.h5<{ $active: boolean }>`
  color: ${({ theme, $active }) =>
    $active ? theme.colors.blue1000 : theme.colors.blueGray400};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;

const UploadBtn = styled.div`
  margin-top: 20px;
  margin-right: 10px;
  padding: 9px 32px;
  background-color: ${({ theme }) => theme.colors.blue100};
  width: fit-content;
  border-radius: 8px;
  cursor: pointer;

  p {
    color: ${({ theme }) => theme.colors.primaryBlue100};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }
`;
