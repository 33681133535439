import { Close22 } from "@components/core/icon/close";
import { CSSProperties } from "react";
import styled from "styled-components";
// import { StyledComponentProps } from "styled-components";

export default function ImjangStatusChangePopUp({ setIsVisible, handleState }) {
  const onClick = (value) => {
    handleState({ state: value });
    setIsVisible(false);
  };

  return (
    <Container>
      <Title>진행상태</Title>
      <IconBox
        onClick={() => {
          setIsVisible(false);
        }}
      >
        <Close22 />
      </IconBox>

      <BoxWrapper>
        <Box
          $bgColor={"#E9F9E8"}
          onClick={() => {
            onClick("waiting");
          }}
        >
          <BoxText $color={"#22C014"}>방문 대기</BoxText>
        </Box>
        <div style={{ height: 10 }} />

        <Box
          $bgColor={"#FFE9C7"}
          onClick={() => {
            onClick("proceed");
          }}
        >
          <BoxText $color={"#FF9C00"}>방문 진행</BoxText>
        </Box>
        <div style={{ height: 10 }} />
        <Box
          $bgColor={"#F0F2F5"}
          onClick={() => {
            onClick("done");
          }}
        >
          <BoxText $color={"#4D5664"}>방문 완료</BoxText>
        </Box>
        <div style={{ height: 10 }} />

        <Box
          $bgColor={"#FDECEE"}
          onClick={() => {
            onClick("cancel");
          }}
        >
          <BoxText $color={"#F04452"}>방문 취소</BoxText>
        </Box>
      </BoxWrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 16px 18px;
  width: 332px;
  height: fit-content;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const IconBox = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.blueGray800};
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;
`;

const BoxWrapper = styled.article`
  margin-top: 22px;
`;

const Box = styled.div<{ $bgColor: string }>`
  padding: 12px 0;
  border-radius: 8px;
  background-color: ${({ $bgColor }) => $bgColor || "white"};
  cursor: pointer;
`;

const BoxText = styled.p<{ $color: string }>`
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
  color: ${({ $color }) => $color};
`;
