import { ArrowColorEnum, ArrowDirectionEnum, Props } from "./Arrow.interfaces";
import styled from "styled-components";

const lineColor = {
  B20: "#CCCFD3",
  B30: "#B2B7BC",
  B40: "#999FA6",
  B50: "#808690",
};

export default function Arrows22({
  color = ArrowColorEnum.B30,
  direction = ArrowDirectionEnum.RIGHT,
}: Props) {
  return (
    <Container direction={direction}>
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path
          d="M9 16L14 10.5L9 5"
          stroke={lineColor[color]}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Container>
  );
}

const Container = styled.div<{ direction: string }>`
  ${({ direction }) => {
    switch (direction) {
      case ArrowDirectionEnum.DOWN:
        return "transform: rotate(90deg);";
      case ArrowDirectionEnum.LEFT:
        return "transform: rotate(180deg);";
      case ArrowDirectionEnum.UP:
        return "transform: rotate(270deg);";
      default:
        return;
    }
  }}
`;
