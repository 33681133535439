import { Calendar } from "@components/core/calendar";
import PushTable from "@components/page/admin/manage/push/PushTable";
import MaemulPushTable from "@components/page/admin/manage/push/MaemulPushTable";
import { Title } from "@components/styles";
import { useMenu } from "@features/useMenu";
import { useGetPushStat } from "@hooks/management";
import { useGetMaemulPushStats } from "@hooks/management";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function PushList() {
  const [selectedTabIdx, setSelectedTabIdx] = useState(0);
  const { setCurrentMenu } = useMenu();
  const [rangeDate, setRangeDate] = useState({ start: "", end: "" });
  const { data } = useGetPushStat({
    startDate: rangeDate.start,
    endDate: rangeDate.end,
  });
  const { data: maemulPushStatsData } = useGetMaemulPushStats({
    offset: 0,
    limit: 1000,
  });

  useEffect(() => {
    setCurrentMenu("pushList");
  }, []);

  return (
    <Container>
      <Title>푸시 발송 내역</Title>
      <BtnList>
        <SelectBtn
          active={selectedTabIdx === 0}
          onClick={() => setSelectedTabIdx(0)}
        >
          <p>매물 알림</p>
        </SelectBtn>
        <SelectBtn
          active={selectedTabIdx === 1}
          onClick={() => setSelectedTabIdx(1)}
        >
          <p>데일리 뉴스 ・ 기고문</p>
        </SelectBtn>
      </BtnList>
      {selectedTabIdx === 1 && (
        <Row style={{ justifyContent: "flex-end" }}>
          <Button to="/admin/push/chart">
            <p>그래프</p>
          </Button>
          <Calendar setRangeDate={setRangeDate} />
        </Row>
      )}
      {selectedTabIdx === 0 && (
        <MaemulPushTable data={maemulPushStatsData?.results || []} />
      )}
      {selectedTabIdx === 1 && <PushTable data={data?.results || []} />}
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
`;

const Row = styled.div`
  display: flex;
`;

const BtnList = styled.div`
  margin-top: 40px;
`;

const SelectBtn = styled.button<{ active: boolean }>`
  width: 160px;
  height: 48px;
  margin-right: 10px;
  border-radius: 8px;
  background: ${({ theme, active }) =>
    active ? theme.colors.blueA100 : theme.colors.blueGrayA200};
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  p {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
    color: ${({ theme, active }) =>
      active ? theme.colors.blue1000 : theme.colors.blueGray600};
  }
`;

const Button = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border: 1px solid #d9d9d9;
  width: 66px;
  height: 45px;
  border-radius: 10px;

  p {
    color: ${({ theme }) => theme.colors.blueGray700};
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.28px;
  }
`;
