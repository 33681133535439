import styled from "styled-components";

import StepWrapper from "./step/StepWrapper";
import CheckNickname from "./step/CheckNickname";
import PushMethod from "./step/PushMethod";
import PushContent from "./step/PushContent";
import PushTime from "./step/PushTime";
import SelectCohortGroup from "./step/SelectCohortGroup";

export default function SendPushContent({
  pushInfo,
  setPushInfo,
  setIsVisible발송확인팝업,
}) {
  return (
    <Container>
      <StepWrapper number={1} title={"푸시 방법 선택"}>
        <PushMethod pushInfo={pushInfo} setPushInfo={setPushInfo} />
      </StepWrapper>
      <StepWrapper number={2} title={"푸시 대상 설정"}>
        {pushInfo.method === "single" && (
          <CheckNickname pushInfo={pushInfo} setPushInfo={setPushInfo} />
        )}
        {pushInfo.method === "multiple" && (
          <SelectCohortGroup pushInfo={pushInfo} setPushInfo={setPushInfo} />
        )}
      </StepWrapper>
      <StepWrapper number={3} title={"전송 내용 작성"}>
        <PushContent pushInfo={pushInfo} setPushInfo={setPushInfo} />
      </StepWrapper>
      <StepWrapper number={4} title={"발송 시점 선택"}>
        <PushTime pushInfo={pushInfo} setPushInfo={setPushInfo} />
      </StepWrapper>
    </Container>
  );
}

const Container = styled.section``;

const CTAButton = styled.button<{ $isDone: boolean }>`
  padding: 10px 0;
  width: 100%;
  border-radius: 8px;
  border: none;
  background: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.primaryBlue100 : theme.colors.blueGray100};
  cursor: pointer;
  color: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.white : theme.colors.blueGray500};
  font-size: 18px;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.36px;

  &:disabled {
    cursor: not-allowed;
  }
`;
