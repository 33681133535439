import {
  createColumnHelper,
  flexRender,
  getSortedRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { HTMLProps, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { getCoreRowModel } from "@tanstack/react-table";
import "../../../table.css";
import { Link } from "react-router-dom";
import Pagination from "@components/core/paging/Pagination";
import { convertToKoreanDate } from "@hooks/calc/converToKoreanDate";
import { User } from "@hooks/admin/Admin.interfaces";

export default function UsersTable({ setSelectedRow, data }) {
  const [columnOrder, setColumnOrder] = useState([]);

  const columnHelper = createColumnHelper();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("checkBox", {
        cell: (info) => info.row.index + 1,
        header: "순번",
        maxSize: 5,
      }),
      columnHelper.accessor("nickname", {
        cell: ({ getValue }) => {
          return getValue() || "-";
        },
        header: "활동명",
        maxSize: 10,
      }),
      columnHelper.accessor("name", { header: "실명", maxSize: 10 }),
      columnHelper.accessor("phone_number", {
        cell: ({ getValue }) => {
          const tableValue = getValue() as string;
          if (tableValue) {
            return tableValue
              .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
              .replace(/\-{1,2}$/g, "");
          } else {
            return "-";
          }
        },
        header: "전화번호",
        maxSize: 15,
      }),
      columnHelper.accessor("email", { header: "이메일주소", maxSize: 20 }),
      columnHelper.accessor("auth", {
        cell: ({ row }) => {
          const email = row.original["email"];
          return email.includes("boodong.kr") ? "관리자" : "인플루언서";
        },
        header: "권한",
        maxSize: 10,
      }),
      columnHelper.accessor("created_at", {
        cell: ({ getValue }) => {
          return convertToKoreanDate(getValue());
        },
        header: "가입날짜",
        maxSize: 10,
      }),
      columnHelper.accessor("is_deleted", {
        cell: ({ getValue }) => {
          return getValue() ? "비활성화" : "활성화";
        },
        header: "상태",
        maxSize: 10,
      }),
      columnHelper.accessor("edit", {
        cell: ({ row }) => {
          const id = row.original["_id"];
          const email = row.original["email"];
          return email.includes("boodong.kr") ? (
            ""
          ) : (
            <StyledLink to={`/admin/config/users/edit/${id}`}>
              정보수정
            </StyledLink>
          );
        },
        header: "-",
        maxSize: 10,
      }),
    ];
  }, []);

  // useReactTable로 테이블 구조 정의
  const table = useReactTable({
    data,
    columns,
    state: {
      // columnVisibility,
      columnOrder,
    },
    enableRowSelection: true,
    enableMultiRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false, //페이지를 변경하는 상태 변화가 발생할 때 pageIndex는 0으로 리셋되는 옵션
  });

  useEffect(() => {
    table.setPageSize(10);
  }, []);

  return (
    <Container>
      <table className="contentsTable">
        <thead className="contentsThead">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="contentsHeader"
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            const isActive = (row.original as User).is_deleted;
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className="contentsData"
                    style={{ color: isActive ? "#B2B7BC" : "#4D5664" }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination table={table} />
    </Container>
  );
}

function IndeterminateCheckbox({
  indeterminate,
  className = "",
  onChange,
  setSelectedRow,
  userId,
  ...rest
}: {
  indeterminate?: boolean;
  setSelectedRow: React.Dispatch<React.SetStateAction<number>>;
  userId: number;
} & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  const onClick = (e) => {
    onChange(e);
    setSelectedRow(userId);
  };

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + " cursor-pointer"}
      onChange={onClick}
      {...rest}
    />
  );
}

const Container = styled.div`
  width: fit-content;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primaryBlue100};
`;
